import { dispatch } from "../store/store";

import {
  setIsPharmacyServiceLoading,
  setIsPharmacyServiceSidebarLoading,
  setIsPharmacyServicesAnalyticsLoading,
  setIsServiceProgressLoading,
  resetPharmacyServiceState,
  setIsCalenderShow,
  setSidebarVisibleData,
  setCompletedServiceTabValueData,
  setSelectedRow,
  setServiceId,
  setServiceData,
  setNHSCount,
  setPrivateCount,
  setServiceName,
  setServiceIcon,
  setServiceBuilderType,
  setNewClinicComponent,
  setCurrentEvents,
  setServiceAnalytics,
  setCurrentServiceTabValue,
  getPharmacyServiceData,
  getPharmacyClinicalServiceData,
  getPharmacyClinicalServiceCountData,
  getAnalyticsByServicePharmacyDateData,
  updateEventData,
  updateCalendarEventByClinicalServiceIdData,
  updateRecheduleByClinicalServiceIdData,
  getEventsData,
  getPharmaciesByCompanyIdData,
  getClinicalServiceBuilderByServiceIdData,
  setCalendarStartDate,
  setCalendarEndDate,
  setCalendarDates,
  addpharmacyServiceData,
  sendSMSData,
  setSmsQueue,
  getSMSData,
  getIdentityVerificationData,
  getIdentityVerificationDataBySessionIdData,
  checkYotiDataBySessionIdData,
  getOrbitSessionDetailData,
  saveOrbitSessionDetailData,
  getUserLogData,
  setNmsProgress,
  savePatientContactData,
  setPatientContact,
  setContactServiceId,
  setFilterPatient,
  setFilterNmsValues,
  setFilterTagsvalues,
  setFilterNamevalues,
  setPharmacyServices,
  setIsFilterModal,
  setScrollPositionData,
  setOpenData,
  setAnalyticsTabsValueData,
  setServiceProgressData,
  getServiceProgressData,
  updateClaimStatusData,
  sendToOrbitData,
  setOrbitQueue,
  setSentToOrbitData,
  changeSentToOrbitData,
  setOrbitClinicalServiceIdData,
  moveInterventionData,
  declineServicesData,
  rescheduleServicesData,
  setInterventionQueue,
  setDeclineQueue,
  setRescheduleQueue,
  setPdsQueue,
  setPatientNameValues,
  setFilterTagsvaluesConsented,
  setInterventionStageScrollPositionData,
  setEligibilityStageScrollPositionData,
  setListViewGridScrollPositionData,
  setShowRescheduleData,
  setCheckedAllEligibleData,
  setCheckedAllFollowUpData,
  setCheckedAllInterventionData,
  setCheckedAllConsentData,
  setProcessedPDSServicesData,
  setDrugFilterValueData,
  setSendSMSAutomationData,
  setSendOrbitAutomationData,
  setTransformedPharmacyData,
  getPharmacyActionsData,
  changePharmacyActionsData,
  moveDeclineServiceToClinicalServiceData,
  setSendInterventionAutomationData,
  getServiceTitanPatientPdsData,
  setFilteredPharmacyServicesData,
  setOriginalPharmacyServicesData,
  getDefaultCompanyServiceUrlData,
  getClinicalServiceBuilderByIdData,
  getServiceTemplatesData,
  setTemplateControlValue,
  submitCustomTemplateData,
  setAnyControlChecked,
  getClinicalServiceBuilderByCompanyIdData,
  updateClinicalServicePaymentDetailsData,
  setSelectedClinicalServiceIdData,
  setShowPaymentModalData,
  setSendToInterventionOptionsData,
  setIsAutomationMenuOpenData,
  setSendConsentOnlyData,
  setOrbitSendModal,
  getAgencyRatesData,
  getAgencyPharmacyData,
  addAgencyPharmacyData,
  setOrbitModalQueueData,
  getAnalyticsNMSProcessedData,
  getAnalyticsServicesEarningsData,
  getAnalyticsServicesUsersData,
  getAnalyticsServicesStagesData,
  getAnalyticsServicesCompletedClaimedData,
  getAnalyticsServicesCompletedToBeClaimedData,
  getAnalyticsServicesDeclinedData,
} from "../features/pharmacyServiceSlice/pharmacyServiceSlice";
import { logError } from "../services/logService";

const reducerName = "pharmacyService";

// #region State Setters
export const setShowCalender = (isCalender) => {
  dispatch(setIsCalenderShow(isCalender));
};
export const setSidebarVisible = (isSidebar) => {
  dispatch(setSidebarVisibleData(isSidebar));
};
export const setCompletedServiceTabValue = (value) => {
  dispatch(setCompletedServiceTabValueData(value));
};

export const updateControlCheckedState = (isChecked) => {
  dispatch(setAnyControlChecked(isChecked));
};
export const setSelectedRowData = (data) => {
  dispatch(setSelectedRow(data));
};
export const setShowPaymentModal = (data) => {
  dispatch(setShowPaymentModalData(data));
};
export const setSelectedClinicalServiceId = (id) => {
  dispatch(setSelectedClinicalServiceIdData(id));
};
export const setIsNewClinicComponent = (isNew) => {
  dispatch(setNewClinicComponent(isNew));
};

export const setCurrentServiceId = (serviceId) => {
  dispatch(setServiceId(serviceId));
};

export const setCurrentServiceIcon = (serviceIcon) => {
  dispatch(setServiceIcon(serviceIcon));
};

export const setCurrentServiceBuilderType = (serviceBuilderType) => {
  dispatch(setServiceBuilderType(serviceBuilderType));
};

export const setCurrentServiceName = (serviceName) => {
  dispatch(setServiceName(serviceName));
};

export const setCurrentServiceData = (serviceData) => {
  dispatch(setServiceData(serviceData));
};

export const setCurrentNHSCount = (NhsCount) => {
  dispatch(setNHSCount(NhsCount));
};

export const setCurrentPrivateCount = (privateCount) => {
  dispatch(setPrivateCount(privateCount));
};

export const setCurrentEvent = (events) => {
  dispatch(setCurrentEvents(events));
};

export const setCurrentServiceAnalytics = (analytics) => {
  dispatch(setServiceAnalytics(analytics));
};

export const resetPharmacyService = () => {
  dispatch(resetPharmacyServiceState());
};

export const setPharmacyServiceLoading = (isLoading) => {
  dispatch(setIsPharmacyServiceLoading(isLoading));
};

export const setPharmacyServiceSidebarLoading = (isLoading) => {
  dispatch(setIsPharmacyServiceSidebarLoading(isLoading));
};

export const setPharmacyServicesAnalyticsLoading = (isLoading) => {
  dispatch(setIsPharmacyServicesAnalyticsLoading(isLoading));
};

export const setServiceProgressLoading = (isLoading) => {
  dispatch(setIsServiceProgressLoading(isLoading));
};

export const setCurrentNmsProgress = (data) => {
  dispatch(setNmsProgress(data));
};

export const setCurrentPatientContact = (data) => {
  dispatch(setPatientContact(data));
};

export const setCurrentContactServiceId = (data) => {
  dispatch(setContactServiceId(data));
};

export const setServiceTab = (tabValue = 0) => {
  dispatch(setCurrentServiceTabValue(tabValue));
};

export const setCurrentCalendarStartDate = (date) => {
  const strDate = date instanceof Date ? date.toISOString() : date;
  dispatch(setCalendarStartDate(strDate));
};

export const setCurrentCalendarEndDate = (date) => {
  const strDate = date instanceof Date ? date.toISOString() : date;
  dispatch(setCalendarEndDate(strDate));
};

export const setCurrentCalendarDates = (startDate, endDate) => {
  const strStartDate =
    startDate instanceof Date ? startDate.toISOString() : startDate;
  const strEndDate = endDate instanceof Date ? endDate.toISOString() : endDate;
  dispatch(setCalendarDates(strStartDate, strEndDate));
};

export const setFilterPatientData = (filterData) => {
  dispatch(setFilterPatient(filterData));
};
export const setFilterNmsData = (filterData) => {
  dispatch(setFilterNmsValues(filterData));
};
export const setFilterTagsData = (filterData) => {
  dispatch(setFilterTagsvalues(filterData));
};
export const setFilterTagsConsentedData = (filterData) => {
  dispatch(setFilterTagsvaluesConsented(filterData));
};
export const setFilterNameData = (filterData) => {
  dispatch(setFilterNamevalues(filterData));
};
export const setOriginalPharmacyServices = (filterData) => {
  dispatch(setOriginalPharmacyServicesData(filterData));
};
export const setCurrentPharmacyServices = (data) => {
  dispatch(setPharmacyServices(data));
};
export const setCurrentFilterModal = (data) => {
  dispatch(setIsFilterModal(data));
};
export const setOpen = (data) => {
  dispatch(setOpenData(data));
};

export const setPatientNameData = (filterData) => {
  dispatch(setPatientNameValues(filterData));
};

export const setServicesNameAndIcons = async (
  serviceName,
  serviceIcon,
  serviceBuilderType
) => {
  setCurrentServiceName(serviceName);
  setCurrentServiceIcon(serviceIcon);
  setCurrentServiceBuilderType(serviceBuilderType);
  setIsNewClinicComponent(false);
};

export const setCurrentSmsQueue = (sms) => {
  dispatch(setSmsQueue([...sms]));
};

export const setCurrentOrbitQueue = (orbit) => {
  dispatch(setOrbitQueue([...orbit]));
};

export const setScrollPosition = (value = 0) => {
  dispatch(setScrollPositionData(value));
};
export const setAnalyticsTabsValue = (value = 0) => {
  dispatch(setAnalyticsTabsValueData(value));
};
export const setServiceProgressValue = (value = 0) => {
  dispatch(setServiceProgressData(value));
};
export const setSentToOrbit = (data) => {
  dispatch(setSentToOrbitData(data));
};
export const setOrbitClinicalServiceId = (data) => {
  dispatch(setOrbitClinicalServiceIdData(data));
};
export const setCurrentInterventionQueue = (value) => {
  dispatch(setInterventionQueue([...value]));
};
export const setCurrentDeclineQueue = (value) => {
  dispatch(setDeclineQueue([...value]));
};
export const setCurrentRescheduleQueue = (value) => {
  dispatch(setRescheduleQueue([...value]));
};
export const setCurrentPdsQueue = (value) => {
  dispatch(setPdsQueue([...value]));
};
export const setInterventionStageScrollPosition = (value = 0) => {
  dispatch(setInterventionStageScrollPositionData(value));
};
export const setEligibilityStageScrollPosition = (value = 0) => {
  dispatch(setEligibilityStageScrollPositionData(value));
};
export const setListViewGridScrollPosition = (value = 0) => {
  dispatch(setListViewGridScrollPositionData(value));
};
export const setShowReschedule = (value = 0) => {
  dispatch(setShowRescheduleData(value));
};
export const setCheckedAllEligible = (value = false) => {
  dispatch(setCheckedAllEligibleData(value));
};
export const setCheckedAllFollowUp = (value = false) => {
  dispatch(setCheckedAllFollowUpData(value));
};
export const setCheckedAllIntervention = (value = false) => {
  dispatch(setCheckedAllInterventionData(value));
};
export const setCheckedAllConsent = (value = false) => {
  dispatch(setCheckedAllConsentData(value));
};
export const setProcessedPDSServices = (value) => {
  dispatch(setProcessedPDSServicesData(value));
};
export const setDrugFilterValue = (value) => {
  dispatch(setDrugFilterValueData(value));
};
export const setSendSMSAutomation = (value) => {
  dispatch(setSendSMSAutomationData(value));
};
export const setSendOrbitAutomation = (value) => {
  dispatch(setSendOrbitAutomationData(value));
};
export const setSendConsentOnly = (value) => {
  dispatch(setSendConsentOnlyData(value));
};
export const setTransformedPharmacies = (value) => {
  dispatch(setTransformedPharmacyData(value));
};
export const setSendInterventionAutomation = (value) => {
  dispatch(setSendInterventionAutomationData(value));
};
export const setFilteredPharmacyServices = (value) => {
  dispatch(setFilteredPharmacyServicesData(value));
};

export const updateTemplateControlValue = (
  templateIndex,
  sectionIndex,
  controlIndex,
  value
) => {
  dispatch(
    setTemplateControlValue({
      templateIndex,
      sectionIndex,
      controlIndex,
      value,
    })
  );
};
export const setSendToInterventionOptions = (value) => {
  dispatch(setSendToInterventionOptionsData(value));
};
export const setIsAutomationMenuOpen = (value) => {
  dispatch(setIsAutomationMenuOpenData(value));
};
export const setOrbitSend = (data) => {
  dispatch(setOrbitSendModal(data));
};
export const setOrbitModalQueue = (value) => {
  dispatch(setOrbitModalQueueData([...value]));
};

// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const getClinicalServiceBuilderByCompanyId = async (companyId) => {
  let result = [];
  try {
    result = await dispatch(
      getClinicalServiceBuilderByCompanyIdData(companyId)
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const getPharmacyServices = async (
  pharmacyId,
  searchType = 0,
  serviceId = 0,
  showCalender = false
) => {
  let result = [];
  try {
    setCurrentServiceId(serviceId);
    setShowCalender(showCalender);
    result = await dispatch(
      getPharmacyServiceData({
        pharmacyId,
        searchType,
        serviceId,
      })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = [];
  }

  return result;
};

export const getPharmacyClinicalServices = async (pharmacyId) => {
  let result = [];
  try {
    result = await dispatch(
      getPharmacyClinicalServiceData(pharmacyId)
    ).unwrap();
  } catch (error) {
    logError(error);
    result = [];
  }

  return result;
};

export const getPharmacyClinicalServicesCount = async (pharmacyId) => {
  let result = [];
  try {
    result = await dispatch(
      getPharmacyClinicalServiceCountData(pharmacyId)
    ).unwrap();
  } catch (error) {
    logError(error);
    result = [];
  }

  return result;
};

export const getServiceAnalytics = async (pharmacyId, serviceId) => {
  let result = [];
  try {
    result = await dispatch(
      getAnalyticsByServicePharmacyDateData({
        pharmacyId,
        serviceId,
      })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = [];
  }

  return result;
};

export const getEvents = async (pharmacyId, serviceId) => {
  let result = [];
  try {
    result = await dispatch(getEventsData({ pharmacyId, serviceId })).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const getClinicalServiceBuilders = async (pharmacyId, serviceId = 0) => {
  let result = [];
  try {
    result = await dispatch(
      getClinicalServiceBuilderByServiceIdData({
        pharmacyId,
        serviceId,
      })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = [];
  }

  return result;
};

export const getPharmaciesByCompanyId = async (companyId) => {
  let result = [];
  try {
    result = await dispatch(getPharmaciesByCompanyIdData(companyId)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const addpharmacyService = async (pharmacyId, data) => {
  let result = null;
  try {
    result = await dispatch(
      addpharmacyServiceData({ pharmacyId, data })
    ).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const updateEvent = async (data) => {
  let result = null;
  try {
    result = await dispatch(updateEventData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};
export const updateCalendarEventByClinicalServiceId = async (params) => {
  let result = null;
  try {
    result = await dispatch(
      updateCalendarEventByClinicalServiceIdData(params)
    ).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};
export const updateRecheduleByClinicalServiceId = async (params) => {
  let result = null;
  try {
    result = await dispatch(
      updateRecheduleByClinicalServiceIdData(params)
    ).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const sendSMS = async (data) => {
  let result = null;
  try {
    result = await dispatch(sendSMSData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const updateClaimStatus = async (data) => {
  let result = null;
  try {
    result = await dispatch(updateClaimStatusData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const sendToOrbit = async (data) => {
  let result = null;
  try {
    result = await dispatch(sendToOrbitData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getSMS = async () => {
  let result = [];
  try {
    result = await dispatch(getSMSData()).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const getIdentityVerification = async () => {
  let result = [];
  try {
    result = await dispatch(getIdentityVerificationData()).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const getIdentityVerificationDataBySessionId = async (
  sessionId,
  gphcNumber
) => {
  let result = [];
  try {
    result = await dispatch(
      getIdentityVerificationDataBySessionIdData({ sessionId, gphcNumber })
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const checkYotiDataBySessionId = async (sessionId) => {
  let result = [];
  try {
    result = await dispatch(checkYotiDataBySessionIdData(sessionId)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const getOrbitSessionDetail = async (pharmacyId) => {
  let result = [];
  try {
    result = await dispatch(getOrbitSessionDetailData(pharmacyId)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const changeSentToOrbit = async (serviceId) => {
  let result = [];
  try {
    result = await dispatch(changeSentToOrbitData(serviceId)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const saveOrbitSessionDetail = async (data) => {
  let result = null;
  try {
    result = await dispatch(saveOrbitSessionDetailData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getUserLog = async (serviceId) => {
  let result = [];
  try {
    result = await dispatch(getUserLogData(serviceId)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const savePatientContact = async (serviceId, telephone, data) => {
  let result = null;
  try {
    result = await dispatch(
      savePatientContactData({ serviceId, telephone, data })
    ).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getServiceProgress = async (serviceId, pharmacyId) => {
  let result = null;
  try {
    result = await dispatch(
      getServiceProgressData({ serviceId, pharmacyId })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getPharmacyActions = async (params) => {
  let result = null;
  try {
    result = await dispatch(getPharmacyActionsData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const changePharmacyActions = async (
  pharmacyId,
  pharmacyActionType,
  isAction
) => {
  let result = null;
  try {
    result = await dispatch(
      changePharmacyActionsData({ pharmacyId, pharmacyActionType, isAction })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const moveDeclineServiceToClinicalService = async (
  clinicalServiceId
) => {
  let result = null;
  try {
    result = await dispatch(
      moveDeclineServiceToClinicalServiceData(clinicalServiceId)
    ).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const moveIntervention = async (data) => {
  let result = null;
  try {
    result = await dispatch(moveInterventionData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const declineServices = async (data) => {
  let result = null;
  try {
    result = await dispatch(declineServicesData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const rescheduleServices = async (data) => {
  let result = null;
  try {
    result = await dispatch(rescheduleServicesData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getServiceTitanPatientPds = async (params) => {
  let result = null;
  try {
    result = await dispatch(getServiceTitanPatientPdsData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getDefaultCompanyServices = async (companyId) => {
  let result = [];
  try {
    result = await dispatch(
      getDefaultCompanyServiceUrlData(companyId)
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const getClinicalServiceBuilderById = async (serviceId) => {
  let result = null;
  try {
    result = await dispatch(
      getClinicalServiceBuilderByIdData(serviceId)
    ).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getServiceTemplates = async (data) => {
  let result = null;
  try {
    result = await dispatch(getServiceTemplatesData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const submitCustomServiceTemplate = async (data) => {
  let result = null;
  try {
    result = await dispatch(submitCustomTemplateData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const updateClinicalServicePaymentDetails = async (
  clinicalServiceId,
  isPaymentTaken
) => {
  let result = null;
  try {
    result = await dispatch(
      updateClinicalServicePaymentDetailsData(clinicalServiceId, isPaymentTaken)
    ).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const addAgencyPharmacy = async (data) => {
  let result = null;
  try {
    result = await dispatch(addAgencyPharmacyData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getAgencyRates = async () => {
  let result = null;
  try {
    result = await dispatch(getAgencyRatesData()).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getAgencyPharmacy = async (pharmacyId) => {
  let result = null;
  try {
    result = await dispatch(getAgencyPharmacyData(pharmacyId)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const getAnalyticsNMSProcessed = async (pharmacyId, serviceId) => {
  let result = [];
  try {
    result = await dispatch(
      getAnalyticsNMSProcessedData({
        pharmacyId,
        serviceId,
      })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = [];
  }

  return result;
};

export const getAnalyticsServicesEarnings = async (pharmacyId, serviceId) => {
  let result = [];
  try {
    result = await dispatch(
      getAnalyticsServicesEarningsData({
        pharmacyId,
        serviceId,
      })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = [];
  }

  return result;
};

export const getAnalyticsServicesUsers = async (pharmacyId, serviceId) => {
  let result = [];
  try {
    result = await dispatch(
      getAnalyticsServicesUsersData({
        pharmacyId,
        serviceId,
      })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = [];
  }

  return result;
};

export const getAnalyticsServicesStages = async (pharmacyId, serviceId) => {
  let result = [];
  try {
    result = await dispatch(
      getAnalyticsServicesStagesData({
        pharmacyId,
        serviceId,
      })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = [];
  }

  return result;
};

export const getAnalyticsServicesCompletedClaimed = async (
  pharmacyId,
  serviceId
) => {
  let result = [];
  try {
    result = await dispatch(
      getAnalyticsServicesCompletedClaimedData({
        pharmacyId,
        serviceId,
      })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = [];
  }

  return result;
};

export const getAnalyticsServicesCompletedToBeClaimed = async (
  pharmacyId,
  serviceId
) => {
  let result = [];
  try {
    result = await dispatch(
      getAnalyticsServicesCompletedToBeClaimedData({
        pharmacyId,
        serviceId,
      })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = [];
  }

  return result;
};

export const getAnalyticsServicesDeclined = async (pharmacyId, serviceId) => {
  let result = [];
  try {
    result = await dispatch(
      getAnalyticsServicesDeclinedData({
        pharmacyId,
        serviceId,
      })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = [];
  }

  return result;
};
// #endregion
