import { dispatch, getDataFromStore } from "../store/store";
import { logError } from "../services/logService";
import {
  getTemplatesData,
  addTemplateData,
  setIsConsultationLoading,
  setTemplatesData,
  getEmailsAndSmsTemplatesData,
  setIsTemplatesLoading,
  addEmailsAndSmsTemplatesData,
  setTemplateMessageTypeData,
  setUpdateMessagetemplateData,
  updateEmailsAndSmsTemplatesData,
  getDefaultTemplatesData,
} from "../features/templateSlice/templateSlice";

const reducerName = "template";

// #region State Setters
export const setConsultationLoading = (isLoading) => {
  dispatch(setIsConsultationLoading(isLoading));
};

export const setTemplatesLoading = (isLoading) => {
  dispatch(setIsTemplatesLoading(isLoading));
};

export const setConsultation = (newTemplates) => {
  dispatch(setTemplatesData(newTemplates));
};

export const setTemplateMessageType = (type) => {
  dispatch(setTemplateMessageTypeData(type));
};

export const setUpdateMessagetemplate = (data) => {
  dispatch(setUpdateMessagetemplateData(data));
};

// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const getAllTemplates = async (companyId) => {
  let result = [];
  try {
    result = await dispatch(
      getTemplatesData(companyId)
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const addTemplate = async (data) => {
  let result = null;

  try {
    result = await dispatch(addTemplateData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
export const getEmailsAndSmsTemplates = async (companyId) => {
  let result = [];
  try {
    result = await dispatch(
      getEmailsAndSmsTemplatesData(companyId)
    ).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const addEmailsAndSmsTemplates = async (data) => {
  let result = null;
  try {
    result = await dispatch(addEmailsAndSmsTemplatesData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const updateEmailsAndSmsTemplates = async (data) => {
  let result = null;
  try {
    result = await dispatch(updateEmailsAndSmsTemplatesData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getDefaultTemplates = async (actionType) => {
  let result = null;
  try {
    result = await dispatch(getDefaultTemplatesData(actionType)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

// #endregion
