import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../../../../hooks/useTitanApp";
import PharmanautGeneralDetails from "./PharmanautGeneralDetails";
function TabPanel(props) {
  const { children, value, index, ...other } = props || {};

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PharmanautDetails() {
  const { appState, nmsServiceState } = useTitanApp();
  const { darkMode, isOrbitUser } = appState || {};
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isDetailLoading = false;
  const isAnalyticsLoading = false;
  const isAuditLoading = false;

  return (
    <Box sx={{ width: "100%" }} className="patient-tab">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label=""
          centered={true}
          style={{
            pointerEvents: isDetailLoading ? "none" : "",
          }}
        >
          <Tab
            label={
              <span className={`tabs_text_alt`}>
                {isDetailLoading === true ? (
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                    height={30}
                    width={100}
                    animation="wave"
                    variant="rounded"
                  />
                ) : (
                  "Details"
                )}
              </span>
            }
            {...a11yProps(1)}
            className="para-color"
          />
          <Tab
            label={
              <span className={`tabs_text_alt`}>
                {isAnalyticsLoading === true ? (
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                    height={30}
                    width={100}
                    animation="wave"
                    variant="rounded"
                  />
                ) : (
                  "Analytics"
                )}
              </span>
            }
            {...a11yProps(0)}
            className="para-color"
          />

          <Tab
            label={
              <span className={`tabs_text_alt`}>
                {isAuditLoading === true ? (
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                    height={30}
                    width={100}
                    animation="wave"
                    variant="rounded"
                  />
                ) : (
                  "Audit"
                )}
              </span>
            }
            {...a11yProps(2)}
            className="para-color"
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <PharmanautGeneralDetails />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* <NmsTimeLine stageName={stageName} /> */}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <ImageSlider navigateFrom={"NMS"} /> */}
      </TabPanel>
    </Box>
  );
}

export default PharmanautDetails;
