import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { keyframes } from "@emotion/react"; // Import keyframes for animation
import useTitanApp from "../../hooks/useTitanApp";
import { border, borderRadius, maxWidth } from "@mui/system";

// Define the blinking animation
const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

// Create a styled light tooltip
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, darkMode }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: darkMode ? "#111111" : theme.palette.common.white,
    color: darkMode ? "white" : "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[2],
    padding: 0,
    fontSize: 11,
    maxWidth: 400, // Increased width for consistent layout
    fontFamily: '"SF-Pro Regular", sans-serif !important', // Add the font family
    borderRadius: 10,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: darkMode ? "#111111" : theme.palette.common.white,
  },
}));

export default function NmsPrescriptionToolTip(autoCheckInfo) {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  return (
    <LightTooltip
      title={
        <div className="nmstooltip-container">
          <div className="nmstooltip-title">
            <p className="nmstooltip-title-text">{autoCheckInfo.title}</p>
          </div>
          <div className="nmstooltip-divider" />
          <div className="nmstooltip-content">
            {autoCheckInfo?.details?.map((detail, index) => (
              <div key={index} className="nmstooltip-item">
                <div
                  className={
                    darkMode ? "nmstooltip-dark-caption" : "nmstooltip-caption"
                  }
                >
                  {detail?.caption}
                </div>
                <div className="nmstooltip-description">
                  {detail?.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      }
      arrow
      placement="top"
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 10],
            },
          },
        ],
      }}
      darkMode={darkMode} // Pass darkMode prop to LightTooltip
    >
      <Box
        sx={{
          width: 5,
          height: 5,
          borderRadius: "50%",
          backgroundColor: "blue",
          display: "inline-block",
          cursor: "pointer",
          animation: `${blinkAnimation} 1s infinite`,
        }}
      />
    </LightTooltip>
  );
}
