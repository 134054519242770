import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import "./../../assets/css/TemplateSection.css";
import CustomLabel from "../../components/controls/CustomLabel";
import { inputTypes } from "../../enum/inputTypes";
import CustomDropdown from "../../components/controls/CustomDropdown";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { BsCalendar3 } from "react-icons/bs";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import useTitanApp from "../../hooks/useTitanApp";
import config from "../../config";
function FormSection({
  SectionTitle,
  UserControls,
  handleChangeModel,
  isCompleted,
  stageStatus,
}) {
  useEffect(() => {
    setInputValues(UserControls);
    UserControls?.map((item, index) => {
      if (item.type === 9 && item.value !== "") {
        setAddedTexts((addedTexts) => [...addedTexts, index]);
      }
    });
  }, [UserControls]);
  let rowCount = 0;
  useEffect(() => {
    setIsRequired(isCompleted);
  }, [isCompleted]);
  const [addedTexts, setAddedTexts] = useState([]);
  const [inputValues, setInputValues] = useState(UserControls);
  const [isRequired, setIsRequired] = useState(isCompleted);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  let patientName;
  const { auth } = useTitanApp();
  const { user } = auth || {};

  const userFullName = user?.name;
  const [PatName, setPatName] = useState("");
  const [switchClass, setSwitchclass] = useState("switch-content-reports");
  function handleChangeColor(isChecked) {
    !isChecked
      ? setSwitchclass("switch-content-reports active-switch")
      : setSwitchclass("switch-content-reports");
  }
  const addText = async (index) => {
    if (addedTexts.includes(index)) {
      const updatedNumbers = addedTexts.filter(
        (addedTexts) => addedTexts !== index
      );
      setAddedTexts(updatedNumbers);
      setInputValues((prevInputValues) => {
        const newInputValues = [...prevInputValues];
        newInputValues[index].value = "";
        return newInputValues;
      });
    } else {
      setAddedTexts((addedTexts) => [...addedTexts, index]);
    }
  };
  const handleFormChange = async (index, event, type) => {
    let value;
    if (type === "multiSelect") {
      const listItem = inputValues[index].value;

      const items = listItem.split(",");

      const trimmedItems = items?.map((item) => item.trim());

      if (!trimmedItems.includes(event?.target?.value)) {
        trimmedItems.push(event?.target?.value);
      }

      value = trimmedItems.join(",");
    } else if (type === "Radio") {
      value = event.target.checked.toString();

      handleChangeColor(value);
    } else if (type === "date") {
      value = event.toISOString();
    } else {
      value = event?.target?.value;
    }
    await setUserControl(index, value);

    const UserControlObj = inputValues;
    handleChangeModel(SectionTitle, UserControlObj, PatName);
  };
  const setUserControl = async (index, value) => {
    setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[index].value = value;
      return newInputValues;
    });
    if (inputValues[index].label === "Patient Name") {
      if (value !== null) {
        setPatName(value);
      }
    }
  };
  const [inputclass, setInputClass] = useState("disable");
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className="patientform_accordion"
        style={{
          marginBottom: "15px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="patient-accordion-cont"
        >
          <div>
            <p>{SectionTitle}</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="patient_card">
            {
              <div className="form_boxes">
                {inputValues?.map((item, index) => {
                  if (item.inline === true) {
                    rowCount > 2 ? (rowCount = 0) : rowCount++;
                  }

                  if (item.type === 7) {
                    return (
                      <>
                        <div
                          key={index}
                          className={
                            item.inline === true ? "inline-not" : "inline"
                          }
                        >
                          <div className="form_control">
                            <CustomDropdown
                              key={index}
                              label={item.label}
                              items={item.listItems}
                              selected={item.value}
                              isEdit={stageStatus}
                            />
                          </div>
                        </div>
                        {rowCount > 2 ? <br /> : <></>}
                      </>
                    );
                  }
                  if (item.type === 6) {
                    return (
                      <>
                        <div
                          key={index}
                          className={
                            item.inline === true ? "inline-not" : "inline"
                          }
                        >
                          <div className="switch-content-vaccine1 input_color">
                            <CustomLabel name={item.label} />
                            <div>
                              <AntSwitch
                                key={index}
                                disabled={stageStatus}
                                inputProps={{ "aria-label": "ant design" }}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputTypes[item.type]
                                  )
                                }
                                defaultChecked={item.value}
                              />
                            </div>
                          </div>
                        </div>
                        {rowCount > 2 ? <br /> : <></>}
                      </>
                    );
                  }
                  if (item.type === 5) {
                    return (
                      <>
                        <div
                          key={index}
                          className={
                            item.inline === true ? "inline-not" : "inline"
                          }
                        >
                          <div className="switch-content-vaccine1 input_color">
                            <CustomLabel name={item.label} />
                            <div>
                              <AntSwitch
                                key={index}
                                disabled={stageStatus}
                                inputProps={{ "aria-label": "ant design" }}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputTypes[item.type]
                                  )
                                }
                                checked={
                                  item.value === ""
                                    ? false
                                    : JSON.parse(item.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {rowCount > 2 ? <br /> : <></>}
                      </>
                    );
                  }
                  if (item.type === 3) {
                    return (
                      <>
                        <div
                          key={index}
                          className={
                            item.inline === true ? "inline-not" : "inline"
                          }
                        >
                          <div key={index} className="form_control">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                className="input_color"
                                label={item.label}
                                defaultValue={dayjs(
                                  item.value === null
                                    ? "2022-04-17"
                                    : item.value
                                )}
                                onChange={(newValue) =>
                                  handleFormChange(
                                    index,
                                    newValue,
                                    inputTypes[item.type]
                                  )
                                }
                              />
                            </LocalizationProvider>
                          </div>
                        </div>

                        {rowCount > 2 ? <br /> : <></>}
                      </>
                    );
                  }
                  if (item.type === 0) {
                    return (
                      <>
                        <div
                          key={index}
                          className={
                            item.inline === true ? "inline-not" : "inline"
                          }
                        >
                          <div key={index} className="form_control">
                            <TextField
                              className="input_color"
                              error={
                                item.required
                                  ? isCompleted === true
                                    ? item.value === ""
                                      ? true
                                      : false
                                    : false
                                  : false
                              }
                              required
                              id="outlined-required"
                              label={item.label}
                              defaultValue={item.value}
                              onChange={(event) =>
                                handleFormChange(
                                  index,
                                  event,
                                  inputTypes[item.type]
                                )
                              }
                            />
                          </div>
                        </div>

                        {rowCount > 2 ? <br /> : <></>}
                      </>
                    );
                  }
                  if (item.type === 10) {
                    return (
                      <>
                        <div
                          key={index}
                          className={
                            item.inline === true ? "inline-not" : "inline"
                          }
                        >
                          <div key={index} className="form_control">
                            <TextField
                              className="input_color"
                              error={
                                item.required
                                  ? isCompleted === true
                                    ? item.value === ""
                                      ? true
                                      : false
                                    : false
                                  : false
                              }
                              id="outlined-multiline-static"
                              label={item.label}
                              multiline
                              rows={4}
                              defaultValue={item.value}
                              onChange={(event) =>
                                handleFormChange(
                                  index,
                                  event,
                                  inputTypes[item.type]
                                )
                              }
                            />
                          </div>
                        </div>

                        {rowCount > 2 ? <br /> : <></>}
                      </>
                    );
                  }
                  if (item.type === 1) {
                    return (
                      <>
                        <div
                          key={index}
                          className={
                            item.inline === true
                              ? "inline-not two-line"
                              : "inline"
                          }
                        >
                          <div key={index} className="form_control">
                            <CustomLabel key={index} name={item.label} />
                            {SectionTitle === "Pharmacist" ? (
                              item.label === "Name" ? (
                                <CustomLabel key={index} name={userFullName} />
                              ) : item.label === "Member ID" ? (
                                <CustomLabel key={index} name="1234" />
                              ) : (
                                <CustomLabel key={index} name={item.value} />
                              )
                            ) : (
                              <CustomLabel key={index} name={item.value} />
                            )}
                          </div>
                        </div>
                        {rowCount > 2 ? <br /> : <></>}
                      </>
                    );
                  }
                  if (item.type === 9) {
                    return (
                      <>
                        <div
                          key={index}
                          className={
                            item.inline === true ? "inline-not" : "inline"
                          }
                        >
                          <div className="radio-text-box">
                            <div className="switch-content-vaccine1 input_color">
                              <CustomLabel name={item.label} />
                              <div>
                                <AntSwitch
                                  key={index}
                                  disabled={stageStatus}
                                  inputProps={{ "aria-label": "ant design" }}
                                  onChange={(event) => addText(index)}
                                  checked={addedTexts.includes(index)}
                                />
                              </div>
                            </div>
                            {addedTexts.includes(index) ? (
                              <div
                                key={index}
                                className="outer-item-white-text"
                              >
                                <TextField
                                  className="input_color"
                                  error={
                                    item.required
                                      ? isCompleted === true
                                        ? item.value === ""
                                          ? true
                                          : false
                                        : false
                                      : false
                                  }
                                  required
                                  id="outlined-required"
                                  label="Required"
                                  defaultValue={item.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      inputTypes[item.type]
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        {rowCount > 2 ? <br /> : <></>}
                      </>
                    );
                  }
                  if (item.type === 11) {
                    return (
                      <>
                        <div
                          key={index}
                          className={
                            item.inline === true ? "inline-not" : "inline"
                          }
                        >
                          <div className="switch-content-vaccine1 input_color">
                            <CustomLabel name={item.label} />
                            <div>
                              {item?.listItems?.map((listItems) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={listItems.value}
                                        onChange={(event) =>
                                          handleFormChange(
                                            index,
                                            event,
                                            inputTypes[item.type]
                                          )
                                        }
                                      />
                                    }
                                    label={listItems.label}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        {rowCount > 2 ? <br /> : <></>}
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div
                          key={index}
                          className={
                            item.inline === true ? "inline-not" : "inline"
                          }
                        >
                          <div key={index} className="form_control">
                            <CustomLabel key={index} name={item.label} />
                            <input
                              key={index}
                              className={inputclass + " card-inner-value"}
                              type={inputTypes[item.type]}
                              disabled={stageStatus}
                              value={item.value}
                              onChange={(event) =>
                                handleFormChange(
                                  index,
                                  event,
                                  inputTypes[item.type]
                                )
                              }
                            />
                          </div>{" "}
                        </div>
                        {rowCount > 2 ? <br /> : <></>}
                      </>
                    );
                  }
                })}
              </div>
            }
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default FormSection;
