import React from "react";
import Modal from "react-bootstrap/Modal";
import CustomInputLabel from "../../components/controls/CustomInputLabel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateClinicalServicePaymentDetails } from "../../dispatchers/pharmacyServiceDispatchers";
import useTitanApp from "../../hooks/useTitanApp";

export default function AddPaymentModal({ show, handleClose, handleUpdate }) {
    const { pharmacyServiceState } = useTitanApp();
    const { selectedClinicalServiceId } = pharmacyServiceState;

    const formik = useFormik({
        initialValues: {
            paymentTaken: "",
        },
        validationSchema: Yup.object({
            paymentTaken: Yup.string()
                .matches(/^[0-9]+$/, "Only numbers are allowed.")
                .max(15, "The number can't exceed 16 digits.")
                .required("This field is required."),
        }),
        onSubmit: async (values) => {
            const isPaymentTaken = true;
            if (values.paymentTaken.length > 0) {
                try {
                    await updateClinicalServicePaymentDetails(selectedClinicalServiceId, isPaymentTaken);
                    handleUpdate();
                } catch (error) {
                }
            }
        },
    });

    return (
        <div>
            <Modal
                show={show}
                onHide={() => handleClose()}
                backdrop="static"
                centered
                dialogClassName="addNewModal"
            >
                <Modal.Header closeButton></Modal.Header>
                <div className="addNew_header">
                    <div>
                        <h5>Add Payment Details</h5>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <CustomInputLabel name="Payment Details" />
                    <div className="addNew-input-container">
                        <input
                            id="paymentTaken"
                            name="paymentTaken"
                            type="text"
                            className="custom-input"
                            placeholder="Please enter payment details"
                            value={formik.values.paymentTaken}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            maxLength={16}
                        />
                    </div>
                    {formik.touched.paymentTaken && formik.errors.paymentTaken ? (
                        <div className="error-message">{formik.errors.paymentTaken}</div>
                    ) : null}
                    <button type="submit" className="addNew-button">
                        <div>Save</div>
                    </button>
                </form>
            </Modal>
        </div>
    );
}