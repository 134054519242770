import React from "react";
import { ReactComponent as TitanVerseCompleteSVG } from "./../../assets/images/titanverse_comp.svg";
import { RxCaretRight } from "react-icons/rx";
import { userChangeCompany } from "../../dispatchers/appDispatchers";
import useTitanApp from "../../hooks/useTitanApp";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../controls/CustomLoader";
function DifferentOrganization() {
  let navigate = useNavigate();
  const { auth, appState, onboardingVerificationState } = useTitanApp();
  const { user } = auth || {};

  const { changeCompany, isAppLoading } = appState;
  const userEmail = user?.email || "";
  const { userData } = onboardingVerificationState || {};
  function callChangeCompany(action) {
    userChangeCompany({ email: userData?.emailAddress, type: action }).then(
      (data) => {
        navigate(`/`);
      }
    );
  }
  return (
    <div className="different_organization_page_wrapper">
      {isAppLoading ? (
        <div className="different_organization_wrapper">
          <CustomLoader />
        </div>
      ) : (
        <div className="different_organization_wrapper">
          <TitanVerseCompleteSVG />
          <h5>WARNING</h5>
          <div className="d_flex">
            <p>
              You currently belong to an organisation that is different to the
              invite you have clicked on
            </p>

            <p>
              By continuing, you will be removed from{" "}
              <strong>{changeCompany?.oldCompanyName}</strong> and will join{" "}
              <strong>{changeCompany?.newCompanyName}</strong>
            </p>
          </div>
          <div className="btn_footer">
            <button
              className="cancel"
              onClick={() => {
                callChangeCompany(1);
              }}
            >
              <p>Cancel</p>
            </button>
            <button
              className="go"
              onClick={() => {
                callChangeCompany(0);
              }}
            >
              <p>Go</p>
              <RxCaretRight size={20} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DifferentOrganization;
