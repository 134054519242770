import template from "./template";

class stages {
  constructor(response, stageNo, newTemplate, userFullName) {
    this.obj = response?.map((value, index) => {
      return {
        stageName: value.stageName,

        template: new template(value.template, stageNo, index, newTemplate),
        order: value.order,
        stageType: value.stageType,
        metaDataList: value.metaDataList,
        clinicalServiceStageStatus: value.clinicalServiceStageStatus,
        completedBy: userFullName,
        completedAt: new Date(),
      };
    });
  }
}

export default stages;
