class UserControls {
  constructor(response, updatedresponse, IsUpdated) {
    IsUpdated === true
      ? (this.obj = updatedresponse?.map((updatedItem) => {
          return {
            name: updatedItem.name,
            type: updatedItem.type,
            label: updatedItem.label,
            inline: updatedItem.inline,
            required: updatedItem.required,
            value: updatedItem.value,
            listItems: updatedItem.listItems,
            order: updatedItem.order,
          };
        }))
      : (this.obj = response?.map((item) => {
          return {
            name: item.name,
            type: item.type,
            label: item.label,
            inline: item.inline,
            required: item.required,
            value: item.value,
            listItems: item.listItems,
            order: item.order,
          };
        }));
  }
}

export default UserControls;
