import { v4 as uuidv4 } from "uuid";
import audit from "./audit";
import Medicationtime from "./Medicationtime";
let patientMedicationId = uuidv4();
class setAddMedication {
  constructor(response, medicationobj, MedicationTimeModel, isInterim) {
    this.obj = {
      PatientMedicationTime: new Medicationtime(
        MedicationTimeModel,
        patientMedicationId
      ).obj,
      Audit:
        response !== null
          ? response?.audit
          : [
              {
                PatientMedicationAuditId: uuidv4(),
                PatientMedicationId:
                  response !== null
                    ? response?.patientMedicationId
                    : patientMedicationId,
                PatientMedicationAuditTypeId: 0,
                Value: "string",
              },
            ],
      times: response !== null ? response?.times : "",
      patientMedicationId:
        response !== null ? response?.patientMedicationId : patientMedicationId,
      patientId: medicationobj?.patientId ? medicationobj?.patientId : "",
      prescribedItemId: medicationobj.prescribedItemId
        ? medicationobj.prescribedItemId
        : "",
      dosageText: medicationobj.dose,
      medicationStatus:
        medicationobj.archive === false
          ? 1
          : response !== null
          ? response?.medicationStatus
          : 0,
      preparationType: medicationobj.preparationType,
      repeatQuantity: medicationobj.quantity,
      numberOfTrays: response !== null ? response?.numberOfTrays : 0,
      allowPrintLabel: response !== null ? response?.allowPrintLabel : "",
      showOnReport: medicationobj.report,
      colour: medicationobj.color ? medicationobj.color : "Green",
      marking: medicationobj.marking ? medicationobj.marking : "",
      isInterim:
        isInterim === ""
          ? response !== null
            ? response?.isInterim
            : ""
          : isInterim,
      isPrn: response !== null ? response?.isPrn : false,
      managedBy: response !== null ? response?.managedBy : 0,
      cycleDays: response !== null ? response?.cycleDays : 0,
      uom: response !== null ? response?.uom : "",
      lastStartDate: medicationobj.startDate,
      needsInterimReport:
        response !== null ? response?.needsInterimReport : false,
      preferredProductId:
        response !== null ? response?.preferredProductId : uuidv4(),
      therapyType: medicationobj.therapyType,
      neverAutoArchive: medicationobj.archive,
      transactionId: response !== null ? response.transactionId : "",
    };
  }
}

export default setAddMedication;
