export const medData = [
  {
    type: "Adult",
    description:
      "First episode of non-severe infection, 125mg every 6 hrs (max 2g daily) for 10 days; severe or complicated disease, 500mg every 6 hrs for 10 days.",
  },
  {
    type: "Children",
    description:
      "First episode of non-severe infection, 125mg every 6 hrs (max 2g daily) for 10 days; severe or complicated disease, 500mg every 6 hrs for 10 days.",
  },
];

export const medAboutData = [
  {
    type: "Contraindications",
    description:
      "Malaria prophylaxis in severe renal impairment (CrCl <30ml/min). Lactation.",
  },
  {
    type: "Warnings",
    description:
      "Diarrhoea, vomiting. Not suitable for monotherapy of P. vivax or P. ovale malaria, or cerebral or complicated malaria. Pregnancy.",
  },
  {
    type: "Interactions",
    description:
      "Metoclopramide, tetracyclines, rifampicin, rifabutin, protease inhibitors, efavirenz, coumarin anticoagulants, etoposide.",
  },
  {
    type: "Side Effects",
    description:
      "Headache, anorexia, GI upset, cough, anaemia, neutropenia, fever, abnormal dreams, depression, insomnia, dizziness, hyponatraemia, raised liver enzymes, pruritus, rash.",
  },
  {
    type: "Ingredients",
    description: "Atovaquone/proguanil hydrochlor.",
  },
  {
    type: "Indications",
    description:
      "Acute, uncomplicated P. falciparum malaria particularly where there is likely to be resistance.",
  },
];
