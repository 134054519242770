import React, { useState, useEffect } from "react";
import { GoArrowLeft } from "react-icons/go";
import MembersUserDetails from "../MembersAddNew/components/MembersUserDetails";
import MembersUserAccessRole from "../MembersAddNew/components/MembersUserAccessRole";
import MembersServicesProvided from "../MembersAddNew/components/MembersServicesProvided";
import MembersNotificationSettings from "../MembersAddNew/components/MembersNotificationSettings";
import CustomButton from "./../../../../../../controls/CustomButton";
import useTitanApp from "../../../../../../../hooks/useTitanApp";
import SettingsSidebar from "../../../../../../SettingsSidebar/SettingsSidebar";
import { useNavigate, useLoaderData } from "react-router-dom";
import AsyncRendererRouter from "../../../../../../AsyncRendererRouter/AsyncRendererRouter";
import CustomLoader from "../../../../../../controls/CustomLoader";
import { updateMember } from "../../../../../../../dispatchers/memberDispatchers";
import { showError } from "../../../../../../ToastedMessage/ToastedMessage";
import Schedule from "../../../../../../OrbitSettings/Availability/components/Schedule";
export default function MembersUpdate() {
  const { appState, memberState } = useTitanApp();
  const { darkMode, features } = appState || {};
  const { currentMember, isMemberLoading } = memberState || {};
  const { ifSettingMinimal } = features || {};
  const [addedRole, setAddedRole] = useState();
  const [addedService, setAddedSevice] = useState([]);
  const [addedSlots, setAddedSlots] = useState([]);
  const [addedNotification, setAddedNotification] = useState();
  const [addedMember, setAddedMember] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setAddedMember(currentMember);
    setAddedRole(currentMember?.role);
    setAddedSlots(
      currentMember?.userWeekAvailability
        ? currentMember?.userWeekAvailability
        : []
    );
    setAddedSevice(currentMember?.services ? currentMember?.services : []);
    setAddedNotification(
      currentMember?.taskAssignNotification
        ? currentMember
        : {
            taskAssignNotification: false,
            taskUpdateNotification: false,
            labResultNotification: false,
            newBookingNotification: false,
          }
    );
  }, [currentMember]);

  function handleChangeMember(member) {
    setAddedMember(member);
  }

  function handleChangeServices(services) {
    setAddedSevice(services);
  }

  async function handleSaveMember() {
    const fieldsToKeep = [
      "userId",
      "companyID",
      "emailAddress",
      "firstName",
      "gender",
      "lastName",
      "userImage",
      "isNew",
      "title",
      "isClinician",
      "qualification",
      "registrations",
      "accountType",
      "isVerified",
      "isActive",
      "newCompanyID",
      "lastLogin",
      "userStatus",
      "gPHCNumber",
      "phoneNumber",
    ];
    const newAddedMember = {};
    for (const field of fieldsToKeep) {
      newAddedMember[field] = addedMember[field];
    }
    const fieldsToKeepNot = [
      "newBookingNotification",
      "labResultNotification",
      "taskAssignNotification",
      "taskUpdateNotification",
    ];
    const newAddedNot = {};
    for (const field of fieldsToKeepNot) {
      newAddedNot[field] = addedNotification[field];
    }
    const saveObj = {
      ...newAddedMember,
      role: addedRole,
      services: addedService,
      ...newAddedNot,
      userWeekAvailability: addedSlots,
    };
    const saveRes = await updateMember(saveObj);
    if (saveRes) {
      navigate("/settings/teams/members");
    } else {
      showError("Member could not be updated.", true, false);
    }
  }
  function handleAddedSlots(data) {
    setAddedSlots(data);
  }
  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      isLoading={isMemberLoading}
      resolve={appData}
      loadingElement={
        <div className="settings_page">
          <div className="settings_wrapper">
            <SettingsSidebar onDarkModeChange={darkMode} />
            <div className="settings_content_wrapper">
              <CustomLoader />
            </div>
          </div>
        </div>
      }
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar onDarkModeChange={darkMode} />
          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <div>
                <div
                  className="back_to_SettingsListView"
                  onClick={() => {
                    navigate("/settings/teams/members");
                  }}
                >
                  <GoArrowLeft className="icons-color" />
                  <p>Back</p>
                </div>
                <div className="addNewMember_wrapper">
                  <h3></h3>
                  <p></p>
                </div>
                <div className="addMember_formFields">
                  <div className="inner-panels">
                    <div className="AddNew_sectionbox">
                      <h4>User Detail</h4>
                      <MembersUserDetails
                        onDarkModeChange={darkMode}
                        addedMember={addedMember}
                        handleChangeMember={handleChangeMember}
                      />
                    </div>
                    {!ifSettingMinimal && (
                      <div className="AddNew_sectionbox">
                        <h4>User Access Role</h4>
                        <MembersUserAccessRole
                          addedRole={addedRole}
                          setAddedRole={setAddedRole}
                        />
                      </div>
                    )}
                    {!ifSettingMinimal && (
                      <div className="AddNew_sectionbox">
                        <h4>Services Provided</h4>
                        <MembersServicesProvided
                          addedService={addedService}
                          handleChangeServices={handleChangeServices}
                        />
                      </div>
                    )}
                    {!ifSettingMinimal && (
                      <div className="AddNew_sectionbox">
                        <h4>Notification Settings</h4>
                        <MembersNotificationSettings
                          addedNotification={addedNotification}
                          setAddedNotification={setAddedNotification}
                        />
                      </div>
                    )}
                    {!ifSettingMinimal && (
                      <div className="AddNew_sectionbox">
                        <h4>Availability</h4>
                        <Schedule
                          addedSlots={addedSlots}
                          handleAddedSlots={handleAddedSlots}
                        />
                      </div>
                    )}
                    <div className="footer_btn">
                      <CustomButton
                        label="Save Changes"
                        action={handleSaveMember}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
