import React, { useState } from "react";
import SettingsSidebar from "../../../../SettingsSidebar/SettingsSidebar";
import AsyncRendererRouter from "../../../../AsyncRendererRouter/AsyncRendererRouter";
import { useLoaderData } from "react-router";
import useTitanType from "../../../../../hooks/useTitanType";
import ServiceTypesList from "./components/ServiceTypesList/ServiceTypesList";
import ServiceTypesTab from "./components/ServiceTypesTabs/ServiceTypesTabs";
import ServiceTypesLoadingComponent from "../../../../SettingsLoadingComponents/ServiceTypesLoadingComponent";

export default function ServiceTypes() {
  const { typeState } = useTitanType();
  const { isTypeLoading } = typeState || {};

  const { appData } = useLoaderData() || {};

  const [serviceTabs, setServiceTabs] = useState(false);
  return (
    <AsyncRendererRouter
      resolve={appData}
      isLoading={isTypeLoading}
      loadingElement={<ServiceTypesLoadingComponent />}
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar />
          <div className="settings_content_wrapper">
            {serviceTabs ? (
              <ServiceTypesTab setServiceTabs={setServiceTabs} />
            ) : (
              <ServiceTypesList setServiceTabs={setServiceTabs} />
            )}
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
