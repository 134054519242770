/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import "./../../../../../assets/css/Dashboard/ServicesNewClinic.scss";
import Card from "@mui/material/Card";
import { icons } from "../../ServicesIcons/serviceIconsEnum";
import earwaximage from "../../../../../assets/images/earvwax.svg";
import pillsimage from "../../../../../assets/images/pillsimage.svg";
import { ReactComponent as NHSIcon } from "../../ServicesIcons/nhs.svg";
import { ReactComponent as MessageIcon } from "../../ServicesIcons/message.svg";
import { ReactComponent as StethoIcon } from "../../ServicesIcons/stethoscope.svg";
import NewServiceStepsModal from "./Modals/NewServiceStepsModal";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { TfiRuler } from "react-icons/tfi";
import ServiceOverview from "./ServiceOverview";
import CustomButton from "../../../../../components/controls/CustomButton";
import LoadingComponentServices from "../../../../../components/LoadingComponentServices/LoadingComponentServices";
import {
  getClinicalServiceBuilderById,
  getServiceTemplates,
} from "../../../../../dispatchers/pharmacyServiceDispatchers";
import travelBg from "../../../../../assets/images/travelvaccine.png";
import weightLossBg from "../../../../../assets/images/weightLossBg.png";
export default function ServicesNewClinic({ handleUpdateServices }) {
  const { pharmacyServiceState, appState } = useTitanApp();
  const { darkMode } = appState || {};
  const {
    currentServiceData,
    defaultPharmacyServices,
    isPharmacyServiceLoading,
    companyServices,
  } = pharmacyServiceState || {};
  const [value, setValue] = useState(0);
  const [selectedService, setSelectedService] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [showServiceDetails, setShowServiceDetails] = useState(false);

  const profileImageUrl =
    "https://img.freepik.com/free-icon/man_318-157507.jpg";

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setShowServiceDetails(false);
  };

  const handleModalOpen = (clinic) => {
    setSelectedService(clinic);
    setOpenModal(true);
  };

  const handleOpenServiceDetails = (clinic) => {
    if (!isServiceExist(clinic)) {
      setSelectedService(clinic);
      setShowServiceDetails(!showServiceDetails);

      getClinicalServiceBuilderById(clinic._id).then((res) => {
        const templateIds = res.stages
          .map((stage) => stage.templateId)
          .filter((value, index, self) => self.indexOf(value) === index);

        getServiceTemplates(templateIds).then((templatesRes) => { });
      });
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
  function isServiceExist(service) {
    var isAvb = companyServices.some(
      (item) => item?.serviceID === service?._id
    );
    return isAvb;
  }
  const renderTabContent = () => {
    switch (value) {
      case 0:
        return (
          <div className="service_content-bg service_kanban">
            {showServiceDetails && <ServiceOverview />}
            {!showServiceDetails && (
              <>
                <div className="popularServices">
                  <p className="popularServicesHeading">Popular templates</p>
                  <div className="innerServices">
                    <div>
                      <div className="serviceCard">
                        <div>
                          <Card className="myCard" sx={{ minWidth: 438 }}>
                            <div className="cardHeading">
                              New Medicine Service
                            </div>
                            <div className="serviceIcon">
                              <StethoIcon width={40} height={40} />
                            </div>

                            <NHSIcon className="nhsserviceIcon" />
                          </Card>
                        </div>
                      </div>
                      <div className="lowerIconBar">
                        <div class="rounded-image">
                          <img src={profileImageUrl} alt="Profile Image" />
                        </div>
                        <div class="details">
                          <p className="createdBy">Created by</p>
                          <p className="createdByName">Titan PMR</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="serviceCard"
                        style={{
                          backgroundImage: `url(${earwaximage})`, // Use the imported image here
                          backgroundSize: "cover", // Ensures the image covers the entire card
                          backgroundPosition: "center", // Centers the background
                        }}
                      >
                        <div>
                          <Card
                            className="myCard"
                            sx={{
                              minWidth: 438,
                            }}
                          >
                            <div className="cardHeading">Earwax Removal</div>
                            <div className="serviceIcon"> </div>
                            <NHSIcon className="nhsserviceIcon" />
                          </Card>
                        </div>
                      </div>
                      <div className="lowerIconBar">
                        <div class="rounded-image">
                          <img src={profileImageUrl} alt="Profile Image" />
                        </div>
                        <div class="details">
                          <p className="createdBy">Created by</p>
                          <p className="createdByName">Titan PMR</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="serviceCard"
                        style={{
                          backgroundImage: `url(${pillsimage})`, // Use the imported image here
                          backgroundSize: "cover", // Ensures the image covers the entire card
                          backgroundPosition: "center", // Centers the background
                        }}
                      >
                        <div>
                          <Card
                            className="myCard"
                            sx={{
                              minWidth: 438,
                            }}
                          >
                            <div className="cardHeading">Viagra</div>
                            <div className="serviceIcon"></div>
                            <NHSIcon className="nhsserviceIcon" />
                          </Card>
                        </div>
                      </div>
                      <div className="lowerIconBar">
                        <div class="rounded-image">
                          <img src={profileImageUrl} alt="Profile Image" />
                        </div>
                        <div class="details">
                          <p className="createdBy">Created by</p>
                          <p className="createdByName">Titan PMR</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="popularServices nhs-section">
                  <p className="popularServicesHeading">NHS Services</p>

                  <div className="innerServices">
                    {defaultPharmacyServices?.map((clinic, index) => {
                      switch (clinic.serviceType) {
                        case 1:
                          return (
                            <div key={index}>
                              <div className="serviceCard serviceCard_enabled">
                                <div>
                                  <Card
                                    className="myCard"
                                    sx={{ minWidth: 438 }}
                                  >
                                    <div>
                                      <div className="cardHeading">
                                        {clinic.serviceName}
                                      </div>
                                      {isServiceExist(clinic) ? (
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            width="16"
                                            height="16"
                                            rx="8"
                                            fill="#00CC6A"
                                          />
                                          <path
                                            d="M6.48857 9.51115L4.99581 7.97574C4.768 7.74143 4.39866 7.74143 4.17085 7.97574C3.94305 8.21006 3.94305 8.58995 4.17085 8.82427L6.11529 10.8243C6.35784 11.0737 6.75631 11.0551 6.9759 10.7841L10.8648 5.98412C11.071 5.72956 11.0376 5.35122 10.7901 5.13908C10.5426 4.92694 10.1748 4.96134 9.96852 5.2159L6.48857 9.51115Z"
                                            className="icons-color"
                                          />
                                        </svg>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className="serviceIcon">
                                      {clinic.serviceName ===
                                        "New Medicine Service" ? (
                                        <StethoIcon width={40} height={40} />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div>
                                      <NHSIcon className="nhsserviceIcon" />
                                    </div>
                                    {/* {clinic?.isEnabled ? (
                                      <div className="service_status">
                                        <span className="status_tag">
                                          installed
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )} */}
                                  </Card>
                                </div>
                              </div>
                              <div className="lowerIconBar">
                                <div class="rounded-image">
                                  <img
                                    src={profileImageUrl}
                                    alt="Profile Image"
                                  />
                                </div>
                                <div class="details">
                                  <p className="createdBy">Created by</p>
                                  <p className="createdByName">Titan PMR</p>
                                </div>
                              </div>
                            </div>
                          );
                      }
                    })}
                  </div>
                </div>

                <div className="popularServices">
                  <p className="popularServicesHeading">Private Services</p>
                  <div className="innerServices">
                    {defaultPharmacyServices?.map((clinic, index) => {
                      const backImage = icons[clinic.clinicaServiceTypeId];
                      switch (clinic.serviceType) {
                        case 2:
                          return (
                            <div key={index}>
                              <div
                                className="serviceCard"
                                onClick={() => handleOpenServiceDetails(clinic)}
                                style={{
                                  backgroundImage:
                                    clinic?.serviceName === "Travel Vaccine"
                                      ? `url(${travelBg})`
                                      : clinic?.serviceName === "Weight Loss" ? `url(${weightLossBg})`
                                        : "linear-gradient(140deg,rgba(0, 94, 184, 0.3),rgba(0, 94, 184, 0.1)",
                                  backgroundSize: "cover", // Ensures the background covers the entire area
                                  backgroundRepeat: "no-repeat", // Prevents repeating the image
                                  backgroundPosition: "center", // Ensures the image is centered
                                }}
                              >
                                <div>
                                  <Card
                                    className="myCard"
                                    sx={{ minWidth: 225 }}
                                  >
                                    <div>
                                      <div className="cardHeading">
                                        {clinic.serviceName}
                                        {isServiceExist(clinic) ? (
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              width="16"
                                              height="16"
                                              rx="8"
                                              fill="#00CC6A"
                                            />
                                            <path
                                              d="M6.48857 9.51115L4.99581 7.97574C4.768 7.74143 4.39866 7.74143 4.17085 7.97574C3.94305 8.21006 3.94305 8.58995 4.17085 8.82427L6.11529 10.8243C6.35784 11.0737 6.75631 11.0551 6.9759 10.7841L10.8648 5.98412C11.071 5.72956 11.0376 5.35122 10.7901 5.13908C10.5426 4.92694 10.1748 4.96134 9.96852 5.2159L6.48857 9.51115Z"
                                              className="icons-color"
                                            />
                                          </svg>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                    <div className="serviceIconP">
                                      {/* <img
                                    src={icons[clinic.clinicaServiceTypeId]}
                                  /> */}
                                    </div>
                                  </Card>
                                </div>
                              </div>
                              <div className="lowerIconBar">
                                <div class="rounded-image">
                                  <img
                                    src={profileImageUrl}
                                    alt="Profile Image"
                                  />
                                </div>
                                <div class="details">
                                  <p className="createdBy">Created by</p>
                                  <p className="createdByName">Titan PMR</p>
                                </div>
                              </div>
                            </div>
                          );
                      }
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 1:
        return (
          <div className="service_content-bg service_kanban">
            {showServiceDetails && <ServiceOverview />}
            {!showServiceDetails && (
              <div className="popularServices">
                <p className="popularServicesHeading">NHS Services</p>

                <div className="innerServices">
                  {defaultPharmacyServices?.map((clinic, index) => {
                    switch (clinic.serviceType) {
                      case 1:
                        return (
                          <div key={index}>
                            <div
                              className={
                                clinic?.isEnabled
                                  ? "serviceCard serviceCard_enabled"
                                  : "serviceCard"
                              }
                            >
                              <div>
                                <Card className="myCard" sx={{ minWidth: 438 }}>
                                  <div>
                                    <div className="cardHeading">
                                      {clinic.serviceName}
                                    </div>
                                    {isServiceExist(clinic) ? (
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          width="16"
                                          height="16"
                                          rx="8"
                                          fill="#00CC6A"
                                        />
                                        <path
                                          d="M6.48857 9.51115L4.99581 7.97574C4.768 7.74143 4.39866 7.74143 4.17085 7.97574C3.94305 8.21006 3.94305 8.58995 4.17085 8.82427L6.11529 10.8243C6.35784 11.0737 6.75631 11.0551 6.9759 10.7841L10.8648 5.98412C11.071 5.72956 11.0376 5.35122 10.7901 5.13908C10.5426 4.92694 10.1748 4.96134 9.96852 5.2159L6.48857 9.51115Z"
                                          className="icons-color"
                                        />
                                      </svg>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="serviceIcon">
                                    {clinic.serviceName ===
                                      "New Medicine Service" ? (
                                      <StethoIcon width={40} height={40} />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <NHSIcon className="nhsserviceIcon" />
                                </Card>
                              </div>
                            </div>
                            <div className="lowerIconBar">
                              <div class="rounded-image">
                                <img
                                  src={profileImageUrl}
                                  alt="Profile Image"
                                />
                              </div>
                              <div class="details">
                                <p className="createdBy">Created by</p>
                                <p className="createdByName">Titan PMR</p>
                              </div>
                            </div>
                          </div>
                        );
                    }
                  })}
                </div>
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div className="service_content-bg service_kanban">
            {showServiceDetails && <ServiceOverview />}
            {!showServiceDetails && (
              <div className="popularServices">
                <p className="popularServicesHeading">Private Services</p>
                <div className="innerServices">
                  {defaultPharmacyServices?.map((clinic, index) => {
                    const backImage = icons[clinic.clinicaServiceTypeId];
                    switch (clinic?.serviceType) {
                      case 2:
                        return (
                          <div key={index}>
                            <div
                              className="serviceCard"
                              onClick={() => handleOpenServiceDetails(clinic)}
                              style={{
                                backgroundImage:
                                  clinic.serviceName === "Travel Vaccine"
                                    ? `url(${travelBg})`
                                    : "linear-gradient(140deg,rgba(0, 94, 184, 0.3),rgba(0, 94, 184, 0.1)",
                                backgroundSize: "cover", // Ensures the background covers the entire area
                                backgroundRepeat: "no-repeat", // Prevents repeating the image
                                backgroundPosition: "center", // Ensures the image is centered
                              }}
                            >
                              <div>
                                <Card className="myCard" sx={{ minWidth: 225 }}>
                                  <div>
                                    <div className="cardHeading">
                                      {clinic.serviceName}
                                      {isServiceExist(clinic) ? (
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            width="16"
                                            height="16"
                                            rx="8"
                                            fill="#00CC6A"
                                          />
                                          <path
                                            d="M6.48857 9.51115L4.99581 7.97574C4.768 7.74143 4.39866 7.74143 4.17085 7.97574C3.94305 8.21006 3.94305 8.58995 4.17085 8.82427L6.11529 10.8243C6.35784 11.0737 6.75631 11.0551 6.9759 10.7841L10.8648 5.98412C11.071 5.72956 11.0376 5.35122 10.7901 5.13908C10.5426 4.92694 10.1748 4.96134 9.96852 5.2159L6.48857 9.51115Z"
                                            className="icons-color"
                                          />
                                        </svg>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  <div className="serviceIconP"></div>
                                </Card>
                              </div>
                            </div>
                            <div className="lowerIconBar">
                              <div class="rounded-image">
                                <img
                                  src={profileImageUrl}
                                  alt="Profile Image"
                                />
                              </div>
                              <div class="details">
                                <p className="createdBy">Created by</p>
                                <p className="createdByName">Titan PMR</p>
                              </div>
                            </div>
                          </div>
                        );
                    }
                  })}
                </div>
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };
  return (
    <>
      <div className="newClinicsContainer">
        <div className="newClinicsContainerWrapper">
          <div className="tabs_box services_container-tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "black",
                },
              }}
            >
              <Tab
                // icon={kanbanIcon}
                iconPosition="start"
                label={<span className={`tabs_text`}>All</span>}
              />
              <Tab
                // icon={listviewIcon}
                iconPosition="start"
                label={<span className={`tabs_text`}>NHS</span>}
              />

              <Tab
                // icon={calendarIcon}
                iconPosition="start"
                label={<span className={`tabs_text`}>Private</span>}
              />
            </Tabs>
          </div>
        </div>
      </div>
      <div className="services_container-content-area">
        {isPharmacyServiceLoading ? (
          <>
            <LoadingComponentServices />
          </>
        ) : (
          renderTabContent()
        )}
      </div>
    </>
  );
}
