import { getObject } from "../getObjectValue";
import stages from "./stages";
import metaDataList from "./metaDataList";
class setServicePrivate {
  constructor(
    response,
    stageNo,
    title,
    userControls,
    isCompleted,
    clincalServiceType,
    Id,
    patientName,
    declined,
    startDate,
    userFullName,
    groupId,
    userEmail
  ) {
    this._id = response._id ? response._id : "";
    this.patientId =
      Id === null ? (response.patientId ? response.patientId : "") : Id;
    this.patientName =
      patientName === null
        ? response.patientName
          ? response.patientName
          : ""
        : patientName;
    this.serviceName = response.serviceName ? response.serviceName : "";
    this.stages = response.stages
      ? new stages(
          response.stages,
          stageNo,
          title,
          userControls,
          isCompleted,
          startDate,
          userFullName,
          groupId,
          userEmail
        ).obj
      : [];
    this.numberOfStages = response.numberOfStages
      ? response.numberOfStages
      : "";
    this.serviceType = response.serviceType ? response.serviceType : 2;
    this.clinicalServiceStatus = stageNo === 1 ? 2 : 1;
    this.clinicalServiceDate = response.clinicalServiceDate;
    this.pharmacyID = response.pharmacyID;
    this.serviceCode = response.serviceCode;
    this.prescriptionID = response.prescriptionID;
    this.serviceID = response.serviceID;
    this.prescriptionIncomingId = response.prescriptionIncomingId;
    this.sendSms = response.sendSms;
    this.sendToOrbit = response.sendToOrbit;
    this.telephoneNumber = response.telephoneNumber;
    this.patientTags =
      response.patientTags === null ? [] : response.patientTags;
    this.serviceMedications =
      response.serviceMedications === null ? [] : response.serviceMedications;
    this.dob = response.dob;
    this.isTest = response.isTest;
  }
}

export default setServicePrivate;
