import React, { useState } from "react";
import CustomLabel from "../../../../../components/controls/CustomLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { toggleUserService } from "../../../../../dispatchers/orbitDispatchers";
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "& .MuiSwitch-thumb": {
    width: 15,
    backgroundColor: "yellow",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    transform: "translateX(9px)",
    "& .MuiSwitch-thumb": {
      backgroundColor: "white",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      backgroundColor: "grey",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 8,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,0.05)",
    boxSizing: "border-box",
  },
}));

function SkillsetAllocation() {
  const { appState, orbitState, onboardingVerificationState } = useTitanApp();
  const { orbitPharmanautUser, pharmanautServices, pharmanautServiceLoader } =
    orbitState || {};
  const { services: userServices } = orbitPharmanautUser || {};
  const isServiceSelected = (_id) => {
    return (
      userServices?.some((userService) => userService._id === _id) || false
    );
  };

  const handleServiceToggle = (service) => {
    toggleUserService(service);
  };

  return (
    <div className="user-control">
      <div className="skillset-inline-not">
        {pharmanautServices && pharmanautServices.length > 0 ? (
          pharmanautServices.map((service, index) => (
            <div
              key={index}
              className={`switch-content-reports-pharmanaut ${
                isServiceSelected(service._id) ? "selected-service-border" : ""
              }`}
            >
              <CustomLabel name={service.serviceName} />
              <div>
                <AntSwitch
                  checked={isServiceSelected(service._id)}
                  onChange={() => handleServiceToggle(service)}
                  inputProps={{ "aria-label": service.serviceName }}
                />
              </div>
            </div>
          ))
        ) : (
          <div>
            <p className="orbit-no-data-note t-center">No data available</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SkillsetAllocation;
