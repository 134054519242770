import React from "react";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../../../../hooks/useTitanApp";
function OrbitAgencySkeleton() {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  return (
    <>
      <div className="orbit_page_wrapper">
        <div className="orbit_header">
          <div className="top-block">
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", borderRadius: "16px" }}
              height={40}
              width={135}
              borderRad
              animation="wave"
              variant="rounded"
            />
          </div>
          <div className="icons_wrapper">
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", borderRadius: "16px" }}
              height={30}
              width={320}
              borderRad
              animation="wave"
              variant="rounded"
            />
          </div>
        </div>
        <div className="orbit_user_image">
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            variant="circular"
            animation="wave"
            width={80}
            height={80}
          />
        </div>
        <div className="orbit_grid_wrapper">
          <div className="orbit_grid_new orbit_grid_top_panel">
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", borderRadius: "16px" }}
              height={80}
              width={590}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", borderRadius: "16px" }}
              height={80}
              width={590}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", borderRadius: "16px" }}
              height={80}
              width={590}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", borderRadius: "16px" }}
              height={80}
              width={590}
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", borderRadius: "16px" }}
              height={80}
              width={590}
              animation="wave"
              variant="rounded"
            />
          </div>

          <div className="orbit_grid_new">
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", borderRadius: "16px" }}
              height={400}
              width="100%"
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", borderRadius: "16px" }}
              height={400}
              width="100%"
              animation="wave"
              variant="rounded"
            />
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "", borderRadius: "16px" }}
              height={400}
              width="100%"
              animation="wave"
              variant="rounded"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default OrbitAgencySkeleton;
