import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { CustomAntSwitch } from "../../../../../../../../../controls/CustomAntSwitch";
import useTitanType from "../../../../../../../../../../hooks/useTitanType";
import useTitanApp from "../../../../../../../../../../hooks/useTitanApp";
import { setProductAppointments } from "../../../../../../../../../../dispatchers/typeDispatchers";
export default function ProductTypesAppointment() {
  const { appState } = useTitanApp();
  const { typeState } = useTitanType();
  const { clinicalServiceBuilderModel, defaultClinicalServiceBuilderModel } =
    typeState;

  const appointmentOptions =
    clinicalServiceBuilderModel?.clinicalServiceBuilder?.product
      ?.appointmentOptions ??
    defaultClinicalServiceBuilderModel?.clinicalServiceBuilder?.product
      ?.appointmentOptions ??
    {};

  // Safely set initial state from appointmentOptions
  const [duration, setDuration] = useState(appointmentOptions?.duration ?? "");
  const [colour, setColour] = useState(appointmentOptions?.colour ?? "");
  const [isEnablePrePayment, setIsEnablePrePayment] = useState(
    appointmentOptions?.isEnablePrePayment ?? false
  );

  // Handle text field changes
  const handleTextFieldChange = (event) => {
    const { name, value } = event.target;
    if (name === "duration") {
      setDuration(value);
    } else if (name === "colour") {
      setColour(value);
    }
    setProductAppointments({
      colour: name === "colour" ? value : colour,
      duration: name === "duration" ? value : duration,
      isEnablePrePayment: isEnablePrePayment,
    });
  };

  // Handle switch change for "isEnablePrePayment"
  const handleSwitchChange = (event) => {
    const checked = event.target.checked;
    setIsEnablePrePayment(checked);
    setProductAppointments({
      colour: colour,
      duration: duration,
      isEnablePrePayment: checked,
    });
  };

  return (
    <div className="privateservices_two_layout">
      <div className="settings_formFields">
        <TextField
          id="duration"
          label="Duration (Mins)"
          variant="outlined"
          name="duration"
          fullWidth
          value={duration}
          onChange={handleTextFieldChange}
        />

        <TextField
          id="colour"
          label="Colour"
          variant="outlined"
          name="colour"
          fullWidth
          type="text"
          value={colour}
          onChange={handleTextFieldChange}
        />
      </div>
      <div className="d-flex">
        <p>Enable pre-payment for online booking</p>
        <CustomAntSwitch
          name="isEnablePrePayment"
          checked={isEnablePrePayment ?? false} // Safely fallback to false
          onChange={handleSwitchChange}
        />
      </div>
    </div>
  );
}
