import { v4 as uuidv4 } from "uuid";

class setUpdateTeam {
  constructor(
    currentTeam,
    currentTeamId,
    teamName,
    company_Id,
    displayName,
    tempUpdatedSelectedPharmacy,
    teamImage,
    userFullName,
    userFirstName,
    userLastName,
    userEmail
  ) {
    this.obj = {
      teamID: currentTeamId,

      teamName: teamName,
      companyID: company_Id,

      TeamDisplayName: teamName,
      createdBy: currentTeam?.createdBy,

      createdDate: currentTeam?.createdDate,

      isActive: currentTeam?.isActive,

      isDeleted: currentTeam?.isDeleted,

      isGlobalView: currentTeam?.isGlobalView,

      updatedBy: currentTeam?.updatedBy,

      updatedDate: new Date(),

      ownerName: userFullName,

      ownerId: currentTeam?.ownerId,

      teamImage: teamImage,

      pharmacies: tempUpdatedSelectedPharmacy,

      teamMembers: [
        {
          teamMemberID: currentTeamId,
          companyID: company_Id,
          userFullName: userFullName,
          userFirstName: userFirstName,
          userLastName: userLastName,
          teamMemberAccessType: 0,
          teamJoiningStatus: 0,
          joiningDate: new Date(),
          gender: 1,
          email: userEmail,
          isOwner: true,
        },
      ],
    };
  }
}

export default setUpdateTeam;
