import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost, httpPut } from "../../services/httpService";
import {
  addOrbitSessionUrl,
  updateOrbitSessionUrl,
  getOrbitProfileUrl,
  saveOrbitProfileUrl,
  addOrbitAuditUrl,
  getOrbitAuditBySessionIdUrl,
  getOrbitDashboardUrl,
  getOrbitMonthlyReportUrl,
  getOrbitEarningsUrl,
  getOrbitUserSessionsUrl,
  getOrbitFollowUpServicesUrl,
  getOrbitPotentialEarningsUrl,
  getOrbitOtherDetailsUrl,
  getOrbitParkedServicesUrl,
  changeOrbitServiceStatusUrl,
  getOrbitActiveServicesUrl,
  addTestModeServiceUrl,
  getPaymentDetailsUrl,
  getOrbitTeamByCompanyId,
  getOrbitAgencyUsers,
  getOrbitAgencyDetails,
  getOrbitAgencyTimeAndCompleted,
  getAgencyPharmacies,
  getAgencyOtherPharmacies,
  getOrbitAgencyMonthlyReport,
  getOrbitPharmanautsWorkPlannerUrl,
  getOrbitServicesWorkPlannerUrl,
  getOrbitWorkPlannerUrl,
  getUserByEmail,
  getOthersAvailable,
  addExclusivePharmacy,
  getOrbitAgencyServicesByCompanyId,
  SaveOrbitPharmanautServices,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";

const initialState = {
  isOrbitLoading: false,
  currentSessionId: null,
  isStart: false,
  orbitAnimation: false,
  isAnimationPlaying: false,
  openSuccessDialog: false,
  totalCount: 0,
  totalPrice: 0,
  orbitSessionTimer: 0,
  timer: 0,
  isActive: false,
  orbitSessionMinutes: "0",
  orbitSessionSeconds: "0",
  isStopTimer: false,
  startTimeObj: null,
  orbitAudit: null,
  lastOrbitSessionAddedUpdated: null,
  orbitProfile: [],
  profDetails: [],
  payDetails: [],
  avbDetails: [],
  accredationsDetails: [],
  compDocDetails: [],
  orbitAudit: [],
  orbitDashboardData: [],
  monthlyReport: null,
  orbitEarnings: [],
  orbitUserSessions: [],
  potentialEarnings: null,
  otherDetails: null,
  parkedServices: [],
  activeServices: [],
  followupServices: [],
  isMonthlyReportLoading: false,
  isOrbitEarningLoading: false,
  isUserSessionLoading: false,
  isPotentialEarningLoading: false,
  isFollowupLoading: false,
  isOtherDetailsLoading: false,
  isParkedServicesLoading: false,
  isActiveServicesLoading: false,
  showTestModeDialog: false,
  isTestMode: false,
  bankPaymentDetails: false,
  orbitTeam: [],
  orbitAgencyUsers: [],
  orbitAgencyLoader: false,
  orbitAgencyDetails: [],
  orbitAgencyDetailsLoader: false,
  orbitAgencyOtherDetailsLoader: false,
  orbitAgencyOtherDetails: [],
  orbitAgencyPharmacies: [],
  orbitAgencyPharmaciesLoader: false,
  othersAvailable: [],
  othersAvailableLoader: false,
  orbitAgencyOtherPharmacies: [],
  orbitAgencyOtherPharmaciesLoader: false,
  sidebarVisible: false,
  isAgencyMonthlyReportLoading: false,
  orbitAgencyMonthlyReport: [],
  orbitServicesWorkPlanner: [],
  orbitServicesWorkPlannerLoader: false,
  orbitPharmanautsWorkPlanner: [],
  orbitPharmanautsWorkPlannerLoader: false,
  orbitWorkPlanner: [],
  orbitWorkPlannerLoader: false,
  selectedRowData: null,
  orbitPharmanautUser: [],
  pharmanautUserLoader: false,
  pharmanautServiceLoader: false,
  pharmanautServices: [],
};
export const addOrbitSessionData = createAsyncThunk(
  "orbit/addOrbitSessionData",
  async (data, thunkAPI) => {
    const respData = await httpPost(addOrbitSessionUrl(), data);
    return respData;
  }
);

export const updateOrbitSessionData = createAsyncThunk(
  "orbit/updateOrbitSessionData",
  async (data, thunkAPI) => {
    const respData = await httpPost(updateOrbitSessionUrl(), data);
    return respData;
  }
);
export const getOrbitProfileData = createAsyncThunk(
  "orbit/getOrbitProfileData",
  async () => {
    const respData = await httpGet(getOrbitProfileUrl());
    return respData;
  }
);
export const saveOrbitProfileData = createAsyncThunk(
  "orbit/saveOrbitProfileData",
  async (data, thunkAPI) => {
    const respData = await httpPost(saveOrbitProfileUrl(), data);
    return respData;
  }
);

export const addOrbitAuditData = createAsyncThunk(
  "orbit/addOrbitAuditData",
  async (body, thunkAPI) => {
    const { data, actionType, actionDate } = body;
    const respData = await httpPost(
      addOrbitAuditUrl(actionType, actionDate),
      data
    );
    return respData;
  }
);

export const getOrbitAuditBySessionIdData = createAsyncThunk(
  "orbit/getOrbitAuditBySessionIdData",
  async (param) => {
    const { searchType, sessionId } = param || {};
    const respData = await httpGet(
      getOrbitAuditBySessionIdUrl(searchType, sessionId)
    );
    return respData;
  }
);

export const getOrbitDashboardData = createAsyncThunk(
  "orbit/getOrbitDashboardData",
  async () => {
    const respData = await httpGet(getOrbitDashboardUrl());
    return respData;
  }
);

export const getOrbitMonthlyReportData = createAsyncThunk(
  "orbit/getOrbitMonthlyReportData",
  async (param) => {
    const { startDate, endDate } = param || {};
    const respData = await httpGet(
      getOrbitMonthlyReportUrl(startDate, endDate)
    );
    return respData;
  }
);

export const getOrbitEarningsData = createAsyncThunk(
  "orbit/getOrbitEarningsData",
  async (param) => {
    const { startDate, endDate } = param || {};
    const respData = await httpGet(getOrbitEarningsUrl(startDate, endDate));
    return respData;
  }
);

export const getOrbitUserSessionsData = createAsyncThunk(
  "orbit/getOrbitUserSessionsData",
  async () => {
    const respData = await httpGet(getOrbitUserSessionsUrl());
    return respData;
  }
);

export const getOrbitParkedServicesData = createAsyncThunk(
  "orbit/getOrbitParkedServicesData",
  async (params) => {
    const respData = await httpGet(getOrbitParkedServicesUrl(params));
    return respData;
  }
);
export const getOrbitActiveServicesData = createAsyncThunk(
  "orbit/getOrbitActiveServicesData",
  async (params) => {
    const { currentSessionId, isTestMode } = params || {};
    const respData = await httpGet(
      getOrbitActiveServicesUrl(currentSessionId, isTestMode)
    );
    return respData;
  }
);
export const changeOrbitServiceStatusData = createAsyncThunk(
  "orbit/changeOrbitServiceStatusData",
  async (params) => {
    const { serviceId, status } = params;
    const respData = await httpGet(
      changeOrbitServiceStatusUrl(serviceId, status)
    );
    return respData;
  }
);

export const getOrbitFollowUpServicesData = createAsyncThunk(
  "orbit/getOrbitFollowUpServicesData",
  async () => {
    const respData = await httpGet(getOrbitFollowUpServicesUrl());
    return respData;
  }
);

export const getOrbitPotentialEarningsData = createAsyncThunk(
  "orbit/getOrbitPotentialEarningsData",
  async () => {
    const respData = await httpGet(getOrbitPotentialEarningsUrl());
    return respData;
  }
);

export const getOrbitOtherDetailsData = createAsyncThunk(
  "orbit/getOrbitOtherDetailsData",
  async () => {
    const respData = await httpGet(getOrbitOtherDetailsUrl());
    return respData;
  }
);

export const addTestModeServiceData = createAsyncThunk(
  "kpi/addTestModeServiceData",
  async (params) => {
    const { telephoneNumber, numberOfScripts } = params || {};
    const respData = await httpPost(
      addTestModeServiceUrl(telephoneNumber, numberOfScripts)
    );
    return respData;
  }
);

export const getPaymentDetailsData = createAsyncThunk(
  "orbit/getPaymentDetailsData",
  async (thunkAPI) => {
    const respData = await httpGet(getPaymentDetailsUrl());
    return respData;
  }
);

export const getOthersAvailableData = createAsyncThunk(
  "orbit/getOthersAvailableData",
  async (thunkAPI) => {
    const respData = await httpGet(getOthersAvailable());
    return respData;
  }
);

export const getOrbitTeamByCompanyIdData = createAsyncThunk(
  "pharmacyService/getOrbitTeamByCompanyIdData",
  async (companyId) => {
    const urlToGet = getOrbitTeamByCompanyId(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getOrbitAgencyUsersData = createAsyncThunk(
  "pharmacyService/getOrbitAgencyUsersData",
  async (companyId) => {
    const urlToGet = getOrbitAgencyUsers(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getOrbitAgencyDetailsData = createAsyncThunk(
  "pharmacyService/getOrbitAgencyDetailsData",
  async (companyId) => {
    const urlToGet = getOrbitAgencyDetails(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getOrbitAgencyTimeAndCompletedData = createAsyncThunk(
  "pharmacyService/getOrbitAgencyTimeAndCompletedData",
  async (companyId) => {
    const urlToGet = getOrbitAgencyTimeAndCompleted(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getAgencyPharmaciesData = createAsyncThunk(
  "orbit/getAgencyPharmaciesData",
  async (params) => {
    const { companyId, workLoadType } = params || {};
    const respData = await httpGet(
      getAgencyPharmacies(companyId, workLoadType)
    );
    return respData;
  }
);

export const getAgencyOtherPharmaciesData = createAsyncThunk(
  "orbit/getAgencyOtherPharmaciesData",
  async (params) => {
    const { companyId, workLoadType } = params || {};
    const respData = await httpGet(
      getAgencyOtherPharmacies(companyId, workLoadType)
    );
    return respData;
  }
);

export const getOrbitAgencyMonthlyReportData = createAsyncThunk(
  "orbit/getOrbitAgencyMonthlyReportData",
  async (params) => {
    const { companyID, formattedStartDate, formattedEndDate } = params || {};
    const respData = await httpGet(
      getOrbitAgencyMonthlyReport(
        companyID,
        formattedStartDate,
        formattedEndDate
      )
    );
    return respData;
  }
);

export const getOrbitServicesWorkPlannerData = createAsyncThunk(
  "pharmacyService/getOrbitServicesWorkPlannerData",
  async (companyId) => {
    const urlToGet = getOrbitServicesWorkPlannerUrl(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getOrbitPharmanautsWorkPlannerData = createAsyncThunk(
  "pharmacyService/getOrbitPharmanautsWorkPlannerData",
  async (companyId) => {
    const urlToGet = getOrbitPharmanautsWorkPlannerUrl(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getOrbitWorkPlannerData = createAsyncThunk(
  "pharmacyService/getOrbitWorkPlannerData",
  async (companyId) => {
    const urlToGet = getOrbitWorkPlannerUrl(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getOrbitAgnecyUserByEmailData = createAsyncThunk(
  "orbit/getOrbitAgnecyUserByEmailData",
  async (params) => {
    const { email } = params || {};
    const respData = await httpGet(getUserByEmail(email));
    return respData;
  }
);

export const addExclusivePharmacyData = createAsyncThunk(
  "orbit/addExclusivePharmacyData",
  async (params) => {
    const { companyID, pharmacyIds } = params || {};
    const response = await httpPost(
      addExclusivePharmacy(companyID),
       pharmacyIds 
    );
    return response; // Return the response data
  }
);

export const getOrbitAgencyServicesByCompanyIdData = createAsyncThunk(
  "orbit/getOrbitAgencyServicesByCompanyIdData",
  async (params) => {
    const { companyID } = params || {};
    const respData = await httpGet(
      getOrbitAgencyServicesByCompanyId(companyID)
    );
    return respData;
  }
);

export const SaveOrbitPharmanautServicesData = createAsyncThunk(
  "orbit/SaveOrbitPharmanautServicesData",
  async (data, thunkApi) => {
    const url = SaveOrbitPharmanautServices();
    const respData = await httpPut(url, data);
    return respData;
  }
);

export const orbitSessionSlice = createSlice({
  name: "orbit",
  initialState,
  reducers: {
    setIsOrbitLoading: (state, action) => {
      state.isOrbitLoading = action?.payload || false;
    },
    setOrbitData: (state, action) => {
      state.startTimeObj = action?.payload || null;
    },
    setOrbitProfileData: (state, action) => {
      state.orbitProfile = action?.payload || null;
    },
    setProfData: (state, action) => {
      state.profDetails = action?.payload || null;
    },
    setPayData: (state, action) => {
      state.payDetails = action?.payload || null;
    },
    setAccredationsData: (state, action) => {
      state.accredationsDetails = action?.payload || null;
    },
    setCompDocData: (state, action) => {
      state.compDocDetails = action?.payload || null;
    },
    setAvbData: (state, action) => {
      state.avbDetails = action?.payload || null;
    },
    setIsStart: (state, action) => {
      state.isStart = action?.payload || false;
    },
    setIsStopTimer: (state, action) => {
      state.isStopTimer = action?.payload || false;
    },
    setIsOrbitSessionMinutes: (state, action) => {
      state.orbitSessionMinutes = action?.payload || "0";
    },
    setIsOrbitSessionSeconds: (state, action) => {
      state.orbitSessionSeconds = action?.payload || "0";
    },
    setTimer: (state, action) => {
      state.timer = action.payload || 0;
    },
    setOrbitAnimation: (state, action) => {
      state.orbitAnimation = action?.payload || false;
    },
    setIsAnimationPlaying: (state, action) => {
      state.isAnimationPlaying = action?.payload || false;
    },
    setOpenSuccessDialog: (state, action) => {
      state.openSuccessDialog = action?.payload || false;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action?.payload || 0;
    },
    setTotalPrice: (state, action) => {
      state.totalPrice = action?.payload || 0;
    },
    setIsActive: (state, action) => {
      state.isActive = action?.payload || false;
    },
    setIsMonthlyReportLoadingData: (state, action) => {
      state.isMonthlyReportLoading = action?.payload || false;
    },
    setIsOrbitEarningLoadingData: (state, action) => {
      state.isOrbitEarningLoading = action?.payload || false;
    },
    setIsUserSessionLoadingData: (state, action) => {
      state.isUserSessionLoading = action?.payload || false;
    },
    setIsPotentialEarningLoadingData: (state, action) => {
      state.isPotentialEarningLoading = action?.payload || false;
    },
    setIsFollowupLoadingData: (state, action) => {
      state.isFollowupLoading = action?.payload || false;
    },
    setIsOtherDetailsLoadingData: (state, action) => {
      state.isOtherDetailsLoading = action?.payload || false;
    },
    setIsActiveServicesLoadingData: (state, action) => {
      state.isActiveServicesLoading = action?.payload || false;
    },
    setshowTestModeDialogData: (state, action) => {
      state.showTestModeDialog = action?.payload || false;
    },
    setisTestModeData: (state, action) => {
      state.isTestMode = action?.payload;
    },
    setSidebarVisibleData: (state, action) => {
      state.sidebarVisible = action?.payload || false;
    },
    setSelectedRow: (state, action) => {
      state.selectedRowData = action?.payload || false;
    },
    toggleService: (state, action) => {
      const service = action?.payload;
      const serviceIndex = state.orbitPharmanautUser.services.findIndex(
        (s) => s._id === service._id
      );

      if (serviceIndex === -1) {
        state.orbitPharmanautUser.services.push(service);
      } else {
        state.orbitPharmanautUser.services.splice(serviceIndex, 1);
      }
    },
    resetOrbitState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrbitSessionData.pending, (state) => {
        state.isOrbitLoading = true;
        state.startTimeObj = null;
      })
      .addCase(addOrbitSessionData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.currentSessionId = appDataFromResponse?._id;
            localStorage.setItem("currentSessionId", appDataFromResponse?._id);
            state.startTimeObj = appDataFromResponse || null;
          } catch (error) {}
        }
      })
      .addCase(addOrbitSessionData.rejected, (state, action) => {
        state.isOrbitLoading = false;
        state.startTimeObj = null;
      })
      .addCase(updateOrbitSessionData.pending, (state) => {
        state.isOrbitLoading = true;
        state.startTimeObj = null;
      })
      .addCase(updateOrbitSessionData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        state.startTimeObj = action?.payload;
      })
      .addCase(updateOrbitSessionData.rejected, (state, action) => {
        logError(action?.error);
        state.isOrbitLoading = false;
        state.startTimeObj = null;
      })
      .addCase(getOrbitProfileData.pending, (state) => {
        state.isOrbitLoading = true;
        state.orbitProfile = [];
      })
      .addCase(getOrbitProfileData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitProfile = action?.payload;
        const {
          professionalDetails,
          paymentDetails,
          availability,
          complianceDocuments,
          accreditations,
        } = action?.payload || {};
        state.profDetails =
          professionalDetails != null
            ? professionalDetails
            : {
                gphc: "",
                psniNumber: "",
                psiNumber: "",
                isPreRegistered: false,
                isDBSUpdate: false,
                educationalOrganization: "",
                yearOfGraduation: "",
                nationality: "",
                isCriminalRecord: false,
                isInvestigation: false,
                yearOfExperience: "",
              };

        state.payDetails =
          paymentDetails != null
            ? paymentDetails
            : {
                accountNumber: "",
                sortCode: "",
                accountHolderName: "",
                isLimitedCompany: false,
                isVatRegistered: false,
              };

        state.accredationsDetails =
          accreditations != null
            ? accreditations
            : {
                documentType: "",
                documentDetails: {
                  document: "",
                  issueDate: null,
                  expiryDate: null,
                  requiredBy: "",
                  notes: "",
                  isUploaded: false,
                  fileType: "",
                },
              };
        state.compDocDetails =
          complianceDocuments != null
            ? complianceDocuments
            : {
                enhancedDB: {
                  document: "",
                  issueDate: null,
                  expiryDate: null,
                  requiredBy: "",
                  notes: "",
                  isUploaded: false,
                  fileType: "",
                },
                indemnityEnsurance: {
                  document: "",
                  issueDate: null,
                  expiryDate: null,
                  requiredBy: "",
                  notes: "",
                  isUploaded: false,
                  fileType: "",
                },
                photographicId: {
                  document: "",
                  issueDate: null,
                  expiryDate: null,
                  requiredBy: "",
                  notes: "",
                  isUploaded: false,
                  fileType: "",
                },
              };
        state.avbDetails = availability != null ? availability : [];
      })
      .addCase(getOrbitProfileData.rejected, (state, action) => {
        logError(action?.error);
        state.isOrbitLoading = false;
        state.orbitProfile = [];
      })
      .addCase(saveOrbitProfileData.pending, (state) => {
        state.isOrbitLoading = true;
      })
      .addCase(saveOrbitProfileData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.orbitProfile = appDataFromResponse || null;
          } catch (error) {}
        }
      })
      .addCase(saveOrbitProfileData.rejected, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitProfile = [];
      })
      .addCase(getOrbitAuditBySessionIdData.pending, (state) => {
        state.isOrbitLoading = true;
        state.orbitAudit = [];
      })
      .addCase(getOrbitAuditBySessionIdData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitAudit = action?.payload;
      })
      .addCase(getOrbitAuditBySessionIdData.rejected, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitAudit = [];
      })
      .addCase(getOrbitDashboardData.pending, (state) => {
        state.isOrbitLoading = true;
        state.orbitDashboardData = [];
      })
      .addCase(getOrbitDashboardData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitDashboardData = action?.payload;
      })
      .addCase(getOrbitDashboardData.rejected, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitDashboardData = [];
      })
      .addCase(getOrbitMonthlyReportData.pending, (state) => {
        state.isMonthlyReportLoading = true;
        state.monthlyReport = null;
      })
      .addCase(getOrbitMonthlyReportData.fulfilled, (state, action) => {
        state.isMonthlyReportLoading = false;
        state.monthlyReport = action?.payload;
      })
      .addCase(getOrbitMonthlyReportData.rejected, (state, action) => {
        state.isMonthlyReportLoading = false;
        state.monthlyReport = null;
      })
      .addCase(getOrbitEarningsData.pending, (state) => {
        state.isOrbitEarningLoading = true;
        state.orbitEarnings = [];
      })
      .addCase(getOrbitEarningsData.fulfilled, (state, action) => {
        state.isOrbitEarningLoading = false;
        state.orbitEarnings = action?.payload;
      })
      .addCase(getOrbitEarningsData.rejected, (state, action) => {
        state.isOrbitEarningLoading = false;
        state.orbitEarnings = [];
      })
      .addCase(getOrbitUserSessionsData.pending, (state) => {
        state.isUserSessionLoading = true;
        state.orbitUserSessions = [];
      })
      .addCase(getOrbitUserSessionsData.fulfilled, (state, action) => {
        state.isUserSessionLoading = false;
        state.orbitUserSessions = action?.payload;
      })
      .addCase(getOrbitUserSessionsData.rejected, (state, action) => {
        state.isUserSessionLoading = false;
        state.orbitUserSessions = [];
      })
      .addCase(getOrbitParkedServicesData.pending, (state) => {
        state.isParkedServicesLoading = true;
        state.parkedServices = [];
      })
      .addCase(getOrbitParkedServicesData.fulfilled, (state, action) => {
        state.isParkedServicesLoading = false;
        state.parkedServices = action?.payload;
      })
      .addCase(getOrbitParkedServicesData.rejected, (state, action) => {
        state.isParkedServicesLoading = false;
        state.parkedServices = [];
      })
      .addCase(getOrbitActiveServicesData.pending, (state) => {
        state.isActiveServicesLoading = true;
        state.activeServices = [];
      })
      .addCase(getOrbitActiveServicesData.fulfilled, (state, action) => {
        state.isActiveServicesLoading = false;
        state.activeServices = action?.payload;
      })
      .addCase(getOrbitActiveServicesData.rejected, (state, action) => {
        state.isActiveServicesLoading = false;
        state.activeServices = [];
      })
      .addCase(changeOrbitServiceStatusData.pending, (state) => {
        state.isParkedServicesLoading = true;
      })
      .addCase(changeOrbitServiceStatusData.fulfilled, (state, action) => {
        state.isParkedServicesLoading = false;
      })
      .addCase(changeOrbitServiceStatusData.rejected, (state, action) => {
        state.isParkedServicesLoading = false;
      })
      .addCase(getOrbitFollowUpServicesData.pending, (state) => {
        state.isFollowupLoading = true;
        state.followupServices = [];
      })
      .addCase(getOrbitFollowUpServicesData.fulfilled, (state, action) => {
        state.isFollowupLoading = false;
        state.followupServices = action?.payload;
      })
      .addCase(getOrbitFollowUpServicesData.rejected, (state, action) => {
        state.isFollowupLoading = false;
        state.followupServices = [];
      })
      .addCase(getOrbitPotentialEarningsData.pending, (state) => {
        state.isPotentialEarningLoading = true;
        state.potentialEarnings = null;
      })
      .addCase(getOrbitPotentialEarningsData.fulfilled, (state, action) => {
        state.isPotentialEarningLoading = false;
        state.potentialEarnings = action?.payload;
      })
      .addCase(getOrbitPotentialEarningsData.rejected, (state, action) => {
        state.isPotentialEarningLoading = false;
        state.potentialEarnings = null;
      })
      .addCase(getOrbitOtherDetailsData.pending, (state) => {
        state.isOtherDetailsLoading = true;
        state.otherDetails = null;
      })
      .addCase(getOrbitOtherDetailsData.fulfilled, (state, action) => {
        state.isOtherDetailsLoading = false;
        state.otherDetails = action?.payload;
      })
      .addCase(getOrbitOtherDetailsData.rejected, (state, action) => {
        state.isOtherDetailsLoading = false;
        state.otherDetails = null;
      })
      .addCase(addTestModeServiceData.pending, (state) => {
        state.isActiveServicesLoading = true;
      })
      .addCase(addTestModeServiceData.fulfilled, (state, action) => {
        state.isActiveServicesLoading = false;
      })
      .addCase(addTestModeServiceData.rejected, (state, action) => {
        state.isActiveServicesLoading = false;
      })
      .addCase(getPaymentDetailsData.pending, (state) => {
        state.isActiveServicesLoading = true;
      })
      .addCase(getPaymentDetailsData.fulfilled, (state, action) => {
        state.isActiveServicesLoading = false;
        state.bankPaymentDetails = action?.payload;
      })
      .addCase(getPaymentDetailsData.rejected, (state, action) => {
        state.isActiveServicesLoading = false;
      })
      .addCase(getOrbitTeamByCompanyIdData.pending, (state, action) => {
        state.isOrbitLoading = true;
      })
      .addCase(getOrbitTeamByCompanyIdData.fulfilled, (state, action) => {
        state.isOrbitLoading = false;
        state.orbitTeam = action?.payload;
      })
      .addCase(getOrbitTeamByCompanyIdData.rejected, (state, action) => {
        state.isOrbitLoading = false;
      })
      .addCase(getOrbitAgencyUsersData.pending, (state, action) => {
        state.orbitAgencyLoader = true;
      })
      .addCase(getOrbitAgencyUsersData.fulfilled, (state, action) => {
        state.orbitAgencyLoader = false;
        state.orbitAgencyUsers = action?.payload;
      })
      .addCase(getOrbitAgencyUsersData.rejected, (state, action) => {
        state.orbitAgencyLoader = false;
      })
      .addCase(getOrbitAgencyDetailsData.pending, (state, action) => {
        state.orbitAgencyDetailsLoader = true;
      })
      .addCase(getOrbitAgencyDetailsData.fulfilled, (state, action) => {
        state.orbitAgencyDetailsLoader = false;
        state.orbitAgencyDetails = action?.payload;
      })
      .addCase(getOrbitAgencyDetailsData.rejected, (state, action) => {
        state.orbitAgencyDetailsLoader = false;
      })
      .addCase(getOrbitAgencyTimeAndCompletedData.pending, (state, action) => {
        state.orbitAgencyOtherDetailsLoader = true;
      })
      .addCase(
        getOrbitAgencyTimeAndCompletedData.fulfilled,
        (state, action) => {
          state.orbitAgencyOtherDetailsLoader = false;
          state.orbitAgencyOtherDetails = action?.payload;
        }
      )
      .addCase(getOrbitAgencyTimeAndCompletedData.rejected, (state, action) => {
        state.orbitAgencyOtherDetailsLoader = false;
      })
      .addCase(getAgencyPharmaciesData.pending, (state, action) => {
        state.orbitAgencyPharmaciesLoader = true;
      })
      .addCase(getAgencyPharmaciesData.fulfilled, (state, action) => {
        state.orbitAgencyPharmaciesLoader = false;
        state.orbitAgencyPharmacies = action?.payload;
      })
      .addCase(getAgencyPharmaciesData.rejected, (state, action) => {
        state.orbitAgencyPharmaciesLoader = false;
      })
      .addCase(getAgencyOtherPharmaciesData.pending, (state, action) => {
        state.orbitAgencyOtherPharmaciesLoader = true;
      })
      .addCase(getAgencyOtherPharmaciesData.fulfilled, (state, action) => {
        state.orbitAgencyOtherPharmaciesLoader = false;
        state.orbitAgencyOtherPharmacies = action?.payload;
      })
      .addCase(getAgencyOtherPharmaciesData.rejected, (state, action) => {
        state.orbitAgencyOtherPharmaciesLoader = false;
      })
      .addCase(getOrbitAgencyMonthlyReportData.pending, (state, action) => {
        state.isAgencyMonthlyReportLoading = true;
      })
      .addCase(getOrbitAgencyMonthlyReportData.fulfilled, (state, action) => {
        state.orbitAgencyMonthlyReport = action?.payload;
        state.isAgencyMonthlyReportLoading = false;
      })
      .addCase(getOrbitAgencyMonthlyReportData.rejected, (state, action) => {
        state.isAgencyMonthlyReportLoading = false;
      })
      .addCase(getOrbitServicesWorkPlannerData.pending, (state, action) => {
        state.orbitServicesWorkPlannerLoader = true;
      })
      .addCase(getOrbitServicesWorkPlannerData.fulfilled, (state, action) => {
        state.orbitServicesWorkPlannerLoader = false;
        state.orbitServicesWorkPlanner = action?.payload;
      })
      .addCase(getOrbitServicesWorkPlannerData.rejected, (state, action) => {
        state.orbitServicesWorkPlannerLoader = false;
      })
      .addCase(getOrbitPharmanautsWorkPlannerData.pending, (state, action) => {
        state.orbitPharmanautsWorkPlannerLoader = true;
      })
      .addCase(
        getOrbitPharmanautsWorkPlannerData.fulfilled,
        (state, action) => {
          state.orbitPharmanautsWorkPlannerLoader = false;
          state.orbitPharmanautsWorkPlanner = action?.payload;
        }
      )
      .addCase(getOrbitPharmanautsWorkPlannerData.rejected, (state, action) => {
        state.orbitPharmanautsWorkPlannerLoader = false;
      })
      .addCase(getOrbitWorkPlannerData.pending, (state, action) => {
        state.orbitWorkPlannerLoader = true;
      })
      .addCase(getOrbitWorkPlannerData.fulfilled, (state, action) => {
        state.orbitWorkPlannerLoader = false;
        state.orbitWorkPlanner = action?.payload;
      })
      .addCase(getOrbitWorkPlannerData.rejected, (state, action) => {
        state.orbitWorkPlannerLoader = false;
      })
      .addCase(getOrbitAgnecyUserByEmailData.pending, (state, action) => {
        state.pharmanautUserLoader = true;
      })
      .addCase(getOrbitAgnecyUserByEmailData.fulfilled, (state, action) => {
        state.pharmanautUserLoader = false;
        state.orbitPharmanautUser = action?.payload;
      })
      .addCase(getOrbitAgnecyUserByEmailData.rejected, (state, action) => {
        state.pharmanautUserLoader = false;
      })
      .addCase(getOthersAvailableData.pending, (state) => {
        state.othersAvailableLoader = true;
      })
      .addCase(getOthersAvailableData.fulfilled, (state, action) => {
        state.othersAvailableLoader = false;
        state.othersAvailable = action?.payload;
      })
      .addCase(getOthersAvailableData.rejected, (state, action) => {
        state.othersAvailableLoader = false;
      })
      .addCase(addExclusivePharmacyData.pending, (state) => {
        state.othersAvailableLoader = true;
        state.orbitAgencyPharmaciesLoader = true;
        state.isActiveServicesLoading = true;
      })
      .addCase(addExclusivePharmacyData.fulfilled, (state, action) => {
        state.othersAvailableLoader = false;
        state.orbitAgencyPharmaciesLoader = false;
        state.isActiveServicesLoading = false;
      })
      .addCase(addExclusivePharmacyData.rejected, (state, action) => {
        state.othersAvailableLoader = false;
        state.orbitAgencyPharmaciesLoader = false;
        state.isActiveServicesLoading = false;
      })
      .addCase(
        getOrbitAgencyServicesByCompanyIdData.pending,
        (state, action) => {
          state.pharmanautServiceLoader = true;
        }
      )
      .addCase(
        getOrbitAgencyServicesByCompanyIdData.fulfilled,
        (state, action) => {
          state.pharmanautServiceLoader = false;
          state.pharmanautServices = action?.payload;
        }
      )
      .addCase(
        getOrbitAgencyServicesByCompanyIdData.rejected,
        (state, action) => {
          state.pharmanautServiceLoader = false;
        }
      )
      .addCase(SaveOrbitPharmanautServicesData.pending, (state, action) => {
        state.pharmanautUserLoader = true;
      })
      .addCase(SaveOrbitPharmanautServicesData.fulfilled, (state, action) => {
        state.pharmanautUserLoader = false;
      })
      .addCase(SaveOrbitPharmanautServicesData.rejected, (state, action) => {
        state.pharmanautUserLoader = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsMonthlyReportLoadingData,
  setIsOrbitEarningLoadingData,
  setIsUserSessionLoadingData,
  setIsPotentialEarningLoadingData,
  setIsFollowupLoadingData,
  setIsOtherDetailsLoadingData,
  setIsActiveServicesLoadingData,
  setIsOrbitLoading,
  setOrbitData,
  resetOrbitState,
  setProfData,
  setPayData,
  setAccredationsData,
  setCompDocData,
  setAvbData,
  setIsStart,
  setIsStopTimer,
  setOrbitProfileData,
  setIsOrbitSessionMinutes,
  setIsOrbitSessionSeconds,
  setTimer,
  setOrbitAnimation,
  setIsAnimationPlaying,
  setOpenSuccessDialog,
  setTotalCount,
  setTotalPrice,
  setIsActive,
  setshowTestModeDialogData,
  setisTestModeData,
  setSidebarVisibleData,
  setSelectedRow,
  toggleService,
} = orbitSessionSlice.actions;

export default orbitSessionSlice.reducer;
