import React, { useCallback, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./../../../../../../../../assets/css/Dashboard/Claim.scss";
import CustomCheckBox from "./CustomCheckBox";
import { ReactComponent as CPCSIcon } from "../../../../../ServicesIcons/nhs.svg";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { showError } from "../../../../../../../../components/ToastedMessage/ToastedMessage";
import {
  getAnalyticsServicesCompletedClaimed,
  getAnalyticsServicesCompletedToBeClaimed,
  getAnalyticsServicesDeclined,
  getServiceAnalytics,
  updateClaimStatus,
} from "../../../../../../../../dispatchers/pharmacyServiceDispatchers";
import useTitanApp from "../../../../../../../../hooks/useTitanApp";
import OrbitCheckFilter from "./OrbitCheckFilter";
import CustomOrbitCheckFilter from "./CustomOrbitCheckFilter";

export default function TobeClaimed(props) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const [rowData, setRowData] = useState([]);
  const gridApiRef = useRef();
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const gridStyle = { height: "100%", width: "100%" };

  const PatientColumns = [
    {
      field: "selected",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      onCheckboxValueChanged: onCheckboxValueChanged,
      width: 50,
      selected: false,
    },
    {
      headerName: "Patient name",
      field: "name",
      sortable: true,
      editable: false,
      selected: false,
    },
    {
      headerName: "Service Name",
      field: "serviceName",
      sortable: true,
      editable: false,
      selected: false,
    },
    {
      headerName: "Orbit",
      field: "sendToOrbit",
      filter: OrbitCheckFilter,
      floatingFilter: true,
      floatingFilterComponent: CustomOrbitCheckFilter,
      width: 130,
      cellRenderer: (params) => {
        if (params?.value) {
          return (
            <div
              className={`orbit-badge ${
                params?.data?.sendToOrbit ? "with-tag" : "without-tag"
              }`}
            >
              {params?.data?.sendToOrbit && (
                <span className="listview-due-tag">Orbit</span>
              )}
            </div>
          );
        } else {
          <></>;
        }
      },
    },
    {
      headerName: "Completed By",
      field: "completedBy",
      sortable: true,
      editable: false,
      selected: false,
    },
    {
      headerName: "Engagement Date",
      field: "engagementDate",
      sortable: true,
      editable: false,
      selected: false,
      valueFormatter: (params) => {
        if (params.value !== null) {
          const date = new Date(params.value);
          const options = { day: "2-digit", month: "2-digit", year: "numeric" };
          return date.toLocaleDateString("en-GB", options);
        } else {
          return "-";
        }
      },
    },
    {
      headerName: "Intervention Date",
      field: "interventionDate",
      sortable: true,
      editable: false,
      selected: false,
      valueFormatter: (params) => {
        if (params.value !== null) {
          const date = new Date(params.value);
          const options = { day: "2-digit", month: "2-digit", year: "numeric" };
          return date.toLocaleDateString("en-GB", options);
        } else {
          return "-";
        }
      },
    },
    {
      headerName: "FollowUp Date",
      field: "followUpDate",
      sortable: true,
      editable: false,
      selected: false,
      valueFormatter: (params) => {
        if (params.value !== null) {
          const date = new Date(params.value);
          const options = { day: "2-digit", month: "2-digit", year: "numeric" };
          return date.toLocaleDateString("en-GB", options);
        } else {
          return "-";
        }
      },
    },
  ];
  const { appState, pharmacyServiceState } = useTitanApp();
  const { currentPharmacyId } = appState || {};
  const { currentServiceId } = pharmacyServiceState || {};

  function onCheckboxValueChanged(params) {
    const selectedNodes = params?.api?.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node?.data);
    const updatedData = selectedData?.map((item) => item?.clinicalServiceID);
    setRowData(updatedData);
  }
  const gridOptions = {
    suppressMovableColumns: true,
    getRowClass: (params) => (params.node.isSelected ? "selected-row" : ""),
    onRowClicked: (event) => {
      if (props.onRowClick) {
        props.onRowClick(event.data);
      }
    },
  };
  const exportToCSV = (apiData, fileName) => {
    const modifiedData = apiData?.map(
      ({
        name,
        serviceName,
        completedBy,
        engagementDate,
        interventionDate,
        followUpDate,
      }) => ({
        "Patient Name": name,
        "Service Name": serviceName,
        "Completed By": completedBy,
        "Engagement Date": engagementDate,
        "Intervention Date": interventionDate,
        "FollowUp Date": followUpDate,
      })
    );
    const ws = XLSX.utils.json_to_sheet(modifiedData);
    const wb = {
      Sheets: { ["To be Claimed"]: { ...ws } },
      SheetNames: ["To be Claimed"],
    };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  function sentClaim() {
    updateClaimStatus(rowData).then((data) => {
      if (data) {
        getAnalyticsServicesCompletedClaimed(
          currentPharmacyId,
          currentServiceId
        );
        getAnalyticsServicesCompletedToBeClaimed(
          currentPharmacyId,
          currentServiceId
        );
        getAnalyticsServicesDeclined(currentPharmacyId, currentServiceId);
      } else {
        showError("Error sending to claim", true, false);
      }
    });
  }
  return (
    <div
      style={gridStyle}
      className={`tobeClaimed-grid ${
        props.darkMode === true ? "ag-theme-alpine-dark" : "ag-theme-alpine"
      }`}
    >
      {props?.unclaimedServices?.length !== 0 ? (
        <>
          <AgGridReact
            ref={gridApiRef}
            rowData={props.unclaimedServices}
            columnDefs={PatientColumns}
            gridOptions={gridOptions}
            onSelectionChanged={onCheckboxValueChanged}
            rowSelection="multiple"
            reactiveCustomComponents
          />
          <div className="analytics_tab_list_footer">
            {rowData?.length > 0 && (
              <button onClick={sentClaim}>
                <span>Mark as claimed</span>
                <span>
                  <CPCSIcon />
                </span>
              </button>
            )}
            <button
              onClick={(e) =>
                exportToCSV(props.unclaimedServices, "ToBeClaimedFile")
              }
            >
              <p>Export</p>
            </button>
          </div>
        </>
      ) : (
        <div>
          <h4>No Data To Show</h4>
        </div>
      )}
    </div>
  );
}
