import React from "react";
import "./../../assets/css/Dashboard/ServicesTab.scss";
import "./../../assets/css/Dashboard/ServicesSidebar.scss";
import "./../../assets/css/Dashboard/ServicesContainer.scss";
import "./../../assets/css/Dashboard/ServicesKanban.scss";
import "./../../assets/css/Dashboard/ServicesAccordion.scss";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";

export default function LoadingComponentServices() {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  return (
    <div className="services_content-bg services_kanban loading-kanban">
      <div className="loading-kanban-columns">
        <div>
          <p className="service_title">
            <Skeleton
              width={180}
              height={35}
              animation="wave"
              sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            />
          </p>
          <p className="service_title">
            <Skeleton
              width={65}
              height={20}
              animation="wave"
              sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            />
          </p>
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            height={150}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            width={400}
            height={150}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            width={400}
            height={150}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            width={400}
            height={150}
            animation="wave"
            variant="rounded"
          />
        </div>
        <div>
          <p className="service_title">
            <Skeleton
              width={150}
              height={35}
              animation="wave"
              sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            />
          </p>
          <p className="service_title">
            <Skeleton
              width={65}
              height={20}
              animation="wave"
              sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            />
          </p>
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            height={150}
            width={400}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            height={150}
            width={400}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            height={150}
            width={400}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            height={150}
            width={400}
            animation="wave"
            variant="rounded"
          />
        </div>
        <div>
          <p className="service_title">
            <Skeleton
              width={150}
              height={35}
              animation="wave"
              sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            />
          </p>
          <p className="service_title">
            <Skeleton
              width={65}
              height={20}
              animation="wave"
              sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            />
          </p>
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            height={150}
            width={400}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            height={150}
            width={400}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            height={150}
            width={400}
            animation="wave"
            variant="rounded"
          />
        </div>
        <div>
          <p className="service_title">
            <Skeleton
              width={150}
              height={35}
              animation="wave"
              sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            />
          </p>
          <p className="service_title">
            <Skeleton
              width={65}
              height={20}
              animation="wave"
              sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            />
          </p>
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            height={150}
            width={400}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            height={150}
            width={400}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            height={150}
            width={400}
            animation="wave"
            variant="rounded"
          />
        </div>
      </div>
    </div>
  );
}
