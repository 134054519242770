import React, { useState, useCallback, useEffect } from "react";
import Card from "@mui/material/Card";
import serviceBackground from "../../../../../assets/images/serviceBackground.png";
import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import ServiceDetails from "./ServiceDetails";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { ReactComponent as PositiveCheck } from "../../../../../assets/images/positiveTick.svg";
import CustomTemplates from "./CustomTemplates";
import Switch from "@mui/material/Switch";
import CustomButton from "../../../../../components/controls/CustomButton";
import { submitCustomServiceTemplate } from "../../../../../dispatchers/pharmacyServiceDispatchers";
import { showSuccess } from "../../../../../components/ToastedMessage/ToastedMessage";
import { showError } from "../../../../../components/ToastedMessage/ToastedMessage";
import CustomLabel from "../../../../../components/controls/CustomLabel";
import {
  updateTemplateControlValue,
  updateControlCheckedState,
} from "../../../../../dispatchers/pharmacyServiceDispatchers";
import TemplateSection from "./TemplateSection";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function ServiceOverview() {
  const [value, setValue] = useState(0);
  const { pharmacyServiceState, appState } = useTitanApp();
  const { darkMode, companyId } = appState || {};
  const {
    serviceDetails,
    serviceDetailTemplates,
    customTemplateModel,
    isAnyControlChecked,
  } = pharmacyServiceState;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const submitCustomTemplate = () => {
    if (!customTemplateModel) {
      console.error("Custom template model is not defined.");
      return;
    }

    const { templates } = customTemplateModel || {};

    if (!templates || templates.length === 0) {
      console.error("No templates available.");
      return;
    }

    const isAnyControlSelected = templates.some((template) =>
      template?.templateSections?.some((section) =>
        section?.userControls?.some((control) => control?.value === true)
      )
    );

    if (!isAnyControlSelected) {
      showError("Please select at least one user control in any section.");
      return;
    }

    const hasRequiredControlSelectionIssue = templates.some((template) =>
      template.templateSections?.some((section) => {
        if (section?.isRequired) {
          if (!section.userControls || section.userControls.length === 0) {
            showError(
              `Section "${section.sectionTitle}" requires at least one user control, but none are available.`
            );
            return true;
          }

          const isAnyControlTrue = section.userControls.some(
            (control) => control?.value === true
          );

          if (!isAnyControlTrue) {
            showError(
              `Please select at least one user control in the required section: "${section.sectionTitle}".`
            );
            return true;
          }
        }
        return false;
      })
    );

    if (hasRequiredControlSelectionIssue) {
      return;
    }

    const filteredModel = templates.map((template,index) => ({
      template: {
        templateName: template?.templateName || "",
        companyID: companyId,
        isDefault: false,
        templateSections:
          template.templateSections?.map((section) => ({
            groupId: section?.groupId,
            order: section?.order,
            sectionTitle: section?.sectionTitle,
            isParent: section?.isParent,
            templateSectionType: section?.templateSectionType,
            isRequired: section?.isRequired,
            userControls:
              section?.userControls
                ?.filter((control) => control?.value === true)
                .map((control) => ({
                  userControlId: control?.userControlId,
                  name: control?.name,
                  type: control?.type,
                  label: control?.label,
                  inline: control?.inline,
                  required: control?.required,
                  value: String(control?.value),
                  listItems: control?.listItems?.map((item) => ({
                    label: item?.label,
                    value: item?.value,
                    selected: item?.selected,
                  })),
                  order: control?.order,
                  parentUserControlId: control?.parentUserControlId,
                  isChildControl: control?.isChildControl,
                })) || [],
          })) || [],
      },
      stageindex: index,
    }));
    submitCustomServiceTemplate({
      filteredModel,
      serviceID: serviceDetails?.serviceID,
      companyID: companyId,
    })
      .then((response) => {})
      .catch((error) => {
        console.error("Submission failed:", error);
      });
  };
  const handleToggleChange = useCallback(
    (templateIndex, sectionIndex, controlIndex) => (event) => {
      const value = event.target.checked;
      updateTemplateControlValue(
        templateIndex,
        sectionIndex,
        controlIndex,
        value
      );
    },
    []
  );

  useEffect(() => {
    const areRequiredSectionsValid = customTemplateModel?.templates?.every(
      (template) =>
        template.templateSections.every((section) =>
          section.isRequired
            ? section.userControls.some((control) => control.value)
            : true
        )
    );
    updateControlCheckedState(areRequiredSectionsValid);
  }, [customTemplateModel]);

  const tabs = [
    { label: "Details", component: <ServiceDetails /> }, // Replace with actual content
    ...(Array.isArray(customTemplateModel?.templates)
      ? customTemplateModel.templates?.map((template, templateIndex) => ({
          label: template.templateName,
          component: (
            <div key={templateIndex} className="cus-template-section">
              <p className="cus-template-name">{template.templateName}</p>
              {template.templateSections &&
                template.templateSections.map((section, sectionIndex) => (
                  <TemplateSection
                    key={sectionIndex}
                    section={section}
                    sectionIndex={sectionIndex}
                    templateIndex={templateIndex}
                    onToggleChange={handleToggleChange}
                  />
                ))}
            </div>
          ),
          props: a11yProps(templateIndex + 1), // Adjust index for accessibility
        }))
      : []),
  ];
  const profileImageUrl =
    "https://img.freepik.com/free-icon/man_318-157507.jpg";

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return <ServiceDetails />;
      case 1:
        return (
          <>
            <CustomTemplates />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="mainDetailcont">
        <div>
          <Card
            className="serviceDetailCard"
            sx={{
              minWidth: 438,
              borderRadius: 3,
              backgroundImage: `url(${serviceBackground})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="cardDetailHeading">
              {serviceDetails?.serviceName}
            </div>
          </Card>
        </div>
        <div className="lowerIconBar">
          <div className="rounded-image">
            <img src={profileImageUrl} alt="Profile Image" />
          </div>
          <div className="details">
            <p className="createdBy">Created by</p>
            <p className="createdByName">Titan PMR</p>
          </div>
        </div>
        <div className="serviceDetailTabs">
          <div className="tabs_box services_container-tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "black",
                },
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={<span className={`tabs_text`}>{tab.label}</span>}
                  {...(tab.props || {})}
                />
              ))}
              {/* <Tab
                iconPosition="start"
                label={<span className={`tabs_text`}>Details</span>}
              />
              <Tab
                iconPosition="start"
                label={<span className={`tabs_text`}>Template</span>}
              /> */}
            </Tabs>
          </div>
        </div>
        <div>
          {tabs.map((tab, index) => (
            <TabPanel value={value} index={index} key={index}>
              {tab.component}
            </TabPanel>
          ))}
        </div>
      </div>

      <div className="service-create-btn">
        <CustomButton
          action={submitCustomTemplate}
          isdisable={!isAnyControlChecked}
          label={"Create Clinic"}
        />
      </div>
    </>
  );
}

export default ServiceOverview;
