import React from "react";
import { Oval } from "react-loader-spinner";
import useTitanApp from "../../../../../../../hooks/useTitanApp";

const UserListChart = () => {
  const { pharmacyServiceState } = useTitanApp();
  const { serviceAnalyticsUsers } = pharmacyServiceState || {};
  const serviceUsers = serviceAnalyticsUsers;
  return (
    <div className="user_list_chart">
      <div className="user_list">
        {serviceUsers?.map((user, index) => (
          <div key={index} className="user_record">
            <div className="d-flex">
              <img
                src="https://www.allbusinesstemplates.com/thumbs/8e402f57-1b43-4878-8915-707979b5a4c0.PNG"
                className="user_image"
              />
              <div>
                <div className="user_heading title-heading">{user.name}</div>
                <div className="user_body chart_description">{user.email}</div>
              </div>
            </div>
            <div>
              <div className="user_heading title-heading">
                {user?.interventionCompleted}
              </div>
              <div className="user_body chart_description">Intervention</div>
            </div>
            <div>
              <div className="user_heading title-heading">
                {user?.followupCompleted}
              </div>
              <div className="user_body chart_description">Followup</div>
            </div>
            <div>
              <div className="user_heading title-heading">
                {user?.declinedServices}
              </div>
              <div className="user_body chart_description">Declined</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserListChart;
