import React, { useEffect, useState } from "react";
import "./../../assets/css/Orbit.css";
import { useLoaderData } from "react-router-dom";
import OrbitHeader from "./components/OrbitHeader";
import OrbitUserImage from "./components/OrbitUserImage";
import OrbitGrid from "./components/OrbitGrid/OrbitGrid";
import OrbitActionGroup from "./components/OrbitActionGroup";
import Lottie from "lottie-react";
import OrbitLightBG from "./../../assets/OrbitLightBG.json";
import OrbitDarkBGNew from "./../../assets/OrbitDarkBGNew.json";
import useTitanApp from "../../hooks/useTitanApp";
import OrbitEndDialog from "./components/OrbitGrid/OrbitEndDialog";
import CustomLoader from "../../components/controls/CustomLoader";
import { setDarkMode } from "../../dispatchers/appDispatchers";
import useTimer from "../../hooks/useTimer";
import {
  addOrbitSession,
  setCurrentOrbitAnimation,
  setCurrentTimeActive,
  setCurrentTotalPrice,
} from "../../dispatchers/orbitDispatchers";
import setAddSession from "../../Model/Orbit/setAddSession";
import OrbitTestMode from "./components/OrbitGrid/OrbitTestMode";
import AsyncRendererRouter from "../../components/AsyncRendererRouter/AsyncRendererRouter";
import { logError } from "../../services/logService";
import { showError } from "../../components/ToastedMessage/ToastedMessage";
import { accessOwner, responseErrorMessage } from "../../data/constantStrings";
import OrbitAgencyUsersGrid from "./components/OrbitAgencyUsersGrid/OrbitAgencyUsersGrid";
import OrbitAgencyGrid from "./components/OrbitAgencyOwnerGrid/OrbitAgencyGrid";
import { checkUserPermissions } from "../../utils/checkUserPermissions";
import { setSidebarVisible } from "../../dispatchers/orbitDispatchers";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { FaRegUser } from "react-icons/fa";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import PharmanautDetails from "./components/OrbitAgencyOwnerGrid/components/PharmanautDetails";
import Avatar from "react-initials-avatar";
import OrbitAgencySkeleton from "./components/OrbitGrid/components/OrbitAgencySkeleton";
export default function Orbit() {
  const { appState, orbitState, onboardingVerificationState } = useTitanApp();
  const { handleStart } = useTimer();
  const [headerTeamMembers, setHeaderTeamMembers] = useState("");
  const [isInviteMembersVisible, setInviteMembersVisible] = useState(false);
  const [teamNamesArr, setTeamNamesArr] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const showInviteMembers = () => {
    setInviteMembersVisible(true);
  };
  const closeInviteMembers = () => {
    setInviteMembersVisible(false);
  };
  const { darkMode, isOrbitUser, teams } = appState || {};
  const {
    isAnimationPlaying,
    isOrbitLoading,
    orbitDashboardData,
    orbitTeam,
    sidebarVisible,
    selectedRowData,
  } = orbitState || {};
  const { userData, isonboardingVerificationLoading } =
    onboardingVerificationState || {};
  const { isActive } = userData || false;
  const userFullName = `${userData?.firstName} ${userData?.lastName}`;
  const userEmail = userData?.emailAddress;
  const { accountType, companyID: actualCompanyID, role } = userData || {};
  const companyID =
    actualCompanyID === "00000000-0000-0000-0000-000000000000"
      ? null
      : actualCompanyID;
  const { name } = selectedRowData || {};
  const capitalizeFirstLetters = (str) => {
    return str
      ? str
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : "";
  };
  useEffect(() => {
    const storedOption = localStorage.getItem("selectedOption");
    const sessionData = localStorage.getItem("sessionData");
    if (storedOption === "orbit" || isOrbitUser === true) {
      if (sessionData === null) {
        setCurrentTimeActive(true);
        const startTimeModel = new setAddSession(userFullName, userEmail).obj;
        setCurrentTotalPrice(0);
        setCurrentOrbitAnimation(true);
        handleStart();
        addOrbitSession(startTimeModel).then((data) => {
          const sessionDataString = JSON.stringify(data);
          localStorage.setItem("sessionData", sessionDataString);
          const session = localStorage.getItem("sessionData");
          return data;
        });
      }
    }
  }, []);

  useEffect(() => {
    setDarkMode(true);
    const fetchData = async () => {
      try {
        if (isActive === false) {
          setInviteMembersVisible(true);
        } else {
          setInviteMembersVisible(false);
        }

        if (orbitTeam && orbitTeam.length > 0) {
          let index = orbitTeam.findIndex(
            (eachTeam) => eachTeam.isGlobalView === true
          );
          index = index === -1 ? 0 : index;

          const teamMembersArr = orbitTeam[index].teamMembers;

          setHeaderTeamMembers(teamMembersArr);
          setTeamNamesArr(
            orbitTeam.map(({ teamName }) => ({
              teamName,
            }))
          );

          return Promise.resolve(index);
        } else {
        }
      } catch (error) {
        logError(error);
        showError(responseErrorMessage, true);
        return Promise.reject(-1);
      }

      return Promise.resolve(-1);
    };

    // Call fetchData when teams changes
    fetchData();
  }, [orbitTeam, isActive]); // Adding teams as a dependency
  useEffect(() => {
    // Only set `isLoaded` to true once companyID changes from null to a valid ID.
    if (companyID !== null) {
      setIsLoaded(true);
    }
  }, [companyID]);
  const loaderAppData = useLoaderData();
  const toggleClose = () => {
    setSidebarVisible(false);
  };
  const list = (anchor) => (
    <div className="services_details_main_wrapper">
      <div className="drawer_wrapper">
        <div className="drawer_header">
          <div>
            <h2 className="img-heading para_color">
              <div className="pharmanaut-avatar">
                {/* Only render Avatar if name is defined */}
                {name?.name && <Avatar name={name.name} />}
              </div>
              {capitalizeFirstLetters(name?.name)}
            </h2>
          </div>
          <div>
            <IconButton onClick={toggleClose}>
              <Close />
            </IconButton>
          </div>
        </div>
        <div>
          <PharmanautDetails />
        </div>
      </div>
    </div>
  );
  return (
    <AsyncRendererRouter
      isLoading={isOrbitLoading}
      resolve={loaderAppData?.appData}
    >
      <div>
        <div className="orbit_page">
          {companyID !== null && checkUserPermissions(accessOwner) ? null : (
            <div className="orbit_bg">
              <Lottie
                animationData={darkMode ? OrbitDarkBGNew : OrbitLightBG}
              />
            </div>
          )}
          {isonboardingVerificationLoading ? (
            <OrbitAgencySkeleton />
          ) : (
            <div className="orbit_page_wrapper">
              <OrbitHeader
                headerTeamMembers={headerTeamMembers}
                handleInviteMembersClick={showInviteMembers}
              />

              <>
                <OrbitUserImage />
                <OrbitGrid />
                {/* {companyID !== null &&
                checkUserPermissions(accessOwner) ? null : (
                  <OrbitUserImage />
                )}
                {companyID === null && !isLoaded ? (
                  <OrbitGrid />
                ) : companyID !== null && checkUserPermissions(accessOwner) ? (
                  <OrbitAgencyGrid />
                ) : (
                  <OrbitAgencyUsersGrid />
                )} */}
              </>

              {isAnimationPlaying && <OrbitEndDialog />}
              <OrbitTestMode />
            </div>
          )}

          <div className="orbit_footer">{isActive && <OrbitActionGroup />}</div>
        </div>
        <div>
          <SwipeableDrawer
            anchor="right"
            open={sidebarVisible}
            onClose={toggleClose}
            onOpen={() => setSidebarVisible(true)}
            className="completed_services_drawer"
          >
            {list("right")};
          </SwipeableDrawer>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
