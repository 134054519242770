export const travelInfoData = {
  destination_summary: {
    destination: "Pakistan",
    capital: "Islamabad",
    languages: "Urdu, English",
    overview:
      "Pakistan, located in South Asia, is known for its diverse landscapes, including mountains, plateaus, and river valleys, with a predominantly arid to desert climate. Summers can be extremely hot, especially in regions like Sindh and Balochistan, while the northern areas experience cooler temperatures and heavy snowfall in winter. Travelers should be prepared for varying climatic conditions depending on their travel route.",
    general_health_advice:
      "Travelers should stay hydrated, especially in hot regions, ensure safe food consumption by eating cooked foods and peeling fruits, and employ insect protection measures such as using repellent and wearing long-sleeved clothing.",
  },
  latest_health_travel_information: {
    infectious_diseases: [
      {
        disease: "Typhoid",
        risk_level: "Moderate",
        precautions:
          "Vaccination recommended, avoid contaminated food and water.",
      },
      {
        disease: "Hepatitis A",
        risk_level: "Moderate",
        precautions: "Vaccination recommended, consume safe food and water.",
      },
      {
        disease: "Hepatitis B",
        risk_level: "Moderate",
        precautions: "Vaccination recommended, especially for extended stays.",
      },
      {
        disease: "COVID-19",
        risk_level: "Moderate",
        precautions:
          "Follow local guidelines, maintain social distancing, and wear masks in crowded places.",
      },
      {
        disease: "Dengue fever",
        risk_level: "High during monsoon season",
        precautions:
          "Use insect repellent, wear long sleeves, and ensure accommodations have screens.",
      },
      {
        disease: "Malaria",
        risk_level: "Variable, especially in rural and lower-income areas",
        precautions:
          "Take antimalarial medication and use bed nets when sleeping.",
      },
    ],
    food_water_safety: {
      concerns:
        "High risk for travelers' diarrhea, hepatitis A, and typhoid fever.",
      precautions:
        "Drink bottled or boiled water, eat at reputable establishments, and avoid raw produce.",
    },
    insect_borne_illnesses: ["Dengue", "Malaria", "Chikungunya"],
    healthcare_access: {
      quality:
        "Variable; higher quality in major cities, less reliable in rural areas.",
      precautions:
        "Consider travel insurance with medical evacuation coverage.",
    },
    climate_related_risks: {
      risks: "Heatwaves during summer, risk of flooding during monsoon season.",
      precautions:
        "Stay informed about weather forecasts and plan activities accordingly.",
    },
  },
  vaccine_recommendations: {
    most_travelers: [
      {
        vaccine: "Hepatitis A",
        description:
          "Prevents liver infection spread through contaminated food and water.",
      },
      {
        vaccine: "Typhoid",
        description:
          "Prevents a severe bacterial infection transmitted through contaminated food and water.",
      },
      {
        vaccine: "Tetanus, Diphtheria, and Pertussis (Tdap)",
        description:
          "Preemptive measure for cuts or wounds, common injuries during travel.",
      },
    ],
    additional_recommendations: [
      {
        vaccine: "Hepatitis B",
        description:
          "Recommended for extended stays or frequent travel for those potentially engaging with local healthcare or at risk of intimate contact.",
      },
      {
        vaccine: "Rabies",
        description:
          "Advised for long-term travelers, those in contact with animals, or visiting rural areas where exposure to rabid animals is possible.",
      },
      {
        vaccine: "Malaria prophylaxis",
        description:
          "Not a vaccine, but medication may be necessary for travelers visiting malaria-prone regions, such as the Punjab and Sindh provinces.",
      },
      {
        vaccine: "Cholera",
        description:
          "Recommended for those in areas with active transmission, especially regions with poor sanitation.",
        reason: "Bacterial disease causing severe diarrhea and dehydration.",
      },
    ],
  },
  other_health_risks: {
    insect_borne_diseases: {
      description:
        "Dengue and malaria are prevalent. Dengue is transmitted by mosquitoes active during the day, while malaria vectors are active from dusk till dawn.",
      prevention:
        "Use insect repellent with DEET, sleep under mosquito nets, and wear long-sleeved clothing.",
    },
    waterborne_diseases: {
      description:
        "Cholera risk is present, especially in areas with poor sanitation. Can cause severe dehydration and gastroenteritis.",
      prevention:
        "Drink purified or bottled water, practice good hygiene, and consume foods from reputable sources.",
    },
    foodborne_illnesses: {
      description:
        "Risk of traveler’s diarrhea is high due to exposure to unfamiliar bacteria.",
      prevention:
        "Avoid raw or undercooked meats and seafood, unpasteurized dairy products, and ensure foods are hot and well-cooked.",
    },
    climate_related_risks: {
      description:
        "Exposure to extreme heat and sudden weather changes poses risks such as heatstroke.",
      prevention:
        "Stay hydrated, acclimatize slowly to heat, and wear sun protection.",
    },
    additional_risks: [
      {
        risk: "Air Pollution",
        description:
          "Significant in urban areas such as Karachi and Lahore due to industrial emissions and traffic.",
        prevention:
          "Check the air quality index, use masks, and limit outdoor activities during high pollution days.",
      },
      {
        risk: "Heat Illness",
        description:
          "Common in the hot and dry regions, particularly from May to August.",
        prevention:
          "Hydrate well, wear light clothing, and stay in shaded or air-conditioned areas during peak heat.",
      },
      {
        risk: "Traffic Accidents",
        description:
          "High accident rates due to congested roads and variable driving standards.",
        prevention:
          "Exercise caution, use seat belts, avoid nighttime travel, and seek experienced local drivers.",
      },
    ],
  },
};
