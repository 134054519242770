import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost, httpPut } from "../../services/httpService";
import {
  getTemplatesUrl,
  saveTemplateUrl,
  getEmailsAndSmsTemplatesUrl,
  addEmailsAndSmsTemplatesUrl,
  updateEmailsAndSmsTemplatesUrl,
  getDefaultTemplatesUrl,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";

const initialState = {
  isConsultationLoading: false,
  isTemplatesLoading: false,
  currentTemplates: [],
  currentTemplate: null,
  emailsAndSmsTemplates: [],
  templateMessageType: 0,
  updateMessagetemplateData: null,
  defaultTemplates: null,
};

export const getTemplatesData = createAsyncThunk(
  "template/getTemplatesData",
  async (companyId, thunkAPI) => {
    const urlToGet = getTemplatesUrl(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getEmailsAndSmsTemplatesData = createAsyncThunk(
  "template/getEmailsAndSmsTemplatesData",
  async (companyId, thunkAPI) => {
    const urlToGet = getEmailsAndSmsTemplatesUrl(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const addTemplateData = createAsyncThunk(
  "template/addTemplateData",
  async (data, thunkAPI) => {
    const respData = await httpPost(saveTemplateUrl(), data);
    return respData;
  }
);

export const addEmailsAndSmsTemplatesData = createAsyncThunk(
  "template/addEmailsAndSmsTemplatesData",
  async (data, thunkAPI) => {
    const respData = await httpPost(addEmailsAndSmsTemplatesUrl(), data);
    return respData;
  }
);

export const updateEmailsAndSmsTemplatesData = createAsyncThunk(
  "template/updateEmailsAndSmsTemplatesData",
  async (data, thunkAPI) => {
    const respData = await httpPut(updateEmailsAndSmsTemplatesUrl(), data);
    return respData;
  }
);

export const getDefaultTemplatesData = createAsyncThunk(
  "template/getDefaultTemplatesData",
  async (actionType, thunkAPI) => {
    const urlToGet = getDefaultTemplatesUrl(actionType);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    setIsConsultationLoading: (state, action) => {
      state.isConsultationLoading = action?.payload || false;
    },
    setIsTemplatesLoading: (state, action) => {
      state.isTemplatesLoading = action?.payload || false;
    },
    setTemplatesData: (state, action) => {
      state.currentTemplates = action?.payload || [];
    },
    setTemplateMessageTypeData: (state, action) => {
      state.templateMessageType = action?.payload || 0;
    },
    setUpdateMessagetemplateData: (state, action) => {
      state.updateMessagetemplateData = action?.payload || null;
    },
    resetConsultationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplatesData.pending, (state) => {
        state.isConsultationLoading = true;
      })
      .addCase(getTemplatesData.fulfilled, (state, action) => {
        state.isConsultationLoading = false;
        state.currentTemplates = action?.payload || [];
      })
      .addCase(getTemplatesData.rejected, (state, action) => {
        state.isConsultationLoading = false;
        state.currentTemplates = [];
      })

      .addCase(addTemplateData.pending, (state) => {
        state.isConsultationLoading = true;
      })
      .addCase(addTemplateData.fulfilled, (state, action) => {
        state.isConsultationLoading = false;
        state.currentTemplate = action?.payload;
      })
      .addCase(addTemplateData.rejected, (state, action) => {
        logError(action?.error);
        state.isConsultationLoading = false;
        state.currentTemplate = null;
      })

      .addCase(getEmailsAndSmsTemplatesData.pending, (state) => {
        state.isTemplatesLoading = true;
      })
      .addCase(getEmailsAndSmsTemplatesData.fulfilled, (state, action) => {
        state.isTemplatesLoading = false;
        state.emailsAndSmsTemplates = action?.payload || [];
      })
      .addCase(getEmailsAndSmsTemplatesData.rejected, (state, action) => {
        state.isTemplatesLoading = false;
        state.emailsAndSmsTemplates = [];
      })

      .addCase(addEmailsAndSmsTemplatesData.pending, (state) => {
        state.isTemplatesLoading = true;
      })
      .addCase(addEmailsAndSmsTemplatesData.fulfilled, (state, action) => {
        state.isTemplatesLoading = false;
      })
      .addCase(addEmailsAndSmsTemplatesData.rejected, (state, action) => {
        state.isTemplatesLoading = false;
      })

      .addCase(updateEmailsAndSmsTemplatesData.pending, (state) => {
        state.isTemplatesLoading = true;
      })
      .addCase(updateEmailsAndSmsTemplatesData.fulfilled, (state, action) => {
        state.isTemplatesLoading = false;
      })
      .addCase(updateEmailsAndSmsTemplatesData.rejected, (state, action) => {
        state.isTemplatesLoading = false;
      })

      .addCase(getDefaultTemplatesData.pending, (state) => {
        state.isTemplatesLoading = true;
      })
      .addCase(getDefaultTemplatesData.fulfilled, (state, action) => {
        state.isTemplatesLoading = false;
        state.defaultTemplates = action?.payload || null;
      })
      .addCase(getDefaultTemplatesData.rejected, (state, action) => {
        state.isTemplatesLoading = false;
      })
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsConsultationLoading,
  setIsTemplatesLoading,
  setTemplatesData,
  resetConsultationState,
  setTemplateMessageTypeData,
  setUpdateMessagetemplateData,
} = templateSlice.actions;

export default templateSlice.reducer;
