import React, { useState, useEffect } from "react";
import NotFound from "../../components/NotFound/NotFound";
import UserSignup from "../../components/UserSignup/UserSignup";
import useTitanApp from "../../hooks/useTitanApp";

const SignUp = ({ root }) => {
  const { onboardingVerificationState } = useTitanApp();
  const { userData } = onboardingVerificationState || {};
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (submitting && userData?.emailAddress === "") {
      // Reset submission state once form submission completes
      setSubmitting(false);
    }
  }, [userData?.emailAddress]);

  const handleFormSubmit = () => {
    setSubmitting(true); // Toggle submission state when form is submitted
    // Call the actual form submission logic here
  };

  // Render blank state or CustomLoader if submitting
  if (submitting) {
    return null; // or <CustomLoader /> for a loading spinner
  }

  return userData?.emailAddress === "" ? (
    <NotFound />
  ) : (
    <UserSignup root={root} onFormSubmit={handleFormSubmit} />
  );
};

export default SignUp;
