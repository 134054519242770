import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost, httpPut } from "../../services/httpService";
import {
  getServicesUrl,
  saveServiceUrl,
  updateServiceUrl,
  getServiceTypeByIdUrl,
  getServicesByCompanyUrl,
  getDefaultServiceUrl,
  getClinicalServiceBuilderResponseUrl,
  submitClincalServiceBuilderUrl,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";
import { TfiControlSkipBackward } from "react-icons/tfi";
const initialState = {
  isTypeLoading: false,
  servicesTypeData: [],
  currentServiceType: null,
  currentServiceModel: null,
  currentClinicalServiceBuilder: null,
  currentStages: [],
  currentPharmacyIds: [],
  typeServices: [],
  clinicalServiceBuilderResponse: [],
  clinicalServiceBuilderModel: [],
  defaultClinicalServiceBuilderModel: [],
  messageTemplates: [],
  isTemplateVerified: false,
};
export const addServiceData = createAsyncThunk(
  "type/addServiceData",
  async (data, thunkAPI) => {
    const respData = await httpPost(saveServiceUrl(), data);
    return respData;
  }
);
export const getServicesData = createAsyncThunk(
  "type/getServicesData",
  async (params, thunkAPI) => {
    const respData = await httpGet(getServicesUrl());
    return respData?.services;
  }
);
export const updateServiceData = createAsyncThunk(
  "type/updateServiceData",
  async (data, thunkAPI) => {
    const respData = await httpPut(updateServiceUrl(), data);
    return respData;
  }
);
export const getServiceTypeByIdData = createAsyncThunk(
  "type/getServiceTypeByIdData",
  async (serviceTypeId, thunkAPI) => {
    const urlToGet = getServiceTypeByIdUrl(serviceTypeId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getServicesByCompanyData = createAsyncThunk(
  "type/getServicesByCompanyData",
  async (params, thunkAPI) => {
    const urlToGet = getDefaultServiceUrl();
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getClinicalServiceBuilderResponseData = createAsyncThunk(
  "type/getClinicalServiceBuilderResponseData",
  async (params) => {
    const { companyId, serviceId } = params;
    const urlToGet = getClinicalServiceBuilderResponseUrl(serviceId, companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const updateClinicalServiceBuilderData = createAsyncThunk(
  "type/updateClinicalServiceBuilderData",
  async (data, thunkApi) => {
    const url = submitClincalServiceBuilderUrl();
    const respData = await httpPost(url, data);
    return respData;
  }
);
const typeSlice = createSlice({
  name: "type",
  initialState,
  reducers: {
    setIsTypeLoading: (state, action) => {
      state.isTypeLoading = action?.payload || false;
    },
    setCurrentTypeData: (state, action) => {
      state.currentServiceType = action?.payload || null;
      state.currentServiceModel =
        state.currentServiceType?.serviceModel || null;
      state.currentClinicalServiceBuilder =
        state.currentServiceType?.clinicalServiceBuilder || null;
      state.currentStages =
        state.currentServiceType?.clinicalServiceBuilder?.stages || [];
      state.currentPharmacyIds = state.currentServiceType?.pharmacyIds || [];
    },
    setCurrentServiceModelData: (state, action) => {
      state.currentServiceModel = action?.payload || null;
      state.currentServiceType.serviceModel = state.currentServiceModel || null;
    },
    setCurrentClinicalServiceBuilderData: (state, action) => {
      state.currentClinicalServiceBuilder = action?.payload || null;
      state.currentServiceType.clinicalServiceBuilder =
        state.currentClinicalServiceBuilder || null;
    },
    setCurrentStagesData: (state, action) => {
      state.currentStages = action?.payload || [];
      state.currentServiceType.clinicalServiceBuilder.stages =
        state.currentStages || [];
    },
    setCurrentPharmacyIdsData: (state, action) => {
      state.currentPharmacyIds = action?.payload || [];
    },
    setProductDetail: (state, action) => {
      const { name, price, isPublicCalendar } = action?.payload;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.product.detail.name =
        name;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.product.detail.price =
        price;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.product.detail.isPublicCalendar =
        isPublicCalendar;
    },
    setAppointmentOptions: (state, action) => {
      const { colour, duration, isEnablePrePayment } = action?.payload;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.product.appointmentOptions.colour =
        colour;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.product.appointmentOptions.duration =
        duration;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.product.appointmentOptions.isEnablePrePayment =
        isEnablePrePayment;
    },
    setConfirmationCancellation: (state, action) => {
      const {
        emailConfirmation,
        emailCancellation,
        smsConfirmation,
        smsCancellation,
      } = action.payload;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.product.confirmationCancellation.emailConfirmation =
        emailConfirmation;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.product.confirmationCancellation.emailCancellation =
        emailCancellation;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.product.confirmationCancellation.smsConfirmation =
        smsConfirmation;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.product.confirmationCancellation.smsCancellation =
        smsCancellation;
    },
    setReminders: (state, action) => {
      const { emailReminder, smsReminder } = action.payload;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.product.reminders.smsReminder =
      smsReminder;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.product.reminders.emailReminder =
        emailReminder;
    },
    setShareandEmbed: (state, action) => {
      const { bookingUrl, iFrameCode } = action.payload;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.onlineBookings.shareEmbed.bookingUrl =
        bookingUrl;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.onlineBookings.shareEmbed.iFrameCode =
        iFrameCode;
    },
    setGeneral: (state, action) => {
      const { bookingsFrequency, cancellationsNotice, confirmationText } =
        action.payload;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.onlineBookings.general.bookingsFrequency =
        bookingsFrequency;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.onlineBookings.general.cancellationsNotice =
        cancellationsNotice;
      state.clinicalServiceBuilderModel.clinicalServiceBuilder.onlineBookings.general.confirmationText =
        confirmationText;
    },
    setServiceTemplateControlValue: (state, action) => {
      const { templateIndex, sectionIndex, controlIndex, value, control } =
        action?.payload;
      const existingControlIndex = state.clinicalServiceBuilderModel.templates[
        templateIndex
      ].templateSections[sectionIndex].userControls.findIndex(
        (ctrl) => ctrl.name === control.name
      );
      if (existingControlIndex === -1) {
        const newControl = { ...control, value: String(value) };
        state.clinicalServiceBuilderModel.templates[
          templateIndex
        ].templateSections[sectionIndex].userControls.push(newControl);
      } else {
        state.clinicalServiceBuilderModel.templates[
          templateIndex
        ].templateSections[sectionIndex].userControls[
          existingControlIndex
        ].value = String(value);
      }
    },
    setCheckServiceTemplate(state, action) {
      state.isTemplateVerified = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServicesData.pending, (state) => {
        state.isTypeLoading = true;
        state.servicesTypeData = [];
        state.currentServiceType = null;
        state.currentServiceModel = null;
        state.currentClinicalServiceBuilder = null;
        state.currentStages = null;
        state.currentPharmacyIds = [];
      })
      .addCase(getServicesData.fulfilled, (state, action) => {
        state.isTypeLoading = false;
        state.servicesTypeData = action?.payload;
      })
      .addCase(getServicesData.rejected, (state, action) => {
        logError(action?.error);
        state.isTypeLoading = false;
        state.servicesTypeData = [];
        state.currentServiceType = null;
        state.currentServiceModel = null;
        state.currentClinicalServiceBuilder = null;
        state.currentStages = null;
        state.currentPharmacyIds = [];
      })
      .addCase(addServiceData.pending, (state, action) => {
        state.isTypeLoading = true;
      })
      .addCase(addServiceData.fulfilled, (state, action) => {
        state.isTypeLoading = false;
        state.currentServiceType = action?.payload;
        state.currentServiceModel =
          state.currentServiceType?.serviceModel || null;
        state.currentClinicalServiceBuilder =
          state.currentServiceType?.clinicalServiceBuilder || null;
        state.currentStages = state.currentClinicalServiceBuilder?.stages || [];
        state.currentPharmacyIds = state.currentServiceType?.pharmacyIds || [];
      })
      .addCase(addServiceData.rejected, (state, action) => {
        logError(action?.error);
        state.isTypeLoading = false;
      })
      .addCase(updateServiceData.pending, (state) => {
        state.isTypeLoading = true;
      })
      .addCase(updateServiceData.fulfilled, (state, action) => {
        state.isTypeLoading = false;
        state.currentServiceType = action?.payload;
        state.currentServiceModel =
          state.currentServiceType?.serviceModel || null;
        state.currentClinicalServiceBuilder =
          state.currentServiceType?.clinicalServiceBuilder || null;
        state.currentStages = state.currentClinicalServiceBuilder?.stages || [];
        state.currentPharmacyIds = state.currentServiceType?.pharmacyIds || [];
      })
      .addCase(updateServiceData.rejected, (state, action) => {
        logError(action?.error);
        state.isTypeLoading = false;
      })
      .addCase(getServiceTypeByIdData.pending, (state) => {
        state.isTypeLoading = true;
      })
      .addCase(getServiceTypeByIdData.fulfilled, (state, action) => {
        state.isTypeLoading = false;
        state.currentServiceType = action?.payload || null;
        state.currentServiceModel =
          state.currentServiceType?.serviceModel || null;
        state.currentClinicalServiceBuilder =
          state.currentServiceType?.clinicalServiceBuilder || null;
        state.currentStages = state.currentClinicalServiceBuilder?.stages || [];
        state.currentPharmacyIds = state.currentServiceType?.pharmacyIds || [];
      })
      .addCase(getServiceTypeByIdData.rejected, (state, action) => {
        logError(action?.error);
        state.isTypeLoading = false;
        state.currentServiceType = null;
        state.currentServiceModel = null;
        state.currentClinicalServiceBuilder = null;
        state.currentStages = null;
        state.currentPharmacyIds = [];
      })
      .addCase(getServicesByCompanyData.pending, (state) => {
        state.isTypeLoading = true;
        state.typeServices = [];
      })
      .addCase(getServicesByCompanyData.fulfilled, (state, action) => {
        state.isTypeLoading = false;
        state.typeServices = action?.payload;
      })
      .addCase(getServicesByCompanyData.rejected, (state, action) => {
        logError(action?.error);
        state.isTypeLoading = false;
        state.typeServices = [];
      })
      .addCase(
        getClinicalServiceBuilderResponseData.pending,
        (state, action) => {
          state.isTypeLoading = true;
        }
      )
      .addCase(
        getClinicalServiceBuilderResponseData.fulfilled,
        (state, action) => {
          // Assign the response to clinicalServiceBuilderResponse
          state.clinicalServiceBuilderResponse = action?.payload;

          const clinicalServiceBuilderData =
            action.payload.clinicalServiceBuilder;

          state.clinicalServiceBuilderModel = {
            clinicalServiceBuilder: {
              _id: clinicalServiceBuilderData?._id || "",
              serviceName: clinicalServiceBuilderData?.serviceName || "",
              stages:
                clinicalServiceBuilderData?.stages?.map((stage) => ({
                  stageName: stage.stageName || "",
                  templateId: stage.templateId || "",
                  order: stage.order || 0,
                  stageType: stage.stageType || 0,
                  metaDataList: stage.metaDataList || null,
                  confirmMessage: stage.confirmMessage || "",
                  brandingOptions: {
                    mainBackgroundColor:
                      stage.brandingOptions?.mainBackgroundColor || "",
                    mainFontColor: stage.brandingOptions?.mainFontColor || "",
                    headerBackgroundColor:
                      stage.brandingOptions?.headerBackgroundColor || "",
                    headerFontColor:
                      stage.brandingOptions?.headerFontColor || "",
                  },
                })) || [],
              numberOfStages: clinicalServiceBuilderData?.numberOfStages || 0,
              serviceType: clinicalServiceBuilderData?.serviceType || 0,
              companyID: clinicalServiceBuilderData?.companyID || "",
              isDefault: false,
              serviceID: clinicalServiceBuilderData?.serviceID || "",
              serviceCode: clinicalServiceBuilderData?.serviceCode || 0,
              product: {
                detail: {
                  name: clinicalServiceBuilderData?.product?.detail?.name || "",
                  price:
                    clinicalServiceBuilderData?.product?.detail?.price || "",
                  isPublicCalendar:
                    clinicalServiceBuilderData?.product?.detail
                      ?.isPublicCalendar || false,
                },
                appointmentOptions: {
                  duration:
                    clinicalServiceBuilderData?.product?.appointmentOptions
                      ?.duration || 0,
                  colour:
                    clinicalServiceBuilderData?.product?.appointmentOptions
                      ?.colour || "",
                  isEnablePrePayment:
                    clinicalServiceBuilderData?.product?.appointmentOptions
                      ?.isEnablePrePayment || false,
                },
                confirmationCancellation: {
                  emailConfirmation:
                    clinicalServiceBuilderData?.product
                      ?.confirmationCancellation?.emailConfirmation || "",
                  emailCancellation:
                    clinicalServiceBuilderData?.product
                      ?.confirmationCancellation?.emailCancellation || "",
                  smsConfirmation:
                    clinicalServiceBuilderData?.product
                      ?.confirmationCancellation?.smsConfirmation || "",
                  smsCancellation:
                    clinicalServiceBuilderData?.product
                      ?.confirmationCancellation?.smsCancellation || "",
                },
                reminders: {
                  emailReminder:
                    clinicalServiceBuilderData?.product?.reminders
                      ?.emailReminder || "",
                  smsReminder:
                    clinicalServiceBuilderData?.product?.reminders
                      ?.smsReminder || "",
                },
              },
              onlineBookings: {
                shareEmbed: {
                  bookingUrl:
                    clinicalServiceBuilderData?.onlineBookings?.shareEmbed
                      ?.bookingUrl || "",
                  iFrameCode:
                    clinicalServiceBuilderData?.onlineBookings?.shareEmbed
                      ?.iFrameCode || "",
                },
                general: {
                  cancellationsNotice:
                    clinicalServiceBuilderData?.onlineBookings?.general
                      ?.cancellationsNotice || "",
                  bookingsFrequency:
                    clinicalServiceBuilderData?.onlineBookings?.general
                      ?.bookingsFrequency || "",
                  confirmationText:
                    clinicalServiceBuilderData?.onlineBookings?.general
                      ?.confirmationText || "",
                },
              },
            },
            templates:
              action.payload.templates?.map((template) => ({
                _id: template._id || "",
                templateName: template.templateName || "",
                templateSections:
                  template.templateSections?.map((section) => ({
                    groupId: section.groupId || "",
                    order: section.order || 0,
                    sectionTitle: section.sectionTitle || "",
                    isParent: section.isParent || false,
                    userControls:
                      section.userControls?.map((control) => ({
                        userControlId: control.userControlId || "",
                        name: control.name || "",
                        type: control.type || 0,
                        label: control.label || "",
                        inline: control.inline || false,
                        required: control.required || false,
                        value: control.value,
                        // control.value === "true"
                        //   ? true
                        //   : control.value === "false" || control.value === ""
                        //   ? false
                        //   : control.value,

                        listItems: control.listItems || null,
                        order: control.order || 0,
                        parentUserControlId:
                          control.parentUserControlId || null,
                        isChildControl: control.isChildControl || false,
                      })) || [],
                    templateSectionType: section.templateSectionType || 0,
                    isRequired: section.isRequired || false,
                  })) || [],
                companyID: template.companyID || null,
                isDefault: true,
              })) || [],
          };

          // add messgae template in state variable
          state.messageTemplates = action?.payload?.messageTemplates;

          // default clinical service builder code
          const clinicalServiceBuilderDefaultData =
            action.payload.clinicalServiceBuilderDefault;

          state.defaultClinicalServiceBuilderModel = {
            clinicalServiceBuilder: {
              _id: clinicalServiceBuilderDefaultData?._id || "",
              serviceName: clinicalServiceBuilderDefaultData?.serviceName || "",
              stages:
                clinicalServiceBuilderDefaultData?.stages?.map((stage) => ({
                  stageName: stage.stageName || "",
                  templateId: stage.templateId || "",
                  order: stage.order || 0,
                  stageType: stage.stageType || 0,
                  metaDataList: stage.metaDataList || null,
                  confirmMessage: stage.confirmMessage || "",
                  brandingOptions: {
                    mainBackgroundColor:
                      stage.brandingOptions?.mainBackgroundColor || "",
                    mainFontColor: stage.brandingOptions?.mainFontColor || "",
                    headerBackgroundColor:
                      stage.brandingOptions?.headerBackgroundColor || "",
                    headerFontColor:
                      stage.brandingOptions?.headerFontColor || "",
                  },
                })) || [],
              numberOfStages:
                clinicalServiceBuilderDefaultData?.numberOfStages || 0,
              serviceType: clinicalServiceBuilderDefaultData?.serviceType || 0,
              companyID: clinicalServiceBuilderDefaultData?.companyID || "",
              isDefault: false,
              serviceID: clinicalServiceBuilderDefaultData?.serviceID || "",
              serviceCode: clinicalServiceBuilderDefaultData?.serviceCode || 0,
              product: {
                detail: {
                  name:
                    clinicalServiceBuilderDefaultData?.product?.detail?.name ||
                    "",
                  price:
                    clinicalServiceBuilderDefaultData?.product?.detail?.price ||
                    "",
                  isPublicCalendar:
                    clinicalServiceBuilderDefaultData?.product?.detail
                      ?.isPublicCalendar || false,
                },
                appointmentOptions: {
                  duration:
                    clinicalServiceBuilderDefaultData?.product
                      ?.appointmentOptions?.duration || 0,
                  colour:
                    clinicalServiceBuilderDefaultData?.product
                      ?.appointmentOptions?.colour || "",
                  isEnablePrePayment:
                    clinicalServiceBuilderDefaultData?.product
                      ?.appointmentOptions?.isEnablePrePayment || false,
                },
                confirmationCancellation: {
                  emailConfirmation:
                    clinicalServiceBuilderDefaultData?.product
                      ?.confirmationCancellation?.emailConfirmation || "",
                  emailCancellation:
                    clinicalServiceBuilderDefaultData?.product
                      ?.confirmationCancellation?.emailCancellation || "",
                  smsConfirmation:
                    clinicalServiceBuilderDefaultData?.product
                      ?.confirmationCancellation?.smsConfirmation || "",
                  smsCancellation:
                    clinicalServiceBuilderDefaultData?.product
                      ?.confirmationCancellation?.smsCancellation || "",
                },
                reminders: {
                  emailReminder:
                    clinicalServiceBuilderDefaultData?.product?.reminders
                      ?.emailReminder || "",
                  smsReminder:
                    clinicalServiceBuilderDefaultData?.product?.reminders
                      ?.smsReminder || "",
                },
              },
              onlineBookings: {
                shareEmbed: {
                  bookingUrl:
                    clinicalServiceBuilderDefaultData?.onlineBookings
                      ?.shareEmbed?.bookingUrl || "",
                  iFrameCode:
                    clinicalServiceBuilderDefaultData?.onlineBookings
                      ?.shareEmbed?.iFrameCode || "",
                },
                general: {
                  cancellationsNotice:
                    clinicalServiceBuilderDefaultData?.onlineBookings?.general
                      ?.cancellationsNotice || "",
                  bookingsFrequency:
                    clinicalServiceBuilderDefaultData?.onlineBookings?.general
                      ?.bookingsFrequency || "",
                  confirmationText:
                    clinicalServiceBuilderDefaultData?.onlineBookings?.general
                      ?.confirmationText || "",
                },
              },
            },
            templates:
              action.payload.defaultTemplates?.map((template) => ({
                _id: template._id || "",
                templateName: template.templateName || "",
                templateSections:
                  template.templateSections?.map((section) => ({
                    groupId: section.groupId || "",
                    order: section.order || 0,
                    sectionTitle: section.sectionTitle || "",
                    isParent: section.isParent || false,
                    userControls:
                      section.userControls?.map((control) => ({
                        userControlId: control.userControlId || "",
                        name: control.name || "",
                        type: control.type || 0,
                        label: control.label || "",
                        inline: control.inline || false,
                        required: control.required || false,
                        value: control.value,
                        // control.value === "true"
                        //   ? true
                        //   : control.value === "false" || control.value === ""
                        //   ? false
                        //   : false,
                        listItems: control.listItems || null,
                        order: control.order || 0,
                        parentUserControlId:
                          control.parentUserControlId || null,
                        isChildControl: control.isChildControl || false,
                      })) || [],
                    templateSectionType: section.templateSectionType || 0,
                    isRequired: section.isRequired || false,
                  })) || [],
                companyID: template.companyID || null,
                isDefault: true,
              })) || [],
          };

          state.isTypeLoading = false;
        }
      )
      .addCase(
        getClinicalServiceBuilderResponseData.rejected,
        (state, action) => {
          state.isTypeLoading = false;
        }
      )
      .addCase(updateClinicalServiceBuilderData.pending, (state, action) => {
        state.isTypeLoading = true;
      })
      .addCase(updateClinicalServiceBuilderData.fulfilled, (state, action) => {
        state.isTypeLoading = false;
      })
      .addCase(updateClinicalServiceBuilderData.rejected, (state, action) => {
        state.isTypeLoading = false;
      });
  },
});
export const {
  setIsTypeLoading,
  setCurrentTypeData,
  setCurrentServiceModelData,
  setCurrentClinicalServiceBuilderData,
  setCurrentStagesData,
  setCurrentPharmacyIdsData,
  setProductDetail,
  setAppointmentOptions,
  setConfirmationCancellation,
  setReminders,
  setShareandEmbed,
  setGeneral,
  setServiceTemplateControlValue,
  setCheckServiceTemplate,
} = typeSlice.actions;
export default typeSlice.reducer;
