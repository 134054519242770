export const emailTemplate = "Dear [patient.first],\n\nThis is an email reminder for your consultation booked at [practice.name] [location.address] on [booking.start.date] at [booking.start.time].\n\nIf you need to cancel your appointment, please use the following link: [booking.cancel.link] \n\nKind Regards,";
export const emailPlaceholders = [
    {
        "category": "Patient",
        "placeholders": [
            { display: "First name", value: "[patient.first]" },
            { display: "Last name", value: "[patient.last]" },
            { display: "Patient email", value: "[patient.email]" },
        ]
    },
    {
        "category": "Clinician",
        "placeholders": [
            { display: "Clinician name", value: "[clinician.email]" }
        ]
    },
    {
        "category": "Practice",
        "placeholders": [
            { display: "Practice name", value: "[practice.name]" },
            { display: "Practice email", value: "[practice.email]" }
        ]
    },
    {
        "category": "Location",
        "placeholders": [
            { display: "Location name", value: "[location.name]" },
            { display: "Location address", value: "[location.address]" }
        ]
    },
    {
        "category": "Appointment",
        "placeholders": [
            { display: "Appointment type", value: "[appointment.type]" },
            { display: "Appointment reference", value: "[appointment.reference]" },
            { display: "Appointment price", value: "[appointment.price]" },
            { display: "Booking Time", value: "[booking.start.time]" },
            { display: "Booking Date", value: "[booking.start.date]" },
        ]
    }
];

