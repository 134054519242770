export const vaccineNames = [
  {
    prescribedItemId: "a152591e-4baa-4cd6-960f-35832768d474",
    drugName:
      "Absorbent cellulose dressing with fluid repellent backing 10cm x 15cm",
    productCode: null,
    cdSchedule: 0,
    availability: 0,
    isInvalid: false,
    itemCharges: 1,
    drugFamily: null,
    batchNumber: "RXV-1215",
    expiry: "01/09/2028",
    site: "Left Deltoid",
    route: "IM",
    notes: "Patient was in pain",
  },
  {
    prescribedItemId: "e223c838-f248-4449-93d7-3561d087c859",
    drugName: "Absorbent perforated dressing with adhesive border 6cm x 15cm",
    productCode: null,
    cdSchedule: 0,
    availability: 0,
    isInvalid: false,
    itemCharges: 1,
    drugFamily: null,
    batchNumber: "RXV-1215",
    expiry: "01/09/2028",
    site: "Left Deltoid",
    route: "IM",
    notes: "Patient was in pain",
  },
  {
    prescribedItemId: "b241b908-f995-46f3-8016-3529ee5b77c9",
    drugName:
      "Activa Leg Ulcer Hosiery Kit (40mmHg) medical stocking and compression liner XX large   Black/Sand",
    productCode: null,
    cdSchedule: 0,
    availability: 0,
    isInvalid: false,
    itemCharges: 1,
    drugFamily: null,
    batchNumber: "RXV-1215",
    expiry: "01/09/2028",
    site: "Left Deltoid",
    route: "IM",
    notes: "Patient was in pain",
  },
  {
    prescribedItemId: "6ad328ad-16bb-472a-acbd-354a5d8c5436",
    drugName: "Alglucosidase alfa 1g/500ml infusion bags   ",
    productCode: null,
    cdSchedule: 0,
    availability: 0,
    isInvalid: false,
    itemCharges: 1,
    drugFamily: null,
    batchNumber: "RXV-1215",
    expiry: "01/09/2028",
    site: "Left Deltoid",
    route: "IM",
    notes: "Patient was in pain",
  },
  {
    prescribedItemId: "6777d2af-1508-4cb9-9566-35581b84069a",
    drugName:
      "Altiven class 3 (34-46mmHg) thigh length open toe with waist attachment lymphoedema garment regular size II right   Soft beige",
    productCode: null,
    cdSchedule: 0,
    availability: 0,
    isInvalid: false,
    itemCharges: 1,
    drugFamily: null,
    batchNumber: "RXV-1215",
    expiry: "01/09/2028",
    site: "Left Deltoid",
    route: "IM",
    notes: "Patient was in pain",
  },
  {
    prescribedItemId: "5b9f193d-625d-4e30-a13a-3597e86b66b9",
    drugName: "Aquacel Ag dressing 15cm x 15cm square   ",
    productCode: null,
    cdSchedule: 0,
    availability: 0,
    isInvalid: false,
    itemCharges: 1,
    drugFamily: null,
    batchNumber: "RXV-1215",
    expiry: "01/09/2028",
    site: "Left Deltoid",
    route: "IM",
    notes: "Patient was in pain",
  },
  {
    prescribedItemId: "9fa1497e-982b-4195-b8e8-352ad4359f44",
    drugName: "Askina DresSil Border dressing 10cm x 10cm   ",
    productCode: null,
    cdSchedule: 0,
    availability: 0,
    isInvalid: false,
    itemCharges: 1,
    drugFamily: null,
    batchNumber: "RXV-1215",
    expiry: "01/09/2028",
    site: "Left Deltoid",
    route: "IM",
    notes: "Patient was in pain",
  },
  {
    prescribedItemId: "e6ae16aa-759d-45cc-8732-3550c5e68053",
    drugName: "Aspire Allergy Relief 2% eye drops   ",
    productCode: null,
    cdSchedule: 0,
    availability: 0,
    isInvalid: false,
    itemCharges: 1,
    drugFamily: null,
    batchNumber: "RXV-1215",
    expiry: "01/09/2028",
    site: "Left Deltoid",
    route: "IM",
    notes: "Patient was in pain",
  },
  {
    prescribedItemId: "64a6829c-bf6f-4fea-9dd6-3560ce09131f",
    drugName: "Aymes ActaSolve High Energy Starter Pack powder   ",
    productCode: null,
    cdSchedule: 0,
    availability: 0,
    isInvalid: false,
    itemCharges: 1,
    drugFamily: null,
    batchNumber: "RXV-1215",
    expiry: "01/09/2028",
    site: "Left Deltoid",
    route: "IM",
    notes: "Patient was in pain",
  },
  {
    prescribedItemId: "d8d13a03-1898-41b4-aa44-357d6838fa1c",
    drugName: "Azocan 150mg capsules   ",
    productCode: null,
    cdSchedule: 0,
    availability: 0,
    isInvalid: true,
    itemCharges: 1,
    drugFamily: null,
    batchNumber: "RXV-1215",
    expiry: "01/09/2028",
    site: "Left Deltoid",
    route: "IM",
    notes: "Patient was in pain",
  },
];
