import React from "react";
import SettingsSidebar from "../SettingsSidebar/SettingsSidebar";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";

export default function ServiceTypesLoadingComponent({
  selectedMenuItem,
  handleMenuItemClick,
  showSubMenuNav,
  onDarkModeChange,
  pharmacyID,
}) {
  const { appState } = useTitanApp();
  const { features, darkMode, currentPharmacyId } = appState;
  return (
    <div className="settings_page">
      <div className="settings_wrapper">
        <SettingsSidebar
          selectedMenuItem={selectedMenuItem}
          handleMenuItemClick={handleMenuItemClick}
          showSubMenuNav={showSubMenuNav}
          onDarkModeChange={darkMode}
          features={features}
          pharmacyID={currentPharmacyId}
        />

        <div className="settings_content_wrapper">
          <>
            <div className="settings_heading">
              <Skeleton
                sx={{
                  bgcolor: darkMode ? "grey.800" : "",
                }}
                height={40}
                width={100}
                animation="wave"
              />
            </div>

            <div className="servicetypes_content_wrapper">
              <div className="servicetype_listbox">
                <div>
                  <div className="allView_list_header">
                    <Skeleton
                      sx={{
                        bgcolor: darkMode ? "grey.800" : "",
                      }}
                      height={40}
                      width={100}
                      animation="wave"
                    />
                  </div>
                </div>
                <div className="allView_listview">
                  <div className="allView_container">
                    <Skeleton
                      sx={{
                        bgcolor: darkMode ? "grey.800" : "",
                      }}
                      height={40}
                      width={100}
                      animation="wave"
                    />
                  </div>
                  <div className="allView_container">
                    <Skeleton
                      sx={{
                        bgcolor: darkMode ? "grey.800" : "",
                      }}
                      height={40}
                      width={100}
                      animation="wave"
                    />
                  </div>
                  <div className="allView_container">
                    <Skeleton
                      sx={{
                        bgcolor: darkMode ? "grey.800" : "",
                      }}
                      height={40}
                      width={100}
                      animation="wave"
                    />
                  </div>
                  <div className="allView_container">
                    <Skeleton
                      sx={{
                        bgcolor: darkMode ? "grey.800" : "",
                      }}
                      height={40}
                      width={100}
                      animation="wave"
                    />
                  </div>
                  <div className="allView_container">
                    <Skeleton
                      sx={{
                        bgcolor: darkMode ? "grey.800" : "",
                      }}
                      height={40}
                      width={100}
                      animation="wave"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}
