import * as React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CustomButton from "../controls/CustomButton";
import "./../../assets/css/PatientModal.css";
import { ImWarning } from "react-icons/im";
import TextField from "@mui/material/TextField";
import {
  addAgencyPharmacy,
  getAgencyRates,
  savePatientContact,
  sendToOrbit,
  setCurrentPatientContact,
  setCurrentPharmacyServices,
  setInterventionStageScrollPosition,
  setOrbitSend,
  setScrollPosition,
} from "../../dispatchers/pharmacyServiceDispatchers";
import useTitanApp from "../../hooks/useTitanApp";
import { isMobileNumberValid } from "../../utils/validMobile";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setCurrentEditedPreferredContact } from "../../dispatchers/nmsDispatchers";
import { showError } from "../ToastedMessage/ToastedMessage";
import { getPharmacyServices } from "../../dispatchers/pharmacyServiceDispatchers";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import { useEffect } from "react";
import CustomLoader from "../controls/CustomLoader";

export default function OrbitSendModal({
  checksCount,
  view,
  rowData,
  setRowData,
}) {
  const { appState, pharmacyServiceState } = useTitanApp();
  const { darkMode, currentPharmacyId } = appState || {};
  const {
    agencyRates,
    isAgencyRatesLoading,
    IsPharmacyAgency,
    pharmacyServices,
    orbitModalQueue,
    agencyPharmacy,
  } = pharmacyServiceState || {};
  const [show, setShow] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [selectedPriceIndex, setSelectedPriceIndex] = useState(null);
  const handleClose = (event) => {
    setShow(false);
    setOrbitSend(false);
  };
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSelect = (agency) => {
    setSelectedAgency(agency);
  };

  const handlePriceSelect = (index) => {
    setSelectedPriceIndex(index);
  };

  const showToast = (message) => {
    toast.success(`${message}`, {
      className: "services-toast",
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      theme: darkMode ? "dark" : "light",
    });
  };
  function handleSubmit() {
    if (selectedOption === "spaceAgent") {
      if (selectedAgency !== null) {
        const obj = {
          companyID: selectedAgency?.companyID,
          pharmacyID: currentPharmacyId,
          isActive: true,
          workLoadType: 1,
          unConsentedRate: selectedAgency?.unConsentedRate,
          consentedRate: selectedAgency?.consentedRate,
          nmsCount: 0,
        };
        const updatedData = orbitModalQueue?.map((item) => ({
          ...item,
          rate: item?.isAutoConsent
            ? selectedAgency?.consentedRate
              ? selectedAgency?.consentedRate
              : "5"
            : selectedAgency?.unConsentedRate
            ? selectedAgency?.unConsentedRate
            : "8",
          companyID: selectedAgency?.companyID,
        }));
        if (agencyPharmacy?.CompanyID !== selectedAgency?.companyID) {
          addAgencyPharmacy(obj).then((data) => {
            callSendToOrbit(updatedData);
          });
        } else {
          callSendToOrbit(updatedData);
        }
      }
    } else {
      const updatedData = orbitModalQueue?.map((item) => ({
        ...item,
        rate: selectedPriceIndex === 0 ? "5" : "8",
        isAutoConsent: selectedPriceIndex === 0 ? true : false,
        companyID: null,
      }));
      callSendToOrbit(updatedData);
    }
  }
  function callSendToOrbit(updatedData) {
    sendToOrbit(updatedData).then((data) => {
      try {
        if (view === "kanban") {
          const newData = pharmacyServices?.map((stage) => ({
            ...stage,
            sortedServices: stage?.sortedServices?.map((service) => ({
              ...service,
              clinicalServices: [...service?.clinicalServices],
            })),
          }));
          const updatedNewData = newData?.map((stage) => {
            if (stage.stageType === 3) {
              return {
                ...stage,
                sortedServices: stage?.sortedServices?.map((serviceGroup) => ({
                  ...serviceGroup,
                  clinicalServices: serviceGroup?.clinicalServices?.map(
                    (clinicalService) => {
                      const queueItem = orbitModalQueue?.find(
                        (item) => item.clinicalServiceID === clinicalService._id
                      );
                      if (queueItem) {
                        return {
                          ...clinicalService,
                          sendToOrbit: true,
                        };
                      }
                      return clinicalService;
                    }
                  ),
                })),
              };
            }
            return stage;
          });
          setCurrentPharmacyServices(updatedNewData);
          const stagesWrapperOrbit = document.querySelector(".stages-wrapper");
          if (stagesWrapperOrbit) {
            setScrollPosition(stagesWrapperOrbit.scrollLeft);
          }
          const stageIntervention =
            document.getElementById("stage_Intervention");
          if (stageIntervention) {
            setInterventionStageScrollPosition(stageIntervention.scrollTop);
          }
        } else {
          let mutableData = JSON.parse(JSON.stringify(rowData));
          orbitModalQueue?.forEach((queueItem) => {
            mutableData?.forEach((patient) => {
              if (patient) {
                if (
                  patient.clinicalServiceId === queueItem.clinicalServiceID &&
                  patient.stageType === 3
                ) {
                  patient.sendToOrbit = "Yes";
                }
              }
            });
          });
          const stageOrder = {
            Eligible: 1,
            "Awaiting consent": 2,
            Intervention: 3,
            Followup: 4,
          };
          mutableData?.sort((a, b) => {
            return stageOrder[a.stageName] - stageOrder[b.stageName];
          });
          setRowData(mutableData);
        }
        showToast(
          `Record${
            orbitModalQueue.length > 1 ? "s" : ""
          } moved to Orbit successfully!`
        );
      } catch (error) {
        showError("Something went wrong");
      }
    });
    handleClose();
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="max-z"
      >
        <div className="clinical_modal send_to_orbit_modal_wrapper">
          <Modal.Body>
            {isAgencyRatesLoading ? (
              <div className="pharmacy-loader">
                <CustomLoader />
              </div>
            ) : (
              <Box className="modal-s-orbit">
                <Typography
                  id="modal-title"
                  variant="h6"
                  sx={{ fontSize: "14px", marginBottom: "15px" }}
                >
                  Select how you would like to outsource your NMS for the{" "}
                  {agencyRates?.length ?? 0} pharmacies selected
                </Typography>
                <FormControl className="radio-s-orbit">
                  <RadioGroup value={selectedOption} onChange={handleChange}>
                    <FormControlLabel
                      value="customPricing"
                      control={<Radio />}
                      label="Custom Pricing"
                    />

                    <Box className="custom-pricing">
                      <Typography variant="body2">
                        How much are you willing to pay per NMS?
                      </Typography>
                      <Box className="price-container">
                        <div
                          className={`consented-price ${
                            selectedPriceIndex === 0 ? "selected" : ""
                          }`}
                          onClick={() => handlePriceSelect(0)}
                        >
                          <Typography>£5</Typography>
                          <Typography variant="caption">Consented</Typography>
                        </div>
                        <div
                          className={`unconsented-price ${
                            selectedPriceIndex === 1 ? "selected" : ""
                          }`}
                          onClick={() => handlePriceSelect(1)}
                        >
                          <Typography>£8</Typography>
                          <Typography variant="caption">Unconsented</Typography>
                        </div>
                      </Box>
                    </Box>
                    <FormControlLabel
                      value="spaceAgent"
                      control={<Radio />}
                      label="Space Agent"
                    />
                    <Box className="space-agent-options">
                      {agencyRates?.map((agency, index) => {
                        return (
                          <div
                            className={`nms-option-orbit ${
                              selectedAgency?.companyID === agency?.companyID
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => handleSelect(agency)}
                          >
                            <Typography className="nms-title-orbit">
                              {agency?.companyName} ⭐⭐⭐
                            </Typography>
                            <div className="spaceAgent_wrapper">
                              <Typography className="nms-description-orbit">
                                We provide the best NMS service in the country.
                                We will even come and clean your car.
                              </Typography>
                              <Box className="price-card">
                                <Typography className="price-card-content">
                                  <span>Consented</span>
                                  <p>£{agency?.consentedRate}</p>
                                </Typography>
                                <Typography>
                                  {" "}
                                  <span>Unconsented</span>
                                  <p>£{agency?.unConsentedRate}</p>
                                </Typography>
                              </Box>
                            </div>
                          </div>
                        );
                      })}
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
          </Modal.Body>
          <div className="progressmodal_footer">
            <CustomButton
              label="Cancel"
              action={() => {
                handleClose();
              }}
            />
            <CustomButton label="Save" action={handleSubmit} />
          </div>
        </div>
      </Modal>
    </>
  );
}
