import React from "react";
import {
  ComposedChart,
  Line,
  LineChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { monthsOrder } from "../ServiceClaimStatus/components/MonthsOrder";
import useTitanApp from "../../../../../../../hooks/useTitanApp";

const EngagementChart = () => {
  const { pharmacyServiceState } = useTitanApp();
  const { serviceAnalyticsStages } = pharmacyServiceState || {};

  const serviceStages = serviceAnalyticsStages
    .slice()
    .sort(
      (a, b) => monthsOrder.indexOf(a.month) - monthsOrder.indexOf(b.month)
    );

  const filteredServiceStages = serviceStages.filter(
    (item) => item.stage === 2
  );

  return (
    <ResponsiveContainer height={400}>
      <ComposedChart data={filteredServiceStages}>
        <CartesianGrid stroke="none" />

        <defs>
          <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#f1c4f2" />
            <stop offset="100%" stopColor="#be7bed" />
          </linearGradient>
          <linearGradient id="gradientFillDark" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#d3d2d4" />
            <stop offset="100%" stopColor="#aaa" />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="month"
          scale="band"
          axisLine={false}
          tickMargin={15}
          className="x_axis_chart title-heading"
          tickLine={false}
          dx={25}
        />
        <YAxis
          domain={[0, 10]}
          axisLine={false}
          tickMargin={20}
          tickLine={false}
          className="y_axis_chart title-heading"
        />
        <Tooltip />
        <Bar
          dataKey="servicesCount"
          barSize={10}
          fill="url(#gradientFillDark)"
          radius={[10, 10, 0, 0]}
          base={0}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default EngagementChart;
