import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost } from "../../services/httpService";
import config from "../../config";
import {
  getClinicalServiceByClinicalServiceIdUrl,
  getClinicalServiceUrl,
  getClinicalServiceFromOrbitUrl,
  getTitanPatientUrl,
  getTitanPatientPdsUrl,
  addNmsUrl,
  updatePrivateServiceUrl,
  getFollowUpByServiceIdAndServiceTypeUrl,
  searchDrugUrl,
  searchPatientUrl,
  addFollowUpUrl,
  getPharmacyIdForOrbitUserUrl,
  getVoiceJWTUrl,
  addTimelineUrl,
  addTimelineItemUrl,
  getTimelineUrl,
  getRecordingByConversationIdUrl,
  saveTotalAttemptsUrl,
  addServiceNotesURL,
  getServiceNotesURL,
  getPatientByTelephoneAndStageIndexUrl,
  updateTotalAttemptsByClinicalServiceIdIrl,
  skipOrbitByClinicalServiceIdUrl,
  backOrbitByClinicalServiceIdUrl,
  parkOrbitByClinicalServiceIdUrl,
  getTotalSkipsUrl,
  getPatientPhoneNumberUrl,
  getPrescriptionImagesByIncomingIdsUrl,
} from "../../apiUrls/apiUrls";
import setServiceNms from "../../Model/Nms/setServiceNms";
import setServicePrivate from "../../Model/Private/setServicePrivate";
import { logError } from "../../services/logService";
const initialState = {
  isNmsLoading: false,
  isNmsPatientTelephoneLoading: false,
  isNmsPatientLoading: false,
  isNmsTimelineLoading: false,
  isNmsPrescriptionLoading: false,
  serviceData: null,
  patientData: null,
  service: null,
  patientName: "",
  pharmacy: null,
  pharmacyID: "",
  clinicalpatientId: "",
  clinicalServiceId: "",
  prescriptionIncomingId: "",
  followupDescription: "",
  followupDate: "",
  calendarId: "",
  arrayImg: [],
  prescriptionImages: [],
  stepNo: 0,
  stageNo: 0,
  NmsModel: null,
  value: 0,
  nmsAdded: null,
  followUpData: [],
  searchOptions: [],
  followUpadded: null,
  isCalling: false,
  showEndCall: false,
  voiceToken: "",
  voiceJWTToken: "",
  timelineAdded: null,
  timelineItemAdded: null,
  timelineElements: [],
  timelineData: [],
  recordingFile: null,
  isServiceNotesLoading: false,
  serviceNotesData: [],
  patientNmsData: [],
  selectedRescheduleOption: false,
  rescheduleComponent: false,
  patientDeclined: false,
  skipNMSModal: false,
  declineNMSModal: false,
  multipleNumbersNMSModal: false,
  declineComments: "",
  skipCounts: 3,
  groupedPatientWarning: false,
  patientCount: 0,
  showNMSNotComplete: false,
  tempClinicalServiceId: "",
  openCallScreenDialog: false,
  callStartTime: "",
  callEndTime: "",
  followUpcomments: "",
  isFollowUpControl: false,
  editedPreferredContact: "",
  patientsTelephone: [],
  responseDTOStagesData: [],
  incomingIds: [],
  prescriptionImages: [],
};
const { voiceJwtServerUrl } = config;

export const getClinicalServiceByClinicalServiceIdData = createAsyncThunk(
  "NmsService/getClinicalServiceByClinicalServiceIdData",
  async (params) => {
    const { clinicalServiceId } = params || {};
    const urlToGet =
      getClinicalServiceByClinicalServiceIdUrl(clinicalServiceId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getClinicalServiceData = createAsyncThunk(
  "NmsService/getClinicalServiceData",
  async (params) => {
    const { currentPharmacyId, isOrbitUser } = params || {};
    const urlToGet = getClinicalServiceUrl(currentPharmacyId, isOrbitUser);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getClinicalServiceFromOrbitData = createAsyncThunk(
  "NmsService/getClinicalServiceFromOrbitData",
  async (params) => {
    const { serviceCode, sessionId, isTestMode } = params;
    const urlToGet = getClinicalServiceFromOrbitUrl(
      serviceCode,
      sessionId,
      isTestMode
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getPharmacyIdForOrbitUserData = createAsyncThunk(
  "NmsService/getPharmacyIdForOrbitUserDate",
  async (params) => {
    const urlToGet = getPharmacyIdForOrbitUserUrl();
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getTitanPatientData = createAsyncThunk(
  "NmsService/getTitanPatientData",
  async (params) => {
    const { patientId, pharmacyID, clinicalServiceId } = params || {};
    const urlToGet = getTitanPatientUrl(
      patientId,
      pharmacyID,
      clinicalServiceId
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getTitanPatientPdsData = createAsyncThunk(
  "NmsService/getTitanPatientPdsData",
  async (params, thunkAPI) => {
    const { pharmacyID, clinicalServiceId } = params || {};

    const respData = await httpGet(
      getTitanPatientPdsUrl(pharmacyID, clinicalServiceId)
    );
    return respData;
  }
);

export const getFollowUpByServiceIdAndServiceTypeData = createAsyncThunk(
  "NmsService/getFollowUpByServiceIdAndServiceTypeData",
  async (params) => {
    const { serviceId, serviceType, stageNo } = params || {};
    const urlToGet = getFollowUpByServiceIdAndServiceTypeUrl(
      serviceId,
      serviceType,
      stageNo
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getPatientByTelephoneAndStageIndexData = createAsyncThunk(
  "NmsService/getPatientByTelephoneAndStageIndexData",
  async (params) => {
    const { telephone, value, serviceId, userSessionId, isOrbitUser } =
      params || {};
    const urlToGet = getPatientByTelephoneAndStageIndexUrl(
      telephone,
      value,
      serviceId,
      userSessionId,
      isOrbitUser
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const addNmsData = createAsyncThunk(
  "NmsService/addNmsData",
  async (params, thunkAPI) => {
    const { data, isOrbitUser } = params || {};
    const respData = await httpPost(addNmsUrl(isOrbitUser), data);
    return respData;
  }
);

export const updatePrivateServiceData = createAsyncThunk(
  "NmsService/updatePrivateServiceData",
  async (params, thunkAPI) => {
    const { data, isOrbitUser, isAddPrescription } = params || {};
    const respData = await httpPost(
      updatePrivateServiceUrl(isOrbitUser, isAddPrescription),
      data
    );
    return respData;
  }
);

export const searchDrugData = createAsyncThunk(
  "NmsService/searchDrugData",
  async (data, thunkAPI) => {
    const respData = await httpPost(searchDrugUrl(), data);
    return respData;
  }
);

export const searchPatientData = createAsyncThunk(
  "NmsService/searchPatientData",
  async (data, thunkAPI) => {
    const respData = await httpPost(searchPatientUrl(), data);
    return respData;
  }
);
export const addFollowUpData = createAsyncThunk(
  "NmsService/addFollowUpData",
  async (data, thunkAPI) => {
    const respData = await httpPost(addFollowUpUrl(), data);
    return respData;
  }
);

export const getVoiceJWTData = createAsyncThunk(
  "NmsService/getVoiceJWTData",
  async (params) => {
    const urlToGet = `${voiceJwtServerUrl}${getVoiceJWTUrl(params)}`;
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const addTimelineData = createAsyncThunk(
  "NmsService/addTimelineData",
  async (data, thunkAPI) => {
    const respData = await httpPost(addTimelineUrl(), data);
    return respData;
  }
);

export const addTimelineItemData = createAsyncThunk(
  "NmsService/addTimelineItemData",
  async (data, thunkAPI) => {
    const respData = await httpPost(addTimelineItemUrl(), data);
    return respData;
  }
);

export const getTimelineData = createAsyncThunk(
  "NmsService/getTimelineData",
  async (params) => {
    const urlToGet = getTimelineUrl(params);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getRecordingByConversationIdData = createAsyncThunk(
  "NmsService/getRecordingByConversationIdData",
  async (conversationId) => {
    const urlToGet = getRecordingByConversationIdUrl(conversationId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const saveTotalAttemptsData = createAsyncThunk(
  "NmsService/saveTotalAttemptsData",
  async (params) => {
    const { clinicalServiceId, currentIndex } = params || {};
    const urlToGet = saveTotalAttemptsUrl(clinicalServiceId, currentIndex);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const updateTotalAttemptsByClinicalServiceIdData = createAsyncThunk(
  "NmsService/updateTotalAttemptsByClinicalServiceIdData",
  async (params) => {
    const { clinicalServiceId } = params || {};
    const urlToGet =
      updateTotalAttemptsByClinicalServiceIdIrl(clinicalServiceId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const skipOrbitByClinicalServiceIdData = createAsyncThunk(
  "NmsService/skipOrbitByClinicalServiceIdData",
  async (params) => {
    const { clinicalServiceId, sessionId, data } = params;
    const urlToGet = skipOrbitByClinicalServiceIdUrl(
      clinicalServiceId,
      sessionId
    );
    const respData = await httpPost(urlToGet, data);
    return respData;
  }
);
export const getPatientPhoneNumberData = createAsyncThunk(
  "NmsService/getPatientPhoneNumberData",
  async (params) => {
    const { clinicalServiceId, patientId } = params;
    const urlToGet = getPatientPhoneNumberUrl(clinicalServiceId, patientId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getTotalSkipsData = createAsyncThunk(
  "NmsService/getTotalSkipsData",
  async (params) => {
    const { clinicalServiceId, sessionId } = params;
    const urlToGet = getTotalSkipsUrl(clinicalServiceId, sessionId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const backOrbitByClinicalServiceIdData = createAsyncThunk(
  "NmsService/backOrbitByClinicalServiceIdData",
  async (params) => {
    const urlToGet = backOrbitByClinicalServiceIdUrl(params);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const parkOrbitByClinicalServiceIdData = createAsyncThunk(
  "NmsService/parkOrbitByClinicalServiceIdData",
  async (params) => {
    const urlToGet = parkOrbitByClinicalServiceIdUrl(params);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const addServiceNotesData = createAsyncThunk(
  "NmsService/addServiceNotesData",
  async (data, thunkAPI) => {
    const respData = await httpPost(addServiceNotesURL(), data);
    return respData;
  }
);

export const getServiceNotesData = createAsyncThunk(
  "NmsService/getServiceNotesData",
  async (params) => {
    const { clinicalServiceId, stageType } = params || {};
    const urlToGet = getServiceNotesURL(clinicalServiceId, stageType);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getPrescriptionImagesByIncomingIdsData = createAsyncThunk(
  "clinicalChecks/getPrescriptionImagesByIncomingIdsData",
  async (params) => {
    const { incomingIds, pharmacyID } = params || {};
    const respData = await httpPost(
      getPrescriptionImagesByIncomingIdsUrl(pharmacyID),
      incomingIds
    );
    return respData;
  }
);

export const nmsServiceSlice = createSlice({
  name: "NmsService",
  initialState,
  reducers: {
    setIsNmsLoading: (state, action) => {
      state.isNmsLoading = action?.payload || false;
    },
    setIsNmsPatientLoading: (state, action) => {
      state.isNmsPatientLoading = action?.payload || false;
    },
    setIsPatientNmsTelephoneLoading: (state, action) => {
      state.isNmsPatientTelephoneLoading = action?.payload || false;
    },
    setIsNmsTimelineLoading: (state, action) => {
      state.isNmsTimelineLoading = action?.payload || false;
    },
    setIsNmsPrescriptionLoading: (state, action) => {
      state.isNmsPrescriptionLoading = action?.payload || false;
    },
    setIsServiceNotesLoading: (state, action) => {
      state.isServiceNotesLoading = action?.payload || false;
    },
    setClinicalServiceData: (state, action) => {
      state.serviceData = action?.payload || null;
    },
    setPatientData: (state, action) => {
      state.patientData = action?.payload || null;
    },
    setService: (state, action) => {
      state.service = action?.payload || null;
    },
    setPatientName: (state, action) => {
      state.patientName = action?.payload || "";
    },
    setPharmacyNms: (state, action) => {
      state.pharmacy = action?.payload || null;
    },
    setPharmacyId: (state, action) => {
      state.pharmacyID = action?.payload || "";
    },
    setClinicalpatientId: (state, action) => {
      state.clinicalpatientId = action?.payload || "";
    },
    setClinicalServiceId: (state, action) => {
      state.clinicalServiceId = action?.payload || "";
    },
    setArrayImg: (state, action) => {
      state.arrayImg = action?.payload || [];
    },
    setIsPrescriptionImages: (state, action) => {
      state.prescriptionImages = action?.payload || [];
    },
    setStepNoNms: (state, action) => {
      state.stepNo = action?.payload || 0;
    },
    setstageNoNms: (state, action) => {
      state.stageNo = action?.payload || 0;
    },
    setNmsModelNms: (state, action) => {
      state.NmsModel = action?.payload || [];
    },
    setvalueNms: (state, action) => {
      state.value = action?.payload || 0;
    },
    setIsShowEndCall: (state, action) => {
      state.showEndCall = action?.payload || false;
    },
    setIsCalling: (state, action) => {
      state.isCalling = action?.payload || false;
    },
    setIsFollowupDescription: (state, action) => {
      state.followupDescription = action?.payload || false;
    },
    setIsFollowupDate: (state, action) => {
      state.followupDate = action?.payload || false;
    },
    setVoiceJWTToken: (state, action) => {
      state.voiceJWTToken = action?.payload || "";
    },
    setTimelineElements: (state, action) => {
      state.timelineElements = action?.payload || [];
    },
    setPatientNMSData: (state, action) => {
      state.patientNmsData = action?.payload || [];
    },
    setPatientCountData: (state, action) => {
      state.patientCount = action?.payload || [];
    },
    setSkipCountData: (state, action) => {
      state.skipCounts = action?.payload || [];
    },
    setRescheduleComponent: (state, action) => {
      state.rescheduleComponent = action?.payload || false;
    },
    setPatientDeclined: (state, action) => {
      state.patientDeclined = action?.payload || false;
    },
    setSkipNMSModal: (state, action) => {
      state.skipNMSModal = action?.payload || false;
    },
    setDeclineNMSModal: (state, action) => {
      state.declineNMSModal = action?.payload || false;
    },
    setMultipleNumbersNMSModal: (state, action) => {
      state.multipleNumbersNMSModal = action?.payload || false;
    },
    setDeclineComments: (state, action) => {
      state.declineComments = action?.payload || "";
    },
    setFollowUpcomments: (state, action) => {
      state.followUpcomments = action?.payload || "";
    },
    setGroupedPatientWarning: (state, action) => {
      state.groupedPatientWarning = action?.payload || false;
    },
    setShowNMSNotComplete: (state, action) => {
      state.showNMSNotComplete = action?.payload || false;
    },
    setTempClinicalServiceId: (state, action) => {
      state.tempClinicalServiceId = action?.payload || false;
    },
    setOpenCallScreenDialogData: (state, action) => {
      state.openCallScreenDialog = action?.payload || false;
    },
    setIsFollowUpControlData: (state, action) => {
      state.isFollowUpControl = action?.payload || false;
    },
    setCallStartTimeData: (state, action) => {
      state.callStartTime = action?.payload || "";
    },
    setCallEndTimeData: (state, action) => {
      state.callEndTime = action?.payload || "";
    },
    setEditedPreferredContact: (state, action) => {
      state.editedPreferredContact = action?.payload || false;
    },
    setResponseDTOStagesData: (state, action) => {
      state.responseDTOStagesData = action?.payload || false;
    },
    resetNmsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClinicalServiceByClinicalServiceIdData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(
        getClinicalServiceByClinicalServiceIdData.fulfilled,
        (state, action) => {
          state.isNmsLoading = false;
          const appDataFromResponse = action?.payload;
          try {
            state.serviceData = appDataFromResponse || null;
            const existingModel = localStorage.getItem(
              appDataFromResponse?.clinicalServiceBuilderResponseDTO?._id
            );
            if (existingModel) {
              state.service = JSON.parse(existingModel);
            } else {
              state.service =
                appDataFromResponse?.clinicalServiceBuilderResponseDTO || null;
            }

            state.pharmacy = appDataFromResponse?.pharmacy || null;
            state.pharmacyID = appDataFromResponse?.pharmacy?.pharmacyID || "";
            state.clinicalpatientId =
              appDataFromResponse?.clinicalServiceBuilderResponseDTO
                ?.patientId || "";
            state.clinicalServiceId =
              appDataFromResponse?.clinicalServiceBuilderResponseDTO?._id || "";
            state.prescriptionIncomingId =
              appDataFromResponse?.clinicalServiceBuilderResponseDTO
                ?.prescriptionIncomingId || "";
            state.incomingIds = appDataFromResponse?.incomingIds || [];
            let service =
              appDataFromResponse?.clinicalServiceBuilderResponseDTO;
            let StageIndexForTab,
              stageNo = 0;
            state.stepNo = service.stages[stageNo]?.metaDataList?.length || 0;
            const stages = service?.stages;
            state.editedPreferredContact = service?.telephoneNumber;

            for (let index = 0; index < stages?.length; index++) {
              const stage = stages[index];

              if (stage.clinicalServiceStageStatus !== 2) {
                StageIndexForTab = index;
                if (index > 1) {
                  state.stepNo = 1;
                }
                state.stepNo = index;
                break;
              }
            }
            let model;
            if (service?.serviceType === 2) {
              model = new setServicePrivate(
                service,
                StageIndexForTab,
                null,
                null,
                false,
                1,
                null,
                null,
                null,
                null,
                "",
                "00000000-0000-0000-0000-000000000000",
                null
              );
            } else {
              model = new setServiceNms(
                service,
                StageIndexForTab,
                null,
                null,
                false,
                1,
                null,
                null,
                null,
                null,
                "",
                "00000000-0000-0000-0000-000000000000",
                null
              );
            }
            const existingModelNms = localStorage.getItem(
              appDataFromResponse?.clinicalServiceBuilderResponseDTO?._id
            );
            if (existingModelNms) {
              state.NmsModel = JSON.parse(existingModelNms);
            } else {
              state.NmsModel = model;
            }
            state.value = StageIndexForTab;
          } catch (error) {}
        }
      )
      .addCase(
        getClinicalServiceByClinicalServiceIdData.rejected,
        (state, action) => {
          state.isNmsLoading = false;
          state.serviceData = null;
        }
      )
      .addCase(getClinicalServiceData.rejected, (state, action) => {
        state.isNmsLoading = false;
        state.serviceData = null;
      })
      .addCase(getClinicalServiceData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(getClinicalServiceData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
        const appDataFromResponse = action?.payload;
        try {
          state.serviceData = appDataFromResponse || null;
          const existingModel = localStorage.getItem(
            appDataFromResponse?.clinicalServiceBuilderResponseDTO?._id
          );
          if (existingModel) {
            state.service = JSON.parse(existingModel);
          } else {
            state.service =
              appDataFromResponse?.clinicalServiceBuilderResponseDTO || null;
          }
          state.pharmacy = appDataFromResponse?.pharmacy || null;
          state.pharmacyID = appDataFromResponse?.pharmacy?.pharmacyID || "";
          state.clinicalpatientId =
            appDataFromResponse?.clinicalServiceBuilderResponseDTO?.patientId ||
            "";
          state.clinicalServiceId =
            appDataFromResponse?.clinicalServiceBuilderResponseDTO?._id || "";
          state.prescriptionIncomingId =
            appDataFromResponse?.clinicalServiceBuilderResponseDTO
              ?.prescriptionIncomingId || "";
          let service = appDataFromResponse?.clinicalServiceBuilderResponseDTO;
          let StageIndexForTab,
            stageNo = 0;
          state.stepNo = service.stages[stageNo]?.metaDataList?.length || 0;
          const stages = service?.stages;

          for (let index = 0; index < stages?.length; index++) {
            const stage = stages[index];

            if (stage.clinicalServiceStageStatus !== 2) {
              StageIndexForTab = index;
              if (index > 1) {
                state.stepNo = 1;
              }
              state.stepNo = index;
              break;
            }
          }
          let model;
          if (service?.serviceType === 2) {
            model = new setServicePrivate(
              service,
              StageIndexForTab,
              null,
              null,
              false,
              1,
              null,
              null,
              null,
              null,
              "",
              "00000000-0000-0000-0000-000000000000",
              null
            );
          } else {
            model = new setServiceNms(
              service,
              StageIndexForTab,
              null,
              null,
              false,
              1,
              null,
              null,
              null,
              null,
              "",
              "00000000-0000-0000-0000-000000000000",
              null
            );
          }
          const existingModelNms = localStorage.getItem(
            appDataFromResponse?.clinicalServiceBuilderResponseDTO?._id
          );
          if (existingModelNms) {
            state.NmsModel = JSON.parse(existingModelNms);
          } else {
            state.NmsModel = model;
          }
          state.value = StageIndexForTab;
        } catch (error) {}
      })
      .addCase(getClinicalServiceFromOrbitData.rejected, (state, action) => {
        state.isNmsLoading = false;
        state.serviceData = null;
      })
      .addCase(getClinicalServiceFromOrbitData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(getClinicalServiceFromOrbitData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
        const appDataFromResponse = action?.payload;
        try {
          state.serviceData = appDataFromResponse || null;
          const existingModel = localStorage.getItem(
            appDataFromResponse?.clinicalServiceBuilderResponseDTO?._id
          );
          if (existingModel) {
            state.service = JSON.parse(existingModel);
          } else {
            state.service =
              appDataFromResponse?.clinicalServiceBuilderResponseDTO || null;
          }
          state.pharmacy = appDataFromResponse?.pharmacy || null;
          state.pharmacyID = appDataFromResponse?.pharmacy?.pharmacyID || "";
          state.clinicalpatientId =
            appDataFromResponse?.clinicalServiceBuilderResponseDTO?.patientId ||
            "";
          state.clinicalServiceId =
            appDataFromResponse?.clinicalServiceBuilderResponseDTO?._id || "";
          state.prescriptionIncomingId =
            appDataFromResponse?.clinicalServiceBuilderResponseDTO
              ?.prescriptionIncomingId || "";
          let service = appDataFromResponse?.clinicalServiceBuilderResponseDTO;
          let StageIndexForTab,
            stageNo = 0;
          state.stepNo = service.stages[stageNo]?.metaDataList?.length || 0;
          const stages = service?.stages;

          for (let index = 0; index < stages?.length; index++) {
            const stage = stages[index];

            if (stage.clinicalServiceStageStatus !== 2) {
              StageIndexForTab = index;
              if (index > 1) {
                state.stepNo = 1;
              }
              state.stepNo = index;
              break;
            }
          }
          let model;
          if (service?.serviceType === 2) {
            model = new setServicePrivate(
              service,
              StageIndexForTab,
              null,
              null,
              false,
              1,
              null,
              null,
              null,
              null,
              "",
              "00000000-0000-0000-0000-000000000000",
              null
            );
          } else {
            model = new setServiceNms(
              service,
              StageIndexForTab,
              null,
              null,
              false,
              1,
              null,
              null,
              null,
              null,
              "",
              "00000000-0000-0000-0000-000000000000",
              null
            );
          }
          const existingModelNms = localStorage.getItem(
            appDataFromResponse?.clinicalServiceBuilderResponseDTO?._id
          );
          if (existingModelNms) {
            state.NmsModel = JSON.parse(existingModelNms);
          } else {
            state.NmsModel = model;
          }
          state.value = StageIndexForTab;
        } catch (error) {}
      })
      .addCase(getTitanPatientData.rejected, (state, action) => {
        state.isNmsPatientLoading = false;
        state.patientData = null;
      })
      .addCase(getTitanPatientData.pending, (state) => {
        state.isNmsPatientLoading = true;
      })
      .addCase(getTitanPatientData.fulfilled, (state, action) => {
        state.isNmsPatientLoading = false;
        const appDataFromResponse = action?.payload;
        try {
          state.patientData = appDataFromResponse || null;
        } catch (error) {}
      })
      .addCase(getTitanPatientPdsData.rejected, (state, action) => {
        // state.isNmsPatientLoading = false;
        // state.patientData = null;
      })
      .addCase(getTitanPatientPdsData.pending, (state) => {
        //state.isNmsPatientLoading = true;
      })
      .addCase(getTitanPatientPdsData.fulfilled, (state, action) => {
        // state.isNmsPatientLoading = false;
        const appDataFromResponse = action?.payload;
        try {
          if (appDataFromResponse?.patientGetModel !== null) {
            state.patientData = appDataFromResponse || null;
          }
        } catch (error) {}
      })
      .addCase(
        getFollowUpByServiceIdAndServiceTypeData.rejected,
        (state, action) => {
          state.isNmsLoading = false;
          state.followUpData = [];
        }
      )
      .addCase(getFollowUpByServiceIdAndServiceTypeData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(
        getFollowUpByServiceIdAndServiceTypeData.fulfilled,
        (state, action) => {
          state.isNmsLoading = false;
          const appDataFromResponse = action?.payload;
          if (appDataFromResponse.length > 0) {
            try {
              state.followUpData = appDataFromResponse || [];
              const { description, start, _id } = appDataFromResponse[0] || {};
              if (description !== "Follow up 0") {
                state.followupDescription = description;
              }
              const parsedDate = new Date(start);
              state.followupDate = parsedDate;
              state.calendarId = _id;
            } catch (error) {}
          } else {
            const currentDate = new Date();
            const futureDate = new Date(currentDate);
            futureDate.setDate(currentDate.getDate() + 14);
            // state.followupDescription = "Follow up face to face";
            state.followupDate = futureDate;
          }
        }
      )
      .addCase(
        getPatientByTelephoneAndStageIndexData.rejected,
        (state, action) => {
          state.isNmsPatientTelephoneLoading = false;
          state.patientNmsData = [];
          state.patientCount = 0;
        }
      )
      .addCase(getPatientByTelephoneAndStageIndexData.pending, (state) => {
        state.isNmsPatientTelephoneLoading = true;
      })
      .addCase(
        getPatientByTelephoneAndStageIndexData.fulfilled,
        (state, action) => {
          state.isNmsPatientTelephoneLoading = false;
          const appDataFromResponse = action?.payload;
          if (appDataFromResponse.length > 0) {
            try {
              state.patientNmsData = appDataFromResponse || [];
            } catch (error) {}
          } else {
            state.patientNmsData = [];
          }
        }
      )
      .addCase(addNmsData.rejected, (state, action) => {
        state.isNmsLoading = false;
        state.nmsAdded = [];
      })
      .addCase(addNmsData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(addNmsData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.nmsAdded = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(updatePrivateServiceData.rejected, (state, action) => {
        state.isNmsLoading = false;
        state.nmsAdded = [];
      })
      .addCase(updatePrivateServiceData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(updatePrivateServiceData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.nmsAdded = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(searchDrugData.rejected, (state, action) => {
        state.isNmsLoading = false;
        state.searchOptions = [];
      })
      .addCase(searchDrugData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(searchDrugData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.searchOptions = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(searchPatientData.rejected, (state, action) => {
        state.isNmsLoading = false;
        state.searchOptions = [];
      })
      .addCase(searchPatientData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(searchPatientData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.searchOptions = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(addFollowUpData.rejected, (state, action) => {
        state.isNmsLoading = false;
        state.followUpadded = [];
      })
      .addCase(addFollowUpData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(addFollowUpData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.followUpadded = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(getVoiceJWTData.rejected, (state, action) => {
        state.isNmsLoading = false;
        state.voiceToken = "";
      })
      .addCase(getVoiceJWTData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(getVoiceJWTData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.voiceToken = appDataFromResponse || "";
          } catch (error) {}
        }
      })
      .addCase(addTimelineData.rejected, (state, action) => {
        state.isNmsLoading = false;
        state.timelineAdded = [];
      })
      .addCase(addTimelineData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(addTimelineData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.timelineAdded = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(addTimelineItemData.rejected, (state, action) => {
        state.isNmsLoading = false;
        state.timelineAdded = [];
      })
      .addCase(addTimelineItemData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(addTimelineItemData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.timelineItemAdded = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(getTimelineData.rejected, (state, action) => {
        state.isNmsTimelineLoading = false;
        state.timelineData = [];
      })
      .addCase(getTimelineData.pending, (state) => {
        state.isNmsTimelineLoading = true;
      })
      .addCase(getTimelineData.fulfilled, (state, action) => {
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.timelineData = appDataFromResponse || [];
          } catch (error) {}
        }
        state.isNmsTimelineLoading = false;
      })
      .addCase(getRecordingByConversationIdData.rejected, (state, action) => {
        state.isNmsLoading = false;
        state.recordingFile = null;
      })
      .addCase(getRecordingByConversationIdData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(getRecordingByConversationIdData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.recordingFile = appDataFromResponse || null;
          } catch (error) {}
        }
        state.isNmsTimelineLoading = false;
      })
      .addCase(saveTotalAttemptsData.rejected, (state, action) => {
        state.isNmsLoading = false;
      })
      .addCase(saveTotalAttemptsData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(saveTotalAttemptsData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
      })
      .addCase(
        updateTotalAttemptsByClinicalServiceIdData.rejected,
        (state, action) => {
          state.isNmsLoading = false;
        }
      )
      .addCase(updateTotalAttemptsByClinicalServiceIdData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(
        updateTotalAttemptsByClinicalServiceIdData.fulfilled,
        (state, action) => {
          state.isNmsLoading = false;
        }
      )
      .addCase(skipOrbitByClinicalServiceIdData.rejected, (state, action) => {
        state.isNmsLoading = false;
      })
      .addCase(skipOrbitByClinicalServiceIdData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(skipOrbitByClinicalServiceIdData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
      })
      .addCase(getPatientPhoneNumberData.rejected, (state, action) => {
        state.isNmsLoading = false;
      })
      .addCase(getPatientPhoneNumberData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(getPatientPhoneNumberData.fulfilled, (state, action) => {
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          state.isNmsLoading = false;
          try {
            state.patientsTelephone = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(getTotalSkipsData.rejected, (state, action) => {
        state.isNmsLoading = false;
        state.skipCounts = 3;
      })
      .addCase(getTotalSkipsData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(getTotalSkipsData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
        state.skipCounts = 3 - action?.payload;
      })
      .addCase(backOrbitByClinicalServiceIdData.rejected, (state, action) => {
        state.isNmsLoading = false;
      })
      .addCase(backOrbitByClinicalServiceIdData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(backOrbitByClinicalServiceIdData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
      })
      .addCase(parkOrbitByClinicalServiceIdData.rejected, (state, action) => {
        state.isNmsLoading = false;
      })
      .addCase(parkOrbitByClinicalServiceIdData.pending, (state) => {
        state.isNmsLoading = true;
      })
      .addCase(parkOrbitByClinicalServiceIdData.fulfilled, (state, action) => {
        state.isNmsLoading = false;
      })
      .addCase(addServiceNotesData.rejected, (state, action) => {
        state.isServiceNotesLoading = false;
      })
      .addCase(addServiceNotesData.pending, (state) => {
        state.isServiceNotesLoading = true;
      })
      .addCase(addServiceNotesData.fulfilled, (state, action) => {
        state.isServiceNotesLoading = false;
      })
      .addCase(getServiceNotesData.rejected, (state, action) => {
        state.isServiceNotesLoading = false;
        state.serviceNotesData = [];
      })
      .addCase(getServiceNotesData.pending, (state) => {
        state.isServiceNotesLoading = true;
      })
      .addCase(getServiceNotesData.fulfilled, (state, action) => {
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          state.isServiceNotesLoading = false;
          try {
            state.serviceNotesData = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(getPrescriptionImagesByIncomingIdsData.pending, (state) => {
        state.isNmsPrescriptionLoading = true;
        state.prescriptionImages = [];
      })
      .addCase(
        getPrescriptionImagesByIncomingIdsData.fulfilled,
        (state, action) => {
          const appDataFromResponse = action?.payload;
          if (appDataFromResponse) {
            try {
              state.prescriptionImages = appDataFromResponse;
            } catch (error) {
              logError(error);
            }
          }
        }
      )
      .addCase(
        getPrescriptionImagesByIncomingIdsData.rejected,
        (state, action) => {
          logError(action?.error);
          state.prescriptionImages = [];
        }
      );
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsNmsLoading,
  setIsNmsPatientLoading,
  setIsNmsTimelineLoading,
  setIsPatientNmsTelephoneLoading,
  setIsNmsPrescriptionLoading,
  setIsServiceNotesLoading,
  setClinicalServiceData,
  setPatientData,
  setService,
  setPatientName,
  setPharmacyNms,
  setPharmacyId,
  setClinicalpatientId,
  setClinicalServiceId,
  setArrayImg,
  setIsPrescriptionImages,
  setStepNoNms,
  setstageNoNms,
  setNmsModelNms,
  setvalueNms,
  setIsShowEndCall,
  setIsCalling,
  setIsFollowupDescription,
  setIsFollowupDate,
  setVoiceJWTToken,
  setTimelineElements,
  setPatientNMSData,
  setPatientCountData,
  setSkipCountData,
  resetNmsState,
  setRescheduleComponent,
  setPatientDeclined,
  setSkipNMSModal,
  setDeclineNMSModal,
  setMultipleNumbersNMSModal,
  setDeclineComments,
  setFollowUpcomments,
  setGroupedPatientWarning,
  setShowNMSNotComplete,
  setTempClinicalServiceId,
  setOpenCallScreenDialogData,
  setCallStartTimeData,
  setCallEndTimeData,
  setIsFollowUpControlData,
  setEditedPreferredContact,
  setResponseDTOStagesData,
} = nmsServiceSlice.actions;

export default nmsServiceSlice.reducer;
