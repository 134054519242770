import React from "react";
import CustomButton from "../../../../components/controls/CustomButton";
import CustomLabel from "../../../../components/controls/CustomLabel";
import useTitanApp from "../../../../hooks/useTitanApp";
import LoadingComponentNMSPatient from "../../../../components/LoadingComponentNMS/LoadingComponentNMSPatient";

export default function PrivateServicePreBooking() {
  const { nmsServiceState } = useTitanApp();
  const { isNmsLoading, responseDTOStagesData } = nmsServiceState || {};

  const processedData = responseDTOStagesData.reduce((acc, item) => {
    acc[item.name] = item.value;
    return acc;
  }, {});

  return isNmsLoading ? (
    <LoadingComponentNMSPatient />
  ) : (
    <div className="content-div patient-info-container private-service-activity">
      <div className="general-inner-boxes">
        {responseDTOStagesData?.clinicalServiceStageStatus !== 2 ? (
          <>
            <div className="genaral_pds">
              <h2 className="card-heading">General</h2>
            </div>
            <div className="general-info">
              <div className="info-box">
                <span>
                  <CustomLabel name="Name" />
                  <p className="card-inner-value name">
                    {`${processedData.Title} ${processedData.FirstName} ${processedData.LastName}`}
                  </p>
                </span>
              </div>
              <div className="info-box">
                <span>
                  <CustomLabel name="Date of birth" />
                  <p className="card-inner-value">{processedData.DOB}</p>
                </span>
              </div>
            </div>
            <div className="general-info">
              <div className="info-box">
                <span>
                  <CustomLabel name="Email" />
                  <p className="card-inner-value">{processedData.Email}</p>
                </span>
              </div>
              <div className="info-box">
                <span>
                  <CustomLabel name="Gender" />
                  <p className="card-inner-value">{processedData.Gender}</p>
                </span>
              </div>
            </div>
            <div className="general-info">
              <div className="info-box">
                <span>
                  <div className="d-flex edit-contact">
                    <CustomLabel name="Preferred Contact" />
                  </div>
                  <p className="card-inner-value phone-field">
                    {processedData.PatientTelephoneNumber}
                  </p>
                </span>
              </div>
              <div className="info-box">
                <span>
                  <CustomLabel name="Address" />
                  <p className="card-inner-value">
                    {processedData.PatientAddress}
                  </p>
                </span>
              </div>
            </div>

            <div className="general-info grids-2">
              <div className="info-box">
                <span>
                  <CustomLabel name="Reason" />
                  <p className="card-inner-value">{processedData.Reason}</p>
                </span>
              </div>
            </div>
          </>
        ) : (
          <div className="error-msg">
            <p>Patient Details are not available!</p>
          </div>
        )}
      </div>
    </div>
  );
}
