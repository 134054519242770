import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./../../../../../../../../assets/css/Dashboard/Claim.scss";

export default function Claimed(props) {
  const StatusIcon = (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  );

  const gridStyle = { height: "100%", width: "100%" };

  const PatientColumns = [
    {
      field: "selected",
      width: 0,
      selected: false,
      headerClass: "defaultGridHeaderCol",
    },
    {
      headerName: "Patient name",
      field: "name",
      sortable: true,
      editable: false,
    },
    {
      headerName: "Date of Nms",
      field: "dateOfService",
      sortable: true,
      editable: false,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return date?.toLocaleDateString("en-GB", options);
      },
    },
    {
      headerName: "Completed Date",
      field: "completedDate",
      sortable: true,
      editable: false,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return date.toLocaleDateString("en-GB", options);
      },
    },
    {
      headerName: "Status",
      field: "serviceStatus",
      sortable: true,
      editable: false,
      cellRendererFramework: StatusIcon,
      valueGetter: (params) => {
        switch (params.data.serviceStatus) {
          case 0:
            return "Pending";
          case 1:
            return "Inprocess";
          case 2:
            return "Completed";
          case 3:
            return "Declined";
          default:
            return params?.data?.serviceStatus;
        }
      },
    },
  ];

  const gridOptions = {
    suppressMovableColumns: true,
    getRowClass: (params) => (params?.node?.isSelected ? "selected-row" : ""),
    onRowClicked: (event) => {
      if (props.onRowClick) {
        props.onRowClick(event.data);
      }
    },
  };

  return (
    <div
      style={gridStyle}
      className={
        props?.darkMode === true ? "ag-theme-alpine-dark" : "ag-theme-alpine"
      }
    >
      {props?.claimedServices?.length !== 0 ? (
        <AgGridReact
          rowData={props?.claimedServices}
          columnDefs={PatientColumns}
          gridOptions={gridOptions}
        />
      ) : (
        <div>
          <h4>No Data To Show</h4>
        </div>
      )}
    </div>
  );
}
