import React from "react";
import ProductTypesDetail from "./components/ProductTypesDetail";
import ProductTypesAppointment from "./components/ProductTypesAppointment";
import ProductTypesConfirmCancellation from "./components/ProductTypesConfirmCancellation";
import ProductTypesReminders from "./components/ProductTypesReminders";

export default function ProductTypesTab() {
  return (
    <div className="addMember_formFields privateservices_formFields">
      <div className="inner-panels">
        <div className="AddNew_sectionbox">
          <h4>Detail</h4>
          <ProductTypesDetail />
        </div>
      </div>
      <div className="inner-panels">
        <div className="AddNew_sectionbox">
          <h4>Appointment Options</h4>
          <ProductTypesAppointment />
        </div>
      </div>
      <div className="inner-panels">
        <div className="AddNew_sectionbox">
          <h4>Confirmation & Cancellation</h4>
          <ProductTypesConfirmCancellation />
        </div>
      </div>
      <div className="inner-panels">
        <div className="AddNew_sectionbox">
          <h4>Reminders</h4>
          <ProductTypesReminders />
        </div>
      </div>
    </div>
  );
}
