import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import "./../../assets/css/NmsDetails.scss";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import { FaRegCommentDots } from "react-icons/fa6";
import { GoPencil } from "react-icons/go";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import useTitanApp from "../../hooks/useTitanApp";
import NMSEmailTemplate from "./components/NMSEmailTemplate";
import NMSSignatureTemplate from "./components/NMSSignatureTemplate";
import NMSNoteTemplate from "./components/NMSNoteTemplate";
import {
  timelineEventTypes,
  timelineEventTypeDescription,
} from "../../enum/timelineEventTypes";
import NMSFollowupTemplate from "./components/NMSFollowupTemplate";
import NMSPhoneCall from "./components/NMSPhoneCall";
import Waveform from "./components/Waveform";
import moment from "moment";
import LoadingComponentNMSTimeline from "../LoadingComponentNMS/LoadingComponentNMSTimeline";
import NMSGroupedPhoneEvents from "./components/NMSGroupedPhoneEvents";
import NMSEventsGrouped from "./components/NMSEventsGrouped";
import NMSGroupedNotes from "./components/NMSGroupedNotes";
import { getFormatDate24Hrs } from "../../utils/getFormatDate24Hrs";

function NmsTimeLine({ stageName, view }) {
  let isGrouped = true;
  const { appState, nmsServiceState } = useTitanApp();
  const { darkMode } = appState || {};
  const { timelineData, patientData, isNmsTimelineLoading } =
    nmsServiceState || {};
  const [expanded, setExpanded] = useState({});
  const [notes, setNotes] = useState("");
  const [notesHistory, setNotesHistory] = useState([]);

  const handleChange = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: prevExpanded[index] === index ? null : index,
    }));
  };

  const handleNotesChange = (event) => {
    setNotes(event?.target?.value);
  };

  const handleSaveNotes = () => {
    const moment = require("moment");
    const currentDate = new Date().toLocaleDateString();
    const ukFormattedDate = moment(currentDate).format("DD/MM/YYYY");
    const newNoteEntry = {
      notes: notes,
      date: ukFormattedDate,
      addedBy: "Shahab Uddin",
    };
    setNotesHistory([...notesHistory, newNoteEntry]);
    setNotes("");
  };

  const getFormatDateOnly = (dateString) => {
    let momentdate = moment(dateString);
    let date = momentdate?.format("DD/MM/yyyy");
    return date;
  };

  const [toggleCollapseAll, setToggleCollapseAll] = useState(false);

  const handleToggleChange = () => {
    if (toggleCollapseAll) {
      setExpanded({});
    } else {
      const allExpanded = {};
      timelineData.forEach((_, index) => {
        allExpanded[index] = false;
      });
      setExpanded(allExpanded);
    }
    setToggleCollapseAll((prev) => !prev);
  };

  const extractedNotesComments = timelineData?.flatMap((timelineItem) =>
    timelineItem?.items
      ?.flatMap((item) => {
        const createdByName = item?.createdByName;
        const createdOn = getFormatDate24Hrs(item?.createdOn);

        return item?.elements
          ?.flatMap((element) => {
            const elementName = element?.name;
            const titleName = element?.title;

            return element?.listItems
              ?.filter(
                (listItem) =>
                  listItem.type === "multiText" || listItem.type === "Text"
              )
              .map((listItem) => ({
                value: listItem.value,
                createdByName,
                createdOn,
                elementName: elementName === "Notes" ? "" : elementName,
                titleName: titleName === "Notes" ? "" : titleName,
                stageName: listItem.label,
              }))
              .filter(Boolean);
          })
          .flat()
          .filter(Boolean);
      })
      .flat()
      .filter(Boolean)
  );

  return isNmsTimelineLoading ? (
    <LoadingComponentNMSTimeline />
  ) : (
    <div className="activity_feed_wrapper">
      <div className="activity_feed_header">
        <h4>
          {isNmsTimelineLoading === false && timelineData
            ? `Activity feed for ${
                view === "private-service" ? "Private Service" : "NMS"
              }`
            : `No activity feeds for ${
                view === "private-service" ? "Private Service" : "NMS"
              }`}
        </h4>
      </div>
      {timelineData?.map((item, index) => {
        return (
          <div className="activity_feed_stepper">
            <Timeline key={index}>
              {item?.items
                ?.filter(
                  (data) => ![7, 11, 12, 13, 14, 18].includes(data?.event)
                )
                .map((data, index) => {
                  let createdbyName = data?.createdByName;
                  if (
                    data?.event === 9 ||
                    data?.event === 10 ||
                    data?.event === 17
                  ) {
                    return (
                      <>
                        {isGrouped ? (
                          <NMSGroupedPhoneEvents
                            data={data}
                            stageName={stageName}
                            createdbyName={createdbyName}
                          />
                        ) : (
                          <></>
                        )}
                        {(isGrouped = false)}
                      </>
                    );
                  } else if (
                    data?.event === 5 ||
                    data?.event === 6 ||
                    data?.event === 15 ||
                    data?.event === 16 ||
                    data?.event === 24 ||
                    data?.event === 25 ||
                    data?.event === 26 ||
                    data?.event === 27
                  ) {
                    return (
                      <NMSEventsGrouped
                        data={data}
                        stageName={stageName}
                        createdbyName={createdbyName}
                      />
                    );
                  } else
                    return (
                      <TimelineItem key={index}>
                        <TimelineSeparator>
                          {data?.event === 4 ? (
                            <FaRegCommentDots
                              size={30}
                              color={darkMode ? "#f2f2f2" : "#0a0a0a"}
                            />
                          ) : (
                            <IoCheckmarkCircleOutline
                              size={30}
                              color={darkMode ? "#f2f2f2" : "#0a0a0a"}
                            />
                          )}
                          {index !== Object.keys(data)?.length && (
                            <TimelineConnector />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>
                          <h6>
                            <span className="typeHeading">
                              {timelineEventTypes[data?.event]}
                            </span>{" "}
                            <span className="type">
                              {timelineEventTypeDescription[data?.event]}
                            </span>{" "}
                            <span className="caller_name">{createdbyName}</span>
                            <span className="type">
                              {" on "} {getFormatDate24Hrs(data?.createdOn)}
                            </span>
                          </h6>
                          {index !== 0 && ![2, 8].includes(data?.event) && (
                            <Accordion
                              expanded={expanded[index]}
                              onChange={() => handleChange(index)}
                              className="timeline_accordion"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                className="heading view-content-heading"
                              >
                                <p className="view-contents">View contents</p>
                              </AccordionSummary>
                              <AccordionDetails>
                                {data?.elements?.map((element, index) => {
                                  let templateComponent = null;

                                  if (
                                    element?.elementType === 2 ||
                                    element?.elementType === 4
                                  ) {
                                    templateComponent = (
                                      <NMSEmailTemplate
                                        key={index}
                                        element={element}
                                      />
                                    );
                                  } else if (element?.elementType === 3) {
                                    templateComponent = (
                                      <NMSSignatureTemplate
                                        key={index}
                                        element={element}
                                        date={getFormatDateOnly(
                                          data?.createdOn
                                        )}
                                      />
                                    );
                                  } else if (element?.elementType === 9) {
                                    templateComponent = (
                                      <NMSPhoneCall
                                        key={index}
                                        element={element}
                                        createdbyName={createdbyName}
                                        isExpanded={
                                          timelineEventTypes[data?.event] ===
                                          stageName
                                            ? true
                                            : false
                                        }
                                      />
                                    );
                                  } else if (element?.elementType === 5) {
                                    templateComponent = (
                                      <Waveform
                                        key={index}
                                        element={element?.value}
                                        darkMode={darkMode}
                                      />
                                    );
                                  } else if (element?.elementType === 7) {
                                    templateComponent = (
                                      <NMSNoteTemplate
                                        key={index}
                                        createdbyName={createdbyName}
                                        element={element}
                                        isExpanded={
                                          timelineEventTypes[data?.event] ===
                                          stageName
                                            ? true
                                            : false
                                        }
                                      />
                                    );
                                  } else if (element?.elementType === 10) {
                                    templateComponent = (
                                      <NMSFollowupTemplate
                                        key={index}
                                        createdbyName={createdbyName}
                                        element={element}
                                        isExpanded={
                                          timelineEventTypes[data?.event] ===
                                          stageName
                                            ? true
                                            : false
                                        }
                                      />
                                    );
                                  }

                                  return templateComponent;
                                })}
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </TimelineContent>
                      </TimelineItem>
                    );
                })}
              {extractedNotesComments.length > 0 && (
                <NMSGroupedNotes
                  extractedNotesComments={extractedNotesComments}
                />
              )}
            </Timeline>
          </div>
        );
      })}
      {/* <div className="timeline_action_box">
          <div className="buttons">
            <button className="timeline_btn">
              <p>PDF</p>
              <FaFilePdf color="#fff" />
            </button>
            <button className="timeline_btn">
              <p>MP3</p>
              <IoMdVolumeHigh color="#fff" />
            </button>
            <button className="timeline_btn">
              <p>Export</p>
              <FaDownload color="#fff" />
            </button>
          </div>
        </div> */}
      {/* <div className="description_container">
          <textarea
            placeholder="General notes..."
            value={notes}
            onChange={handleNotesChange}
          ></textarea>
          <div className="notes_save_btn">
            <CustomButton label="Save" action={handleSaveNotes} />
          </div>
        </div> */}
    </div>
  );
}

export default NmsTimeLine;
