import { dispatch, getDataFromStore } from "../store/store";
import { logError } from "../services/logService";
import {
  setIsMemberLoading,
  setMembersData,
  setRolesData,
  setServicesData,
  getMemberData,
  getServicesData,
  addMemberData,
  updateMemberData,
  getMemberByIdData,
  setCurrentMemberData,
  getOrbitAgencyServicesByCompanyIdData,
} from "../features/memberSlice/memberSlice";

const reducerName = "member";

// #region State Setters

export const setMembers = (memberData) => {
  dispatch(setMembersData(memberData));
};
export const setRoles = (roleData) => {
  dispatch(setRolesData(roleData));
};
export const setServices = (servicesData) => {
  dispatch(setServicesData(servicesData));
};
export const setMemberLoading = (isLoading) => {
  dispatch(setIsMemberLoading(isLoading));
};
export const setCurrentMember = (memberObject) => {
  dispatch(setCurrentMemberData(memberObject));
};
// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const getmembers = async (companyId) => {
  let result = [];
  try {
    result = await dispatch(getMemberData(companyId)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const getServices = async () => {
  let result = [];
  try {
    result = await dispatch(getServicesData()).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};

export const getMemberById = async (memberId) => {
  let result = null;

  try {
    result = await dispatch(getMemberByIdData(memberId)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const addMember = async (data) => {
  let result = null;

  try {
    result = await dispatch(addMemberData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const updateMember = async (data) => {
  let result = null;

  try {
    result = await dispatch(updateMemberData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getOrbitAgencyServicesByCompanyId = async (companyID) => {
  let result = null;
  try {
    result = await dispatch(
      getOrbitAgencyServicesByCompanyIdData(companyID)
    ).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

// #endregion
