import React from "react";
import { MdAdd, MdOutlineModeEditOutline } from "react-icons/md";
import useTitanType from "../../../../../../../hooks/useTitanType";
import { useNavigate } from "react-router";
import { LiaArrowRightSolid } from "react-icons/lia";
import useTitanApp from "../../../../../../../hooks/useTitanApp";
import { getClinicalServiceBuilderResponse } from "../../../../../../../dispatchers/typeDispatchers";
import { S } from "@sendbird/uikit-react/index-56249831";
import { setServiceTab } from "../../../../../../../dispatchers/pharmacyServiceDispatchers";
import { checkUserPermissions } from "../../../../../../../utils/checkUserPermissions";
import { accessAddService } from "../../../../../../../data/constantStrings";

export default function ServiceTypesList({ setServiceTabs }) {
  const { appState } = useTitanApp();
  const { typeState } = useTitanType();
  const { darkMode } = appState;
  const { typeServices } = typeState || {};
  const navigate = useNavigate();
  const nhsservices = typeServices.filter(
    (service) => service.serviceType === 1
  );
  const pvtservices = typeServices.filter(
    (service) => service.serviceType === 2
  );
  const { companyId } = appState;
  const handleAddNewServiceType = () => {
    navigate("/settings/services/types/new");
  };

  const handleEditServiceType = (typeObj) => {
    navigate(`/settings/services/types/${typeObj?._id}`);
  };

  const onServiceClick = (serviceObj) => {
    if (serviceObj.serviceType === 1) {
      return;
    } else {
      getClinicalServiceBuilderResponse(serviceObj._id, companyId).then(
        (res) => {
          if (res) {
            setServiceTabs(true);
          } else {
          }
        }
      );
    }
  };

  return (
    <>
      <div className="settings_heading">
        <h1>My Services</h1>
      </div>

      <div className="servicetypes_content_wrapper">
        <div className="servicetype_listbox">
          <div>
            <div className="allView_list_header">
              <h3>NHS</h3>
            </div>
          </div>
          <div className="allView_listview">
            {nhsservices?.map((service, index) => (
              <div
                key={index}
                className="allView_container"
                onClick={() => onServiceClick(service)}
              >
                <div className="allView_title">
                  <p className="allView_name">{service.serviceName}</p>
                </div>
                {service.serviceType === 2 && (
                  <LiaArrowRightSolid color={darkMode ? "#fff" : "#0a0a0a"} />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="servicetypes_content_wrapper">
        <div className="servicetype_listbox">
          <div>
            <div className="allView_list_header">
              <h3>Private</h3> {/* Updated the header to differentiate */}
            </div>
          </div>
          <div className="allView_listview">
            {pvtservices?.map((service, index) => (
              checkUserPermissions(accessAddService) ? (
                <div
                  key={index}
                  className="allView_container"
                  onClick={() => onServiceClick(service)}
                >
                  <div className="allView_title">
                    <p className="allView_name">{service.serviceName}</p>
                  </div>
                  <LiaArrowRightSolid color={darkMode ? "#fff" : "#0a0a0a"} />
                </div>
              ) : (
                <div
                  key={index}
                  className="allView_container"
                >
                  <div className="allView_title">
                    <p className="allView_name">{service.serviceName}</p>
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
