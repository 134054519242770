import stages from "./stages";

class setAddTemplate {
  constructor(response, stageNo, newTemplate, userFullName) {
    this._id = response._id;
    this.patientId = response.patientId;
    this.patientName = response.patientName;
    this.serviceName = response.serviceName;
    this.stages = new stages(
      response.stages,
      stageNo,
      newTemplate,
      userFullName
    ).obj;
    this.numberOfStages = response.numberOfStages;
    this.serviceType = response.serviceType;
    this.clinicalServiceStatus = response.clinicalServiceStatus;
    this.clinicalServiceDate = response.clinicalServiceDate;
    this.pharmacyID = response.pharmacyID;
    this.serviceCode = response.serviceCode;
    this.prescriptionID = response.prescriptionID;
    this.serviceID = response.serviceID;
    this.prescriptionIncomingId = response.prescriptionIncomingId;
    this.sendSms = response.sendSms;
    this.telephoneNumber = response.telephoneNumber;
    this.patientTags =
      response.patientTags === null ? [] : response.patientTags;
  }
}

export default setAddTemplate;
