import React from "react";
import { Tabs, Tab } from "@mui/material";
import ProductTypesTab from "./components/ProductTypesTab/ProductTypesTab";
import OnlineBookingTypesTab from "./components/OnlineBookingTypesTab/OnlineBookingTypesTab";
import useTitanApp from "../../../../../../../hooks/useTitanApp";
import { LiaArrowLeftSolid } from "react-icons/lia";
import useTitanType from "../../../../../../../hooks/useTitanType";
import StageContent from "./StageContent";
import CustomButton from "../../../../../../controls/CustomButton"; // Imported CustomButton
import { updateClinicalServiceBuilder } from "../../../../../../../dispatchers/typeDispatchers";
import { useNavigate, useParams } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default function ServiceTypesTab({ setServiceTabs }) {
  const navigate = useNavigate();
  const { appState } = useTitanApp();
  const { darkMode, companyId } = appState;
  const [value, setValue] = React.useState(0);
  const { typeState } = useTitanType();
  const {
    clinicalServiceBuilderModel,
    defaultClinicalServiceBuilderModel,
    isTemplateVerified,
    isTypeLoading,
  } = typeState || {};
  const serviceBuilder =
    defaultClinicalServiceBuilderModel?.clinicalServiceBuilder;
  // clinicalServiceBuilderResponse?.clinicalServiceBuilder ||
  // clinicalServiceBuilderResponse?.clinicalServiceBuilderDefault;
  const templates = defaultClinicalServiceBuilderModel?.templates || [];
  const stages = serviceBuilder?.stages || [];

  const tabs = [
    { label: "Product", component: <ProductTypesTab /> },
    { label: "Online Booking", component: <OnlineBookingTypesTab /> },
    ...stages.map((stage, stageIndex) => ({
      label: stage.stageName,
      component: (
        <StageContent
          stage={stage}
          stageIndex={stageIndex}
          templates={templates}
        />
      ),
      props: a11yProps(stageIndex + 2),
    })),
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = () => {
    const filteredClinicalServiceBuilder = {
      ...clinicalServiceBuilderModel.clinicalServiceBuilder,
      companyID: companyId,
      stages: clinicalServiceBuilderModel.clinicalServiceBuilder?.stages?.map(
        (stage, index) => {
          return {
            ...stage,
          };
        }
      ),
    };

    const filteredTemplates = clinicalServiceBuilderModel.templates.map(
      (template, index) => {
        return {
          ...template,
          companyID: companyId,
          isDefault: false,

          templateSections: template.templateSections.map((section) => ({
            ...section,
            userControls: section.userControls.filter(
              (control) => control.value === "true"
            ),
          })),
        };
      }
    );

    const filteredModel = {
      clinicalServiceBuilder: filteredClinicalServiceBuilder,
      templates: filteredTemplates,
    };

    // console.log("Submitting filtered model:", filteredModel);

    updateClinicalServiceBuilder(filteredModel);
    setServiceTabs(false);
  };

  return (
    isTypeLoading === false && (
      <>
        <div className="settings_heading">
          <h1>{serviceBuilder?.serviceName}</h1>
        </div>
        <div className="tabs_box servicetypes_tabs_box">
          <div className="d-flex">
            <LiaArrowLeftSolid
              color={darkMode ? "#fff" : "#0a0a0a"}
              onClick={() => setServiceTabs(false)}
            />
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="service types tabs"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={<span className={`tabs_text`}>{tab.label}</span>}
                  {...(tab.props || {})}
                />
              ))}
            </Tabs>
          </div>

          {tabs.map((tab, index) => (
            <TabPanel value={value} index={index} key={index}>
              {tab.component}
            </TabPanel>
          ))}
        </div>
        <div className="service-create-btn">
          <CustomButton
            isdisable={!isTemplateVerified}
            action={handleSubmit} // Bind the updated handleSubmit function here
            label={"Submit"}
          />
        </div>
        {/* Footer Section with CustomButton */}
      </>
    )
  );
}
