import React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useTitanApp from "../../hooks/useTitanApp";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  height: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px'
};

const AddPatientModal = ({ show, handleClose }) => {
  const { appState } = useTitanApp();
  const { currentPharmacyId } = appState || {};

  return (
    <Modal open={show} onClose={handleClose}>
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            margin: -1
          }}
        >
          <CloseIcon />
        </IconButton>
        <iframe
          src={`http://localhost:3001/book-now/${currentPharmacyId}`}
          title="Website"
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        ></iframe>
      </Box>
    </Modal>
  );
};

export default AddPatientModal;
