class template {
  constructor(response, stageNo, current, newTemplate) {
    this._id = response._id;
    this.templateName = response.templateName;
    this.templateSections =
      stageNo === current ? newTemplate : response.templateSections;
  }
}

export default template;
