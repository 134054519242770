import React, { useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import CustomLabel from "../../../../../components/controls/CustomLabel";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { ReactComponent as PositiveCheck } from "../../../../../assets/images/positiveTick.svg";
import {
  updateTemplateControlValue,
  updateControlCheckedState,
} from "../../../../../dispatchers/pharmacyServiceDispatchers";

// Styled component outside the main function to avoid recreation on every render
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "& .MuiSwitch-thumb": {
    width: 15,
    backgroundColor: "yellow",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    transform: "translateX(9px)",
    "& .MuiSwitch-thumb": {
      backgroundColor: "white",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      backgroundColor: "grey",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 8,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,0.05)",
    boxSizing: "border-box",
  },
}));

// Small component to render individual controls
const UserControl = React.memo(({ control, onToggleChange, checked }) => (
  <div className={control.inline ? "inline-not" : "inline"}>
    <div
      className="switch-content-reports"
      style={{ border: checked ? "1px solid #00A3DA" : "none" }}
    >
      <CustomLabel name={control.label} />
      <div>
        <AntSwitch
          disabled={false}
          inputProps={{ "aria-label": control.label }}
          checked={checked}
          onChange={onToggleChange}
        />
      </div>
    </div>
  </div>
));

export const TemplateSection = ({
  section,
  sectionIndex,
  templateIndex,
  onToggleChange,
}) => {
  const sectionIsValid = section.userControls.some((control) => control.value);

  return (
    <div
      className="cus-title-section"
      style={{
        border:
          section.isRequired && !sectionIsValid ? "1px solid red" : "none",
        borderRadius: section.isRequired ? "10px" : "none",
      }}
    >
      <p className="cus-template-title">
        {section.sectionTitle} {sectionIsValid && <PositiveCheck />}
      </p>
      {section.userControls.map((control, controlIndex) => (
        <UserControl
          key={controlIndex}
          control={control}
          checked={control.value}
          onToggleChange={onToggleChange(
            templateIndex,
            sectionIndex,
            controlIndex
          )}
        />
      ))}
    </div>
  );
};

function CustomTemplates() {
  const { pharmacyServiceState } = useTitanApp();
  const { customTemplateModel, serviceDetailTemplates } = pharmacyServiceState;

  const handleToggleChange = useCallback(
    (templateIndex, sectionIndex, controlIndex) => (event) => {
      const value = event.target.checked;
      updateTemplateControlValue(
        templateIndex,
        sectionIndex,
        controlIndex,
        value
      );
    },
    []
  );

  useEffect(() => {
    const areRequiredSectionsValid = customTemplateModel.templates.every(
      (template) =>
        template.templateSections.every((section) =>
          section.isRequired
            ? section.userControls.some((control) => control.value)
            : true
        )
    );
    updateControlCheckedState(areRequiredSectionsValid);
  }, [customTemplateModel]);

  return (
    <div className="templatesMain">
      <div className="accordion-boxes">
        {serviceDetailTemplates &&
          customTemplateModel.templates.map((template, templateIndex) => (
            <div key={templateIndex} className="cus-template-section">
              <p className="cus-template-name">{template.templateName}</p>
              {template.templateSections &&
                template.templateSections.map((section, sectionIndex) => (
                  <TemplateSection
                    key={sectionIndex}
                    section={section}
                    sectionIndex={sectionIndex}
                    templateIndex={templateIndex}
                    onToggleChange={handleToggleChange}
                  />
                ))}
            </div>
          ))}
      </div>
    </div>
  );
}

export default CustomTemplates;
