import React from "react";
import useTitanApp from "../../../../../hooks/useTitanApp";

function ServiceDetails() {
  const { pharmacyServiceState } = useTitanApp();
  const { serviceDetails } = pharmacyServiceState;

  return (
    <div className="mainServiceDetails">
      <div className="serviceStages">
        <div className="serviceStageHeading">Stages</div>
        <div className="serviceStageContent">
          {/* Render stages dynamically from serviceDetails */}
          {serviceDetails?.stages?.length > 0 ? (
            serviceDetails.stages.map((stage, index) => (
              <span key={index}>
                {stage.stageName}
                {index < serviceDetails.stages.length - 1 && " --› "}
              </span>
            ))
          ) : (
            <span>No stages available</span>
          )}
        </div>
      </div>
      <div className="serviceDateStatus">
        <div className="serviceDateStatusHeading">Online</div>
        <div className="serviceDateStatusContent">28/10/2022</div>
      </div>
    </div>
  );
}

export default ServiceDetails;
