import React, { useState, useEffect } from "react";
import "./../../../../../../assets/css/Dashboard/ServicesAnalytics.scss";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import useTitanApp from "../../../../../../hooks/useTitanApp";
import {
  getServiceAnalytics,
  setCurrentCalendarStartDate,
  setCurrentCalendarEndDate,
  getAnalyticsNMSProcessed,
  getAnalyticsServicesEarnings,
  getAnalyticsServicesUsers,
  getAnalyticsServicesStages,
  getAnalyticsServicesCompletedClaimed,
  getAnalyticsServicesCompletedToBeClaimed,
  getAnalyticsServicesDeclined,
} from "../../../../../../dispatchers/pharmacyServiceDispatchers";
import { Skeleton, Button, TextField, IconButton } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LicenseInfo } from "@mui/x-license";
import config from "../../../../../../config";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import Calendar from "@mui/icons-material/Event";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const WrappedSingleInputDateRangeField = React.forwardRef((props, ref) => {
  return <SingleInputDateRangeField size="small" {...props} ref={ref} />;
});
WrappedSingleInputDateRangeField.fieldType = "single-input";

const { muiKey } = config;
LicenseInfo.setLicenseKey(
  "07800020c9fa4cdb1270b48fb1cdb0e9Tz05NzI2NSxFPTE3NTY2NTczNzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=="
);

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export const getTimeLessDate = (paramDate) => {
  if (typeof paramDate === "string" && paramDate.indexOf("T") >= 0) {
    const [dateOnly] = paramDate.split("T");
    return new Date(dateOnly + "T00:00:00");
  }
  return new Date(Date.now());
};

function DateRange() {
  const { appState, pharmacyServiceState } = useTitanApp();
  const { currentPharmacyId, darkMode } = appState || {};
  const {
    currentServiceId,
    calendarStartDate,
    calendarEndDate,
    isPharmacyServiceProcessedLoading,
  } = pharmacyServiceState || {};
  const [dates, setDates] = useState([
    getTimeLessDate(calendarStartDate),
    getTimeLessDate(calendarEndDate),
  ]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const handleCalendarChange = async (event) => {
    const [startDate, endDate] = event;
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);

    if (event && startDate && endDate) {
      if (startDate !== null && endDate !== null) {
        setDates([event[0], event[1]]);
        setIsCalendarOpen(false);
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Set start and end dates to midnight UTC to avoid timezone shifts
        const localISOStartTime = new Date(Date.UTC(
          start.getFullYear(),
          start.getMonth(),
          start.getDate(),
          0, 0, 0, 0
        ));

        const localISOEndTime = new Date(Date.UTC(
          end.getFullYear(),
          end.getMonth(),
          end.getDate(),
          0, 0, 0, 0
        ));

        setCurrentCalendarStartDate(localISOStartTime);
        setCurrentCalendarEndDate(localISOEndTime);

        // Trigger analytics functions
        getAnalyticsNMSProcessed(currentPharmacyId, currentServiceId);
        getAnalyticsServicesEarnings(currentPharmacyId, currentServiceId);
        getAnalyticsServicesUsers(currentPharmacyId, currentServiceId);
        getAnalyticsServicesStages(currentPharmacyId, currentServiceId);
        getAnalyticsServicesCompletedClaimed(currentPharmacyId, currentServiceId);
        getAnalyticsServicesCompletedToBeClaimed(currentPharmacyId, currentServiceId);
        getAnalyticsServicesDeclined(currentPharmacyId, currentServiceId);
      }
    }
  };

  const handleCalendarIconClick = () => {
    setIsCalendarOpen(true);
    setDates([null, null]);
  };

  const handleCloseCalendarClick = () => {
    setIsCalendarOpen(false);
    setDates([
      getTimeLessDate(calendarStartDate),
      getTimeLessDate(calendarEndDate),
    ]);
  };

  return (
    <div className="date-range-picker-container">
      <div className="date-range-picker analytics-tab-date-picker">
        {isPharmacyServiceProcessedLoading ? (
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.900" : "" }}
            height={30}
            width={235}
            animation="wave"
            variant="rounded"
          />
        ) : (
          <ThemeProvider theme={darkMode ? darkTheme : ""}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              {!isCalendarOpen && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    value={
                      dates[0] && dates[1]
                        ? `${dayjs(dates[0]).format("DD/MM/YYYY")} - ${dayjs(
                          dates[1]
                        ).format("DD/MM/YYYY")}`
                        : "DD/MM/YYYY - DD/MM/YYYY"
                    }
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <IconButton onClick={handleCalendarIconClick}>
                          <Calendar />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              )}
              {isCalendarOpen && (
                <DemoContainer components={["SingleInputDateRangeField"]}>
                  <DateRangePicker
                    open={true}
                    onClose={handleCloseCalendarClick}
                    slots={{ field: WrappedSingleInputDateRangeField }}
                    name="allowedRange"
                    value={[dayjs(dates[0]), dayjs(dates[1])]}
                    onChange={handleCalendarChange}
                    inputFormat="DD/MM/YYYY"
                    slotProps={{
                      textField: { InputProps: { endAdornment: <Calendar /> } },
                    }}
                  />
                </DemoContainer>
              )}
            </LocalizationProvider>
          </ThemeProvider>
        )}
      </div>
    </div>
  );
}

export default DateRange;
