import { getObject } from "../getObjectValue";
import stages from "./stages";
import metaDataList from "./metaDataList";
class setServiceNms {
  constructor(
    response,
    stageNo,
    title,
    userControls,
    isCompleted,
    clincalServiceType,
    Id,
    patientName,
    declined,
    startDate,
    userFullName,
    groupId,
    userEmail
  ) {
    this._id = response._id ? response._id : "";
    this.patientId =
      Id === null ? (response.patientId ? response.patientId : "") : Id;
    this.patientName =
      patientName === null
        ? response.patientName
          ? response.patientName
          : ""
        : patientName;
    this.serviceName = response.serviceName ? response.serviceName : "";
    this.stages = response.stages
      ? new stages(
          response.stages,
          stageNo,
          title,
          userControls,
          isCompleted,
          startDate,
          userFullName,
          groupId,
          userEmail
        ).obj
      : [];
    this.numberOfStages = response.numberOfStages
      ? response.numberOfStages
      : "";
    this.serviceType = response.serviceType ? response.serviceType : 1;
    this.clinicalServiceStatus =
      clincalServiceType === 2
        ? stageNo === 1
          ? 2
          : 1
        : stageNo === 0 || stageNo === 1
        ? declined === 3
          ? declined
          : 1
        : stageNo === 2
        ? declined === 3 || declined === 2
          ? declined
          : 1
        : 2;
    this.clinicalServiceDate = response.clinicalServiceDate;
    this.pharmacyID = response.pharmacyID;
    this.serviceCode = response.serviceCode;
    this.prescriptionID = response.prescriptionID;
    this.serviceID = response.serviceID;
    this.prescriptionIncomingId = response.prescriptionIncomingId;
    this.sendSms = response.sendSms;
    this.sendToOrbit = response.sendToOrbit;
    this.telephoneNumber = response.telephoneNumber;
    this.patientTags =
      response.patientTags === null ? [] : response.patientTags;
    this.serviceMedications =
      response.serviceMedications === null ? [] : response.serviceMedications;
    this.dob = response.dob;
    this.isTest = response.isTest;
    this.isAutoConsent = response.isAutoConsent;
  }
}

export default setServiceNms;
