import { dispatch, getDataFromStore } from "../store/store";
import {
  addRoleData,
  getPermissionsData,
  getTeamsRoleByIdData,
  getTeamRolesAllData,
  getRolesData,
  setIsTeamLoading,
  setCurrentRoleData,
  updateRoleData,
  inviteTeamMemberData,
  addTeamData,
  updateTeamData,
  getTeamsData,
  getUserImagesData,
  removeTeamMemberData,
  setAddTeamModalData,
  setAddTeamTitleData,
  setAddTeamImageData,
  setAddTeamPharmacyNameData,
  setTeamFormModeData,
  changeMemberRoleData,
} from "../features/teamSlice/teamSlice";
import { logError } from "../services/logService";

const reducerName = "team";

// #region State Setters
export const setTeamLoading = (isLoading) => {
  dispatch(setIsTeamLoading(isLoading));
};

export const setCurrentRole = (roleObject) => {
  dispatch(setCurrentRoleData(roleObject));
};
export const setAddTeamModal = (modal) => {
  dispatch(setAddTeamModalData(modal));
};
export const setAddTeamTitle = (title) => {
  dispatch(setAddTeamTitleData(title));
};
export const setAddTeamImage = (image) => {
  dispatch(setAddTeamImageData(image));
};
export const setAddTeamPharmacyName = (pharmacyName) => {
  dispatch(setAddTeamPharmacyNameData(pharmacyName));
};
export const setTeamFormMode = (formMode) => {
  dispatch(setTeamFormModeData(formMode));
};
// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const getTeamsRoles = async (companyId) => {
  let result = null;

  try {
    result = await dispatch(getTeamRolesAllData(companyId)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getRoles = async (roleType) => {
  let result = null;
  try {
    result = await dispatch(getRolesData(roleType)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getTeams = async () => {
  let result = null;

  try {
    result = await dispatch(getTeamsData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getUserImages = async (teamId) => {
  let result = null;

  try {
    result = await dispatch(getUserImagesData(teamId)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const addRole = async (data) => {
  let result = null;

  try {
    result = await dispatch(addRoleData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const inviteTeamMember = async (data, teamId) => {
  let result = null;

  try {
    result = await dispatch(inviteTeamMemberData({ data, teamId })).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const addTeam = async (data) => {
  let result = null;

  try {
    result = await dispatch(addTeamData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const updateTeam = async (data) => {
  let result = null;

  try {
    result = await dispatch(updateTeamData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const getTeamsRoleById = async (roleId) => {
  let result = null;

  try {
    result = await dispatch(getTeamsRoleByIdData(roleId)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const removeTeamMember = async (params) => {
  let result = null;

  try {
    result = await dispatch(removeTeamMemberData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const changeMemberRole = async (params) => {
  let result = null;
  try {
    result = await dispatch(changeMemberRoleData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getPermissions = async () => {
  let result = null;

  try {
    result = await dispatch(getPermissionsData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};

export const updateRole = async (data) => {
  let result = null;

  try {
    result = await dispatch(updateRoleData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }

  return result;
};
// #endregion
