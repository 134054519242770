import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import useTitanApp from "../hooks/useTitanApp";
import { IoMdPerson, IoMdLogOut } from "react-icons/io";
import { handleUpdateSession } from "../data/handleUpdateSession";
import useTimer from "../hooks/useTimer";
import { setIsNewClinicComponent } from "../dispatchers/pharmacyServiceDispatchers";
import config from "../config";
import userAvatar from "./../assets/images/userAvatar.png";
export default function ProfileButton() {
  const { auth, signout, appState } = useTitanApp();
  const { user } = auth || {};
  const { isOrbitUser } = appState || {};
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfileClick = () => {
    navigate(`/orbitsettings/professionaldetails`);
    setIsNewClinicComponent(false);
  };
  const logout = async () => {
    if (isOrbitUser) {
      var result = await handleUpdateSession();
      if (result === true) {
        handleReset();
        signout();
      }
    } else {
      signout();
    }
  };
  const { handleReset } = useTimer();

  return (
    <div>
      <div className="header-icons">
        <div className="icon-only label-btn" onClick={handleClick}>
          <img src={user?.picture} className="profile_image" />
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem className="profile_menu_item" onClick={handleProfileClick}>
          <IoMdPerson className="icons-color" /> <span>Profile</span>
        </MenuItem>

        <MenuItem className="profile_menu_item" onClick={logout}>
          <IoMdLogOut className="icons-color flip-horizontal" />{" "}
          <span>Logout</span>
        </MenuItem>
      </Menu>
    </div>
  );
}
