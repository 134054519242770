import React from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import { MdAdd, MdOutlineModeEditOutline, MdArrowForward } from "react-icons/md";
import SettingsSidebar from "../../SettingsSidebar/SettingsSidebar";
import AsyncRendererRouter from "../../AsyncRendererRouter/AsyncRendererRouter";
import RolesLoadingComponent from "../../SettingsLoadingComponents/RolesLoadingComponent";
import useTitanTemplate from "../../../hooks/useTitanTemplate";
import { setTemplateMessageType, setUpdateMessagetemplate } from "../../../dispatchers/templateDispatchers";
import { checkUserPermissions } from "../../../utils/checkUserPermissions";
import { accessEmailTemplate } from "../../../data/constantStrings";

export default function EmailAndSms() {
  const { templateState } = useTitanTemplate();
  const { emailsAndSmsTemplates, isTemplatesLoading } = templateState || {};
  const navigate = useNavigate();

  const handleAddNewTemplate = (messageType) => {
    setTemplateMessageType(messageType);
    navigate("/settings/communication/messagetemplate/new");
  };

  const handleEditTemplate = (value, message) => {
    setTemplateMessageType(message?.messageType);
    setUpdateMessagetemplate(message);
    navigate("/settings/communication/messagetemplate/update");
  };

  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      resolve={appData}
      isLoading={isTemplatesLoading}
      loadingElement={<RolesLoadingComponent />}
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar />
          <div className="settings_content_wrapper communication_wrapper">
            <div className="settings_heading template_heading">
              <h1>SMS & Email</h1>
            </div>
            <div className={"settings_content_area roles_wrapper email_sms_wrapper"}>
              <div className="allRoles_wrapper allEmails_wrapper">
                <div className="allView_list_header">
                  <h3>Email Template</h3>
                  {checkUserPermissions(accessEmailTemplate) && (
                    <div className="addNew_cta" onClick={() => handleAddNewTemplate(0)}>
                      <span>Add Template</span>
                      <MdAdd />
                    </div>
                  )}
                </div>
                <div className="allView_listview">
                  {emailsAndSmsTemplates?.length > 0 ? (
                    emailsAndSmsTemplates
                      .filter((email) => email?.messageType === 0)
                      .map((email) => (
                        <div
                          className="allView_container"
                          key={email._id}
                          onClick={checkUserPermissions(accessEmailTemplate) ? () => handleEditTemplate(true, email) : null}
                        >
                          <div className="allView_title">
                            <p className="allView_name">{email?.templateName}</p>
                          </div>
                          {checkUserPermissions(accessEmailTemplate) && (
                            <MdArrowForward
                              size={20}
                              className="icons-color member_edit_icon"
                            />
                          )}
                        </div>
                      ))
                  ) : (
                    <p>No Templates Found</p>
                  )}
                </div>

              </div>
              <div className="allRoles_wrapper allEmails_wrapper">
                <div className="allView_list_header">
                  <h3>SMS Template</h3>
                  {checkUserPermissions(accessEmailTemplate) && (
                    <div className="addNew_cta" onClick={() => handleAddNewTemplate(1)}>
                      <span>Add Template</span>
                      <MdAdd />
                    </div>
                  )}
                </div>
                <div className="allView_listview">
                  {emailsAndSmsTemplates?.length > 0 ? (
                    emailsAndSmsTemplates
                      .filter((email) => email?.messageType === 1)
                      .map((email) => (
                        <div
                          className="allView_container"
                          key={email._id}
                          onClick={checkUserPermissions(accessEmailTemplate) ? () => handleEditTemplate(true, email) : null}
                        >
                          <div className="allView_title">
                            <p className="allView_name">{email?.templateName}</p>
                          </div>
                          {checkUserPermissions(accessEmailTemplate) && (
                            <MdArrowForward
                              size={20}
                              className="icons-color member_edit_icon"
                            />
                          )}
                        </div>
                      ))
                  ) : (
                    <p>No Templates Found</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}
