import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import useTitanType from "../../../../../../../../../../hooks/useTitanType";
import { setReminderDetails } from "../../../../../../../../../../dispatchers/typeDispatchers";

export default function ProductTypesReminders() {
  const { typeState } = useTitanType();
  const { clinicalServiceBuilderModel, defaultClinicalServiceBuilderModel, messageTemplates } =
    typeState;

  const productDetails =
    clinicalServiceBuilderModel?.clinicalServiceBuilder?.product ??
    defaultClinicalServiceBuilderModel?.clinicalServiceBuilder?.product ??
    {};

  const { reminders } = productDetails;
  const { emailReminder, smsReminder } = reminders;
  const [reminderEmail, setReminderEmail] = useState(emailReminder ?? "");
  const [reminderSMS, setReminderSMS] = useState(smsReminder ?? "");

  const smsReminderTemplates =
    messageTemplates?.filter((template) => template?.actionType === 5) ?? [];
  const emailReminderTemplates =
    messageTemplates?.filter((template) => template?.actionType === 4) ?? [];

  const handleChange = (setter, field) => (event) => {
    const value = event.target.value;
    setter(value);
    const payload = {
      smsReminder: field === "smsReminder" ? value : reminderSMS,
      emailReminder: field === "emailReminder" ? value : reminderEmail,
    };
    setReminderDetails(payload);
  };

  return (
    <div className="privateservices_two_layout">
      <div className="d-flex">
        <div className="membersAddNew_box">
          <FormControl fullWidth>
            <InputLabel id="email-confirmation-label">
              Email reminder
            </InputLabel>
            <Select
              labelId="email-confirmation-label"
              id="email-confirmation-select"
              value={reminderEmail}
              onChange={handleChange(setReminderEmail, "emailReminder")}
              className="role-select"
            >
              {emailReminderTemplates?.map((template) => (
                <MenuItem key={template?._id} value={template?.templateName}>
                  {template?.templateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="membersAddNew_box">
          <FormControl fullWidth>
            <InputLabel id="email-cancellation-label">
              Sms reminder
            </InputLabel>
            <Select
              labelId="email-cancellation-label"
              id="email-cancellation-select"
              value={reminderSMS}
              onChange={handleChange(setReminderSMS, "smsReminder")}
              className="role-select"
            >
              {smsReminderTemplates?.map((template) => (
                <MenuItem key={template?._id} value={template?.templateName}>
                  {template?.templateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
