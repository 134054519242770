import React from "react";
import OnlineBookingTypesShareEmbed from "./components/OnlineBookingTypesShareEmbed";
import OnlineBookingTypesGeneral from "./components/OnlineBookingTypesGeneral";

export default function OnlineBookingTypesTab() {
  return (
    <div className="addMember_formFields privateservices_formFields">
      <div className="inner-panels">
        <div className="AddNew_sectionbox">
          <h4>Share and embed</h4>
          <p className="description">
            Use the links below to add to your email templates or to your
            existing website. The embedded iframe is a great way to embed the
            booking form directly into your website
          </p>
          <OnlineBookingTypesShareEmbed />
        </div>
      </div>
      <div className="inner-panels">
        <div className="AddNew_sectionbox">
          <h4>General</h4>
          <OnlineBookingTypesGeneral />
        </div>
      </div>
    </div>
  );
}
