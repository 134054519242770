import React, { useEffect, useState } from "react";
import "./../../assets/css/PrescribedMedicines.css";
import { RiMedicineBottleLine } from "react-icons/ri";
import editIcon from "./editIcon.svg";
import chatIcon from "./chaticon.svg";
import checkIcon from "./checkIcon.svg";
import starIcon from "./starIcon.svg";
import EndorsementModal from "../EndorsementModal/EndorsementModal";
import CustomSwitch from "../controls/CustomSwitch";
import CustomLabel from "../controls/CustomLabel";
import CoreAcoordion from "./CoreAccordion";
import ScheduleAccordion from "./ScheduleAccordion";
import ReportsAccordion from "./ReportsAccordion";
import MdsAccordion from "./MdsAccordion";
import CustomDropdown from "../controls/CustomDropdown";
import GroupMedicineWarning from "../GroupMedicineWarning";
import MedicineClinicalWarning from "../MedicineClinicalWarning";
import setCheckNow from "../../Model/CheckNow/setCheckNow";
import NotesControl from "../NotesControl/NotesControl";
import { dispenseItemsArray } from "../../enum/dispenseDropdown";
import useTitanApp from "../../hooks/useTitanApp";
import setPrescriptionItemChecks from "../../Model/PrescriptionItemChecks/setPrescriptionItemChecks";
import {
  setCheckNowModel,
  setItemChecks,
  setTheraphy,
  setItemReturnDose,
  setItemReturnQuantity,
  setReturnQuantity,
  setReturnDose,
  setReturnDispense,
  setDispense,
  setEditable,
  setButtonclass,
  setMedicationTime,
  setReturnArchive,
  setReturnColor,
  setReturnTherapy,
  setReturnMarking,
  setReturnPreparation,
  setReturnReport,
  getPrescriptionItemCheck,
  getAdditionalEndorsement,
  setNotes,
} from "../../dispatchers/clinicalChecksDispatchers";
import NmsPrescriptionToolTip from "../NmsPrescriptionToolTip/NmsPrescriptionToolTip";
function PrescribedMedicines(props) {
  const { appState, clincicalChecksState } = useTitanApp();

  const { isAppLoading } = appState || {};
  const {
    prescriptions,
    clinicalChecksPharmacy,
    prescriptionItemCheck,
    checkNowModel,
    dispenseArray,
    isTherapy,
    therapyComponents,
    defaultDosageTimings,
    postMedicationId,
    itemReturnDose,
    itemReturnQuantity,
    prescriptionChecks,
    returnDispense,
    buttonClass,
    isEditable,
    isReturnDispense,
    notesModel,
    medicationId,
    prescriptionCheckGroups,
  } = clincicalChecksState || {};
  const { pharmacyID } = clinicalChecksPharmacy || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };

  const {
    itemInfo,
    PrescribedItemId,
    PrescriptionItemId,
    number,
    medicineValues,
  } = props || {};
  const matchExists = notesModel.some(
    (note) => note.prescriptionItemId === PrescriptionItemId
  );
  const [doseValue, setDoseValue] = useState(medicineValues.dosageText);
  const [quantityValue, setQuantityValue] = useState(medicineValues.quantity);
  const [isNotes, setIsNotes] = useState(false);
  const [inputclass, setInputClass] = useState("disable");
  const [DispenseLabel, setDispenseLabel] = useState("");
  const [colorClass, setColorClass] = useState(
    medicationId
      ? prescriptionItemCheck?.length === 0
        ? "green"
        : "orange"
      : "blue"
  );
  const [heading, setheading] = useState(
    medicationId
      ? prescriptionItemCheck?.length === 0
        ? "Matched with therapy"
        : "Therapy Change"
      : "New drug"
  );
  // if (itemInfo === null) {
  //   setReturnQuantity(medicineValues.quantity);
  // } else {
  //   setReturnQuantity(itemInfo?.repeatQuantity);
  // }

  // setReturnDose(
  //   itemInfo !== null ? itemInfo?.dosageText : medicineValues.dosageText
  // );
  // setReturnTherapy(itemInfo !== null ? itemInfo?.therapyType : 0);
  // setReturnArchive(itemInfo !== null ? itemInfo?.neverAutoArchive : false);
  // setReturnPreparation(itemInfo !== null ? itemInfo?.preparationType : 0);
  // setReturnMarking(itemInfo !== null ? itemInfo?.marking : "");
  // setReturnColor(itemInfo !== null ? itemInfo?.colour : "Green");
  // setReturnReport(itemInfo !== null ? itemInfo?.showOnReport : false);
  // setEditable(
  //   medicationId ? (prescriptionItemCheck?.length === 0 ? true : true) : false
  // );
  // setMedicationTime(
  //   itemInfo === null ? defaultDosageTimings : itemInfo?.patientMedicationTime
  // );
  const therapyChanges = [44, 45, 46, 47, 48];
  useEffect(() => {
    async function fetchData() {
      prescriptionItemCheck?.map((value) => {
        if (medicationId) {
          if (therapyChanges.includes(value.clinicalCheckId)) {
            setTheraphy(true);
          }
        }
      });
    }
    fetchData();
  }, []);

  function changeDispense(value) {
    setReturnDispense(value);
  }
  async function showmodel() {
    setItemReturnDose(doseValue);
    setItemReturnQuantity(quantityValue);
    const foundItem = dispenseItemsArray.find(
      (item) => item.value === returnDispense
    );

    setDispenseLabel(foundItem.label);
    const newCheckNowModel = new setCheckNow(
      checkNowModel,
      "",
      PrescriptionItemId,
      "",
      quantityValue,
      doseValue,
      true,
      postMedicationId,
      isReturnDispense,
      0,
      isReturnDispense,
      isReturnDispense === true ? returnDispense : "",
      false,
      "",
      false,
      "",
      "",
      false
    );
    setCheckNowModel(newCheckNowModel);
    const prescriptionItemChecksBody = new setPrescriptionItemChecks(
      prescriptions,
      quantityValue,
      doseValue,
      PrescriptionItemId,
      true
    ).obj;
    setItemChecks(prescriptionItemChecksBody);

    setButtonclass(!buttonClass);
    setEditable(!isEditable);
  }

  function showbuttons() {
    setButtonclass(!buttonClass);
    setEditable(!isEditable);
  }

  async function getEndorsementforItem() {
    await getAdditionalEndorsement({
      pharmacyID,
      PrescribedItemId,
    });
  }

  function changeitemQuantity(e) {
    const value = e.target.value;
    if (value === "" || value === "0" || /^[1-9]\d{0,2}$/.test(value)) {
      setQuantityValue(value);
    }
  }

  function changeitemDose(e) {
    setDoseValue(e?.target?.value);
  }
  function hideButton() {
    setButtonclass(false);
    setEditable(!isEditable);
    setQuantityValue(medicineValues?.quantity);
    setDoseValue(medicineValues?.dosageText);
  }

  function handleChangeDispensed(checked) {
    setDispense(!checked);
  }
  function notes() {
    setIsNotes(!isNotes);
  }
  function checkPrescriptionActions() {
    let allMatch = true;

    const matchingItem = checkNowModel?.prescriptionItems.find(
      (item) => item?.prescribedItemId === PrescribedItemId
    );
    const flattenedCheckGroups = prescriptionCheckGroups.flat();
    const { prescriptionItemCheckActions } = matchingItem;
    if (
      !flattenedCheckGroups.find(
        (item) => item?.prescribedItemId === PrescribedItemId
      )
    ) {
      allMatch = true;
    } else if (
      flattenedCheckGroups.find(
        (item) => item?.prescribedItemId === PrescribedItemId
      ) &&
      prescriptionItemCheckActions?.length === 0
    ) {
      allMatch = false;
    } else {
      allMatch = flattenedCheckGroups
        .filter((group) => group?.prescribedItemId === PrescribedItemId)
        .every((group) =>
          prescriptionItemCheckActions.some(
            (action) =>
              action?.clinicalCheckId === group?.clinicalCheckId &&
              group?.clinicalCheckOptions.some(
                (option) =>
                  option?.clinicalCheckOptionId ===
                  action?.clinicalCheckOptionId
              )
          )
        );
    }
    return allMatch;
  }
  return (
    <>
      {isAppLoading === false && (
        <>
          <EndorsementModal PrescriptionItemId={PrescriptionItemId} />
          <div className="medicine-card customcard">
            <div
              className="card-step-number numberscart"
              style={{
                background:
                  checkPrescriptionActions() === true ? "#00ca69" : "#E22424",
              }}
            >
              <h2>{number}</h2>
            </div>
            <div className={`card-header ${colorClass}`}>
              <div className="card-header-title">
                <img src={starIcon} />
                <p>{heading}</p>
              </div>
              <img src={checkIcon} />
            </div>

            <div className="card-content re">
              <div className="card-actions">
                <h2
                  className={
                    isReturnDispense
                      ? "medicine-card-heading-dispensed"
                      : dispenseArray.includes(PrescriptionItemId)
                      ? "medicine-card-heading-dispensed"
                      : "medicine-card-heading"
                  }
                >
                  <RiMedicineBottleLine />
                  {medicineValues.prescribedItem.writtenAs}
                  {medicineValues?.dosageAutoCheckInfo !== null ? (
                    <NmsPrescriptionToolTip
                      autoCheckInfo={medicineValues?.dosageAutoCheckInfo}
                    />
                  ) : (
                    <></>
                  )}
                </h2>
                <div className="card-right-actions">
                  {buttonClass ? (
                    <>
                      <CustomSwitch
                        handleCallback={handleChangeDispensed}
                        defaultValue={isReturnDispense}
                      />
                      <p>Not dispensed</p>
                      <p>{matchExists ? notesModel?.length : 0}</p>
                      <img src={chatIcon} onClick={notes} />
                    </>
                  ) : (
                    <>
                      <p>{matchExists ? notesModel?.length : 0}</p>
                      <img src={chatIcon} onClick={notes} />
                      <img src={editIcon} onClick={showbuttons} />
                    </>
                  )}
                </div>
              </div>
              {!buttonClass ? (
                isReturnDispense ? (
                  <>
                    <p className="dispense-label">{DispenseLabel}</p>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              {buttonClass ? (
                isReturnDispense ? (
                  <div className="outer-boxes">
                    <div className="outer-item not-disp-reason">
                      <CustomLabel name="Not dispensed reason" />
                      <CustomDropdown
                        handleChange={changeDispense}
                        label=""
                        items={dispenseItemsArray}
                        selected={returnDispense}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}

              {buttonClass ? (
                <>
                  <div className="outer-boxes">
                    <div className="outer-item">
                      <CustomLabel name="Quantity" />
                      <input
                        className={" card-inner-value quantity-box"}
                        type="text"
                        disabled={false}
                        value={quantityValue}
                        onChange={changeitemQuantity}
                      />
                    </div>
                  </div>
                  <div className="outer-boxes">
                    <div className="outer-item">
                      <CustomLabel name="Dose" />
                      <input
                        className={" card-inner-value quantity-box"}
                        type="text"
                        disabled={false}
                        value={doseValue}
                        onChange={changeitemDose}
                      />
                    </div>
                    <div className="outer-item">
                      <CustomLabel name="Measure" />
                      <input
                        className={" card-inner-value quantity-box"}
                        type="text"
                        disabled={false}
                        value={
                          medicineValues.unitText ? medicineValues.unitText : ""
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="outer-boxes">
                    <div className="outer-item">
                      <CustomLabel name="Quantity" />
                      {buttonClass ? (
                        <input
                          className={" card-inner-value quantity-box"}
                          type="text"
                          disabled={isTherapy}
                          value={quantityValue}
                          onChange={changeitemQuantity}
                        />
                      ) : (
                        <label className="custom-label custom-label-value">{`${quantityValue} ${medicineValues.unitText}`}</label>
                      )}
                    </div>
                    <div className="outer-item">
                      <CustomLabel name="Dose" />
                      <CustomLabel name={doseValue} />
                    </div>
                  </div>
                </>
              )}

              {isTherapy === true ? (
                <>
                  {therapyComponents.includes(1) ? (
                    <CoreAcoordion
                      medicineValues={medicineValues}
                      itemInfo={itemInfo}
                    />
                  ) : (
                    <></>
                  )}
                  {therapyComponents.includes(2) ? (
                    <ScheduleAccordion
                      PatientMedicationTime={
                        itemInfo === null
                          ? defaultDosageTimings
                          : itemInfo?.patientMedicationTime
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {therapyComponents.includes(4) ? (
                    <MdsAccordion itemInfo={itemInfo} />
                  ) : (
                    <></>
                  )}
                  {therapyComponents.includes(5) ? (
                    <ReportsAccordion itemInfo={itemInfo} />
                  ) : (
                    <></>
                  )}
                  {isNotes ? (
                    <NotesControl PrescriptionItemId={PrescriptionItemId} />
                  ) : (
                    <></>
                  )}

                  <div
                    className={
                      buttonClass ? "buttons-enable" : "buttons-disable"
                    }
                  >
                    <div className="warning-switch">
                      <CustomSwitch />
                      <p>Show warnings</p>
                    </div>
                    <div className="buttons-bar">
                      <button className="form-buttons" onClick={hideButton}>
                        Cancel
                      </button>
                      <button
                        className="form-buttons"
                        onClick={getEndorsementforItem}
                      >
                        Additional Endorsements
                      </button>
                      <button className="form-buttons" onClick={showmodel}>
                        Add item
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {isNotes ? (
                    <NotesControl PrescriptionItemId={PrescriptionItemId} />
                  ) : (
                    <></>
                  )}
                  <div
                    className={`${
                      buttonClass ? "buttons-enable" : "buttons-disable"
                    }-add`}
                  >
                    {buttonClass ? (
                      <div className="buttons-bar">
                        <button className="form-buttons" onClick={hideButton}>
                          Cancel
                        </button>
                        <button className="form-buttons" onClick={showmodel}>
                          Add item
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
              {prescriptionCheckGroups?.map((group, index) => {
                return group?.length > 1 ? (
                  group[0]?.prescribedItemId === PrescribedItemId ? (
                    <div key={index}>
                      <GroupMedicineWarning
                        key={index}
                        group={group}
                        PrescriptionItemId={PrescriptionItemId}
                        PrescribedItemId={PrescribedItemId}
                        itemInfo={itemInfo}
                        medicineValues={medicineValues}
                        autoCheckInfo={group[0]?.autoCheck}
                      />
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <div key={index}>
                    {group?.map((items, innerIndex) => {
                      if (items?.prescribedItemId === PrescribedItemId) {
                        return (
                          <MedicineClinicalWarning
                            key={innerIndex}
                            items={items}
                            itemInfo={itemInfo}
                            PrescribedItemId={PrescribedItemId}
                            PrescriptionItemId={PrescriptionItemId}
                            medicineValues={medicineValues}
                            autoCheckInfo={items?.autoCheck}
                          />
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PrescribedMedicines;
