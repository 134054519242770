import React from "react";
import NmsTimeLine from "../../../../components/NmsDetails/NmsTimeLine";

export default function PrivateServiceActivity({ stageName }) {
  return (
    <div className="ps-timeline-wrapper">
      <NmsTimeLine stageName={stageName} view="private-service" />
    </div>
  );
}
