import { useEffect, useRef, useState } from "react";
import setSearchDrug from "../../Model/SearchDrug/setSerachDrug";
import { searchTypes } from "../../enum/searchTypes";
import useTitanApp from "../../hooks/useTitanApp";
import { searchDrug, searchPatient } from "../../dispatchers/nmsDispatchers";
import { drugNames } from "../../data/drugNames";

const SearchableDropdown = ({
  label,
  id,
  selectedVal,
  handleChange,
  searchType,
  options,
}) => {
  const { clincicalChecksState, appState } = useTitanApp();
  const { currentPharmacyId } = appState;
  const { clinicalChecksPharmacy } = clincicalChecksState || {};
  const { pharmacyID } = clinicalChecksPharmacy || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);
  function setDateformat(inputDate) {
    const date = new Date(inputDate);

    const ukShortDate = date?.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: "UTC",
    });
    return ukShortDate;
  }
  const selectOption = (option) => {
    setQuery(() => "");
    switch (searchTypes[searchType]) {
      case 0:
        handleChange(option);
        break;
      case 1:
        handleChange(option);
        break;
    }

    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return "";
  };
  const filter = (options) => {
    return query === ""
      ? []
      : options?.filter(
          (option) =>
            option[label]?.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
  };
  const CallSearchApi = async (drug) => {
    let response;
    var updatedDrugs = drugNames.filter((item) =>
      item?.drugName.toLocaleLowerCase().includes(drug.toLocaleLowerCase())
    );
    setSearchOptions(updatedDrugs);
  };
  const Changequery = async (drug) => {
    setIsProcessing(true);
    setQuery(drug);
    if (drug === "") {
      setSearchOptions([]);
    } else {
      CallSearchApi(drug);
    }
    setIsProcessing(false);
  };
  return (
    <div className="search_dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            className={isProcessing ? "disabled-input" : ""}
            onChange={(e) => {
              Changequery(e?.target?.value);

              handleChange(null);
            }}
            onClick={toggle}
          />
        </div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {searchOptions?.map((option, index) => {
          switch (searchTypes[searchType]) {
            case 0:
              return (
                <div
                  onClick={() => selectOption(option)}
                  className={`option ${
                    option.drugName === selectedVal ? "selected" : ""
                  }`}
                  key={`${id}-${index}`}
                >
                  {option.drugName}
                </div>
              );
              break;
            case 1:
              return (
                <div
                  onClick={() => selectOption(option)}
                  className={`option ${
                    option.drugName === selectedVal ? "selected" : ""
                  }`}
                  key={`${id}-${index}`}
                >
                  {`${option?.title} ${option?.forename} ${
                    option.surname
                  } ${setDateformat(option?.dob)}`}
                </div>
              );
              break;
          }
        })}
      </div>
    </div>
  );
};

export default SearchableDropdown;
