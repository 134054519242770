import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { FormControlLabel, Switch, IconButton } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoCopyOutline } from "react-icons/io5";
import useTitanApp from "./../../../../../../../../../../hooks/useTitanApp";
import useTitanType from "../../../../../../../../../../hooks/useTitanType";
import { setShareAndEmbedData } from "../../../../../../../../../../dispatchers/typeDispatchers";

export default function OnlineBookingTypesShareEmbed() {
  const { appState } = useTitanApp();
  const { darkMode } = appState;
  const { typeState } = useTitanType();
  const { clinicalServiceBuilderModel, defaultClinicalServiceBuilderModel } =
    typeState;

  // Safely access shareEmbed details
  const onlineBookingDetails =
    clinicalServiceBuilderModel?.clinicalServiceBuilder?.onlineBookings
      ?.shareEmbed ??
    defaultClinicalServiceBuilderModel?.clinicalServiceBuilder?.onlineBookings
      ?.shareEmbed ??
    {};

  const [bookingUrl, setBookingUrl] = useState(
    onlineBookingDetails?.bookingUrl ?? ""
  );
  const [iframeCode, setIframeCode] = useState(
    onlineBookingDetails?.iFrameCode ?? ""
  );

  const handleTextFieldChange = (event) => {
    const { name, value } = event.target;
    if (name === "bookingUrl") {
      setBookingUrl(value);
    } else if (name === "iframeCode") {
      setIframeCode(value);
    }
    setShareAndEmbedData({
      bookingUrl: name === "bookingUrl" ? value : bookingUrl,
      iFrameCode: name === "iframeCode" ? value : iframeCode,
    });
  };

  return (
    <div className="privateservices_one_layout">
      <div className="text-field-container clipboard-field">
        <TextField
          id="booking-url"
          label="Booking URL"
          variant="outlined"
          name="bookingUrl"
          fullWidth
          value={bookingUrl}
          onChange={handleTextFieldChange}
        />
        <CopyToClipboard text={bookingUrl} className="copy-clipboard-icon">
          <IconButton aria-label="copy">
            <IoCopyOutline color={darkMode ? "#f1f1f1" : "#0a0a0a"} />
          </IconButton>
        </CopyToClipboard>
      </div>

      <div className="text-field-container clipboard-field">
        <TextField
          id="iframe-code"
          label="Iframe Code"
          variant="outlined"
          name="iframeCode"
          fullWidth
          value={iframeCode}
          onChange={handleTextFieldChange}
        />
        <CopyToClipboard text={iframeCode} className="copy-clipboard-icon">
          <IconButton aria-label="copy">
            <IoCopyOutline color={darkMode ? "#f1f1f1" : "#0a0a0a"} />
          </IconButton>
        </CopyToClipboard>
      </div>
    </div>
  );
}
