import React, { useState, useEffect } from "react";
import "./../../../../../assets/css/Dashboard/ServicesAnalytics.scss";
import ServiceAnalyticsCharts from "./components/ServiceAnalyticsCharts";
import ServiceClaimStatus from "./components/ServiceClaimStatus/ServiceClaimStatus";
import DateRange from "./components/DateRangePicker";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { FaLessThanEqual } from "react-icons/fa";
import { Tabs, Tab } from "@mui/material";
import { Skeleton } from "@mui/material";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineFileDownloadDone } from "react-icons/md";
import { setAnalyticsTabsValue } from "../../../../../dispatchers/pharmacyServiceDispatchers";

export default function ServicesAnalytics() {
  const dataSetOne = [
    {
      name: "Jan",

      uv: 590,

      pv: 800,

      amt: 1400,

      cnt: 490,
    },

    {
      name: "Feb",

      uv: 868,

      pv: 967,

      amt: 1506,

      cnt: 590,
    },

    {
      name: "Mar",

      uv: 1397,

      pv: 1098,

      amt: 989,

      cnt: 350,
    },

    {
      name: "Apr",

      uv: 1480,

      pv: 1200,

      amt: 1228,

      cnt: 480,
    },

    {
      name: "May",

      uv: 1520,

      pv: 1108,

      amt: 1100,

      cnt: 460,
    },

    {
      name: "June",

      uv: 590,

      pv: 800,

      amt: 1400,

      cnt: 490,
    },

    {
      name: "July",

      uv: 868,

      pv: 967,

      amt: 1506,

      cnt: 590,
    },

    {
      name: "Aug",

      uv: 1397,

      pv: 1098,

      amt: 989,

      cnt: 350,
    },

    {
      name: "Sep",

      uv: 1480,

      pv: 1200,

      amt: 1228,

      cnt: 480,
    },

    {
      name: "Oct",

      uv: 1520,

      pv: 1108,

      amt: 1100,

      cnt: 460,
    },

    {
      name: "Nov",

      uv: 1480,

      pv: 1200,

      amt: 1228,

      cnt: 480,
    },

    {
      name: "Dec",

      uv: 1520,

      pv: 1108,

      amt: 1100,

      cnt: 460,
    },
  ];

  const { appState, pharmacyServiceState } = useTitanApp();
  const { darkMode } = appState || {};

  const { isAppLoading } = appState || {};
  const { serviceAnalytics, isPharmacyServiceUsersLoading, analyticsTabValue } =
    pharmacyServiceState || {};

  const [errMessage, setErrorMessage] = useState("");
  const [isError, setError] = useState(false);
  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 3);
  const defaultEndDate = new Date();

  return (
    isAppLoading === false && (
      <>
        <div className="services_content-bg services_analytics">
          <>
            <div className="anaylytics-filters-wrapper">
              {isPharmacyServiceUsersLoading ? (
                <div className="analytics-tabs-wrapper">
                  <div className="service_filter_skeleton">
                    <Skeleton
                      sx={{ bgcolor: darkMode ? "grey.900" : "" }}
                      height={50}
                      animation="wave"
                    />
                  </div>
                </div>
              ) : (
                <div className="analytics-tabs-wrapper">
                  <div className="tabs_box services_container-tabs">
                    <Tabs
                      value={analyticsTabValue}
                      onChange={(event, newValue) => {
                        setAnalyticsTabsValue(newValue);
                      }}
                      aria-label="Tabs"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "black",
                        },
                      }}
                    >
                      <Tab
                        icon={
                          <RxDashboard
                            className={
                              analyticsTabValue === 1
                                ? "icons-color"
                                : "icons-box"
                            }
                          />
                        }
                        iconPosition="start"
                        label={<span className={`tabs_text`}>Dashboard</span>}
                        value={0}
                      />
                      <Tab
                        icon={
                          <MdOutlineFileDownloadDone className="icons-color" />
                        }
                        iconPosition="start"
                        label={<span className={`tabs_text`}>Completed</span>}
                        value={1}
                      />
                    </Tabs>
                  </div>
                </div>
              )}
              <DateRange />
            </div>

            {/* Animated content based on active tab */}
            {analyticsTabValue === 0 && <ServiceAnalyticsCharts />}

            {analyticsTabValue === 1 && <ServiceClaimStatus />}
          </>
        </div>
      </>
    )
  );
}
