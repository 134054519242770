import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./../../../../../../../../assets/css/Dashboard/Claim.scss";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { moveDeclineServiceToClinicalService } from "../../../../../../../../dispatchers/pharmacyServiceDispatchers";

export default function Declined(props) {
  const [rowData, setRowData] = useState(props?.claimedServices);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const StatusIcon = (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  );
  const gridStyle = { height: "100%", width: "100%" };

  const PatientColumns = [
    {
      field: "selected",
      width: 0,
      selected: false,
      headerClass: "defaultGridHeaderCol",
    },
    {
      headerName: "Patient name",
      field: "name",
      sortable: true,
      editable: false,
    },
    {
      headerName: "Date of Nms",
      field: "dateOfService",
      sortable: true,
      editable: false,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return date?.toLocaleDateString("en-GB", options);
      },
    },
    {
      headerName: "Declined Date",
      field: "completedDate",
      sortable: true,
      editable: false,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return date.toLocaleDateString("en-GB", options);
      },
    },
    {
      headerName: "Declined By",
      field: "completedBy",
      sortable: true,
      editable: false,
    },
    {
      headerName: "Status",
      field: "serviceStatus",
      sortable: true,
      editable: false,
      cellRendererFramework: StatusIcon,
      valueGetter: (params) => {
        switch (params?.data?.serviceStatus) {
          case 0:
            return "Pending";
          case 1:
            return "Inprocess";
          case 2:
            return "Completed";
          case 3:
            return "Declined";
          default:
            return params?.data?.serviceStatus;
        }
      },
    },
    {
      headerName: "Back to eligible",
      cellRenderer: (params) => {
        return (
          <button className="revert_back_action_button">
            Back to eligible
          </button>
        );
      },
    },
  ];

  const gridOptions = {
    suppressMovableColumns: true,
    getRowClass: (params) => (params?.node?.isSelected ? "selected-row" : ""),
    onCellClicked: (event) => {
      if (event?.colDef?.headerName === "Back to eligible") {
        handleBackToEligible(event.data);
        return;
      }
      if (props.onRowClick) {
        props.onRowClick(event.data);
      }
    },
  };

  const handleBackToEligible = async (data) => {
    if (data !== null) {
      const clinicalServiceId = data?.clinicalServiceID;
      var result = await moveDeclineServiceToClinicalService(clinicalServiceId);
      const updatedData = rowData.filter(
        (row) => row.clinicalServiceID !== clinicalServiceId
      );
      setRowData(updatedData);
    }
  };

  const exportToCSV = (apiData, fileName) => {
    const modifiedData = apiData?.map((data) => {
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };

      const formattedDOS = new Date(data.dateOfService);
      const formattedDateofService = formattedDOS.toLocaleDateString(
        "en-GB",
        options
      );

      const formattedCD = new Date(data.completedDate);
      const formattedCompleteDate = formattedCD.toLocaleDateString(
        "en-GB",
        options
      );

      return {
        "Patient Name": data.name,
        "Date of Service": formattedDateofService,
        "Completed Date": formattedCompleteDate,
        "Service Status": data.serviceStatus,
        "Completed By": data.completedBy,
      };
    });

    const ws = XLSX.utils.json_to_sheet(modifiedData);
    const wb = {
      Sheets: { ["Declined"]: ws },
      SheetNames: ["Declined"],
    };

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div
      style={gridStyle}
      className={
        props?.darkMode === true ? "ag-theme-alpine-dark" : "ag-theme-alpine"
      }
    >
      {props?.claimedServices?.length !== 0 ? (
        <>
          <AgGridReact
            rowData={rowData}
            columnDefs={PatientColumns}
            gridOptions={gridOptions}
          />

          <div className="analytics_tab_list_footer">
            <button onClick={(e) => exportToCSV(rowData, "DeclinedFile")}>
              <p>Export</p>
            </button>
          </div>
        </>
      ) : (
        <div>
          <h4>No Data To Show</h4>
        </div>
      )}
    </div>
  );
}
