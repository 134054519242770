import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost } from "../../services/httpService";
import { v4 as uuidv4 } from "uuid";
import {
  getPharmacyServiceUrl,
  getPharmacyClinicalServiceUrl,
  getPharmacyClinicalServiceCountUrl,
  getClinicalServiceBuilderByServiceIdUrl,
  getEventsUrl,
  updateEventUrl,
  updateCalendarEventByClinicalServiceIdUrl,
  updateRecheduleByClinicalServiceIdUrl,
  getAnalyticsByServicePharmacyDateUrl,
  getPharmaciesByCompanyIdUrl,
  addpharmacyServiceUrl,
  sendSMSUrl,
  getSMSUrl,
  getOrbitSessionDetailUrl,
  saveOrbitSessionDetailUrl,
  getUserLogUrl,
  savePatientContactUrl,
  getIdentityVerificationUrl,
  getIdentityVerificationDataBySessionIdUrl,
  checkYotiDataBySessionIdUrl,
  getServiceProgressURL,
  updateClaimStatusURL,
  sendToOrbitURL,
  changeSentToOrbitUrl,
  moveInterventionUrl,
  declineServicesUrl,
  rescheduleServicesUrl,
  getPharmacyActionsUrl,
  changePharmacyActionsUrl,
  getTitanPatientPdsUrl,
  moveDeclineServiceToClinicalServiceUrl,
  getDefaultCompanyServiceUrl,
  getClinicalServiceBuilderByIdUrl,
  getServiceTemplatesUrl,
  submitCustomTemplateUrl,
  getClinicalServiceBuilderByCompanyIdUrl,
  updateClinicalServicePaymentDetailsUrl,
  getAgencyRatesUrl,
  getAgencyPharmacyUrl,
  addAgencyPharmacyUrl,
  getAnalyticsNMSProcessedUrl,
  getAnalyticsServicesEarningsUrl,
  getAnalyticsServicesUsersUrl,
  getAnalyticsServicesStagesUrl,
  getAnalyticsServicesCompletedClaimedUrl,
  getAnalyticsServicesCompletedToBeClaimedUrl,
  getAnalyticsServicesDeclinedUrl,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";
import moment from "moment";
import { getDueName } from "../../data/getStartDateForStages";

const tzoffset = new Date().getTimezoneOffset() * 60000;
const defaultStartDate = new Date(Date.now() - tzoffset);
defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
const defaultEndDate = new Date(Date.now() - tzoffset);

const initialState = {
  isPharmacyServiceLoading: false,
  isPharmacyServiceSiedebarLoading: false,
  isPharmacyAnalyticsLoading: false,
  isCalenderShow: false,
  isServiceProgressLoading: false,
  newClinicComponent: false,
  currentServiceId: "0",
  currentServiceTab: 0,
  NhsCount: 0,
  privateCount: 0,
  totalCount: 0,
  serviceName: "",
  serviceIcon: "",
  serviceBuilderType: "0",
  pharmacyServices: [],
  pharmacyClincalServices: [],
  pharmacyClincalServicesCount: [],
  currentServiceData: [],
  clinicalServiceBuilderModel: [],
  currentEvents: [],
  serviceAnalytics: [],
  calendarStartDate: defaultStartDate.toISOString(),
  calendarEndDate: defaultEndDate.toISOString(),
  allPharmacies: [],
  currentPharmacyService: null,
  smsQueue: [],
  smsUrls: [],
  orbitSessionDetail: null,
  orbitSession: null,
  userLog: null,
  isNmsProgress: false,
  userName: "",
  savePatientContact: null,
  isPatientContact: false,
  contactServiceId: "",
  filterPharmacyService: [],
  filterNmsValue: "Is",
  filterTagValues: [],
  filterTagValuesConsented: [],
  filterNameValues: "",
  isFilterModal: false,
  patientTags: [],
  patientTagsColor: [],
  pharmacyServicesSms: [],
  yotiSessionDetail: null,
  updatedEvent: null,
  scrollPosition: 0,
  open: false,
  analyticsTabValue: 0,
  serviceProgress: 0,
  isSentClaim: false,
  orbitQueue: [],
  sidebarVisible: false,
  selectedRowData: null,
  completedServiceTabValue: 0,
  isYotiSuccess: false,
  isSentToOrbit: false,
  orbitClinicalServiceId: "",
  interventionQueue: [],
  declineQueue: [],
  rescheduleQueue: [],
  pdsQueue: [],
  patientName: "Tag",
  interventionStageScrollPosition: 0,
  eligibilityStageScrollPosition: 0,
  listViewGridScrollPosition: 0,
  showReschedule: false,
  checkedAllEligible: false,
  checkedAllConsent: false,
  checkedAllIntervention: false,
  checkedAllFollowUp: false,
  processedPDSServices: [],
  drugFilterValue: "",
  sendSMSAutomation: false,
  sendOrbitAutomation: false,
  sendInterventionAutomation: false,
  sendConsentOnly: false,
  transformedPharmacyData: [],
  pharmacyActions: null,
  originalPharmacyServices: [],
  editedPreferredContact: "",
  sendToInterventionOptions: null,
  isRevertBackToClinicalService: false,
  SendToInterventionDropdownOptions: [
    {
      _id: 0,
      optionName: "With Consent only",
    },
    {
      _id: 1,
      optionName: "For all NMS",
    },
  ],
  isAutomationMenuOpen: false,
  defaultPharmacyServices: [],
  serviceDetails: null,
  serviceDetailTemplates: [],
  customTemplateModel: [],
  isAnyControlChecked: false,
  serviceBuilderDetails: null,
  companyServices: [],
  selectedClinicalServiceId: "",
  showPaymentModal: false,
  isOrbitSendModal: false,
  agencyRates: [],
  isAgencyRatesLoading: false,
  IsPharmacyAgency: false,
  agencyPharmacy: null,
  orbitModalQueue: [],
  // optimize analytics tab
  isPharmacyServiceProcessedLoading: false,
  isPharmacyServiceEarningLoading: false,
  isPharmacyServiceUsersLoading: false,
  isPharmacyServiceStagesLoading: false,
  isPharmacyServiceCompletedClaimedLoading: false,
  isPharmacyServiceCompletedTobeClaimedLoading: false,
  isPharmacyServiceCompletedDeclinedLoading: false,
  serviceAnalyticsProcessed: [],
  serviceAnalyticsEarning: [],
  serviceAnalyticsUsers: [],
  serviceAnalyticsStages: [],
  serviceAnalyticsCompletedClaimed: [],
  serviceAnalyticsCompletedTobeClaimed: [],
  serviceAnalyticsCompletedDeclined: [],
};

export const getClinicalServiceBuilderByCompanyIdData = createAsyncThunk(
  "pharmacyService/getClinicalServiceBuilderByCompanyIdData",
  async (companyId, thunkApi) => {
    const urlToGet = getClinicalServiceBuilderByCompanyIdUrl(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getPharmacyServiceData = createAsyncThunk(
  "pharmacyService/getPharmacyServiceData",
  async (params) => {
    const { pharmacyId, searchType, serviceId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };
    const urlToGet = getPharmacyServiceUrl(pharmacyId, serviceId, searchType);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getPharmacyClinicalServiceData = createAsyncThunk(
  "pharmacyService/getPharmacyClinicalServiceData",
  async (pharmacyId) => {
    const urlToGet = getPharmacyClinicalServiceUrl(pharmacyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getPharmacyClinicalServiceCountData = createAsyncThunk(
  "pharmacyService/getPharmacyClinicalServiceCountData",
  async (pharmacyId) => {
    const urlToGet = getPharmacyClinicalServiceCountUrl(pharmacyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getClinicalServiceBuilderByServiceIdData = createAsyncThunk(
  "pharmacyService/getClinicalServiceBuilderByServiceIdData",
  async (params) => {
    const { pharmacyId, serviceId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };
    const urlToGet = getClinicalServiceBuilderByServiceIdUrl(
      pharmacyId,
      serviceId
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getEventsData = createAsyncThunk(
  "pharmacyService/getEventsData",
  async (params) => {
    const { pharmacyId, serviceId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };
    const urlToGet = getEventsUrl(pharmacyId, serviceId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getAnalyticsByServicePharmacyDateData = createAsyncThunk(
  "pharmacyService/getAnalyticsByServicePharmacyDateData",
  async (params, thunkApi) => {
    const { calendarStartDate, calendarEndDate } =
      thunkApi.getState().pharmacyService || {};
    const { pharmacyId, serviceId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };

    const urlToGet = getAnalyticsByServicePharmacyDateUrl(
      pharmacyId,
      serviceId,
      calendarStartDate,
      calendarEndDate
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getPharmaciesByCompanyIdData = createAsyncThunk(
  "pharmacyService/getPharmaciesByCompanyIdData",
  async (companyId, thunkApi) => {
    const urlToGet = getPharmaciesByCompanyIdUrl(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const addpharmacyServiceData = createAsyncThunk(
  "pharmacyService/addpharmacyServiceData",
  async ({ pharmacyId, data }, thunkApi) => {
    const url = addpharmacyServiceUrl(pharmacyId);
    const respData = await httpPost(url, data);
    return respData;
  }
);
export const updateEventData = createAsyncThunk(
  "pharmacyService/updateEventData",
  async (data, thunkApi) => {
    const url = updateEventUrl();
    const respData = await httpPost(url, data);
    return respData;
  }
);
export const updateCalendarEventByClinicalServiceIdData = createAsyncThunk(
  "pharmacyService/updateCalendarEventByClinicalServiceIdData",
  async (params, thunkApi) => {
    const { formattedDateTime, clinicalServiceId, stageNo } = params || {
      formattedDateTime: "",
    };
    const url = updateCalendarEventByClinicalServiceIdUrl(
      formattedDateTime,
      clinicalServiceId,
      stageNo
    );
    const respData = await httpGet(url);
    return respData;
  }
);
export const updateRecheduleByClinicalServiceIdData = createAsyncThunk(
  "pharmacyService/updateRecheduleByClinicalServiceIdData",
  async (params, thunkApi) => {
    const { formattedDateTime, clinicalServiceId, stageNo, data } = params || {
      formattedDateTime: "",
    };
    const url = updateRecheduleByClinicalServiceIdUrl(
      formattedDateTime,
      clinicalServiceId,
      stageNo
    );
    const respData = await httpPost(url, data);
    return respData;
  }
);
export const sendSMSData = createAsyncThunk(
  "pharmacyService/sendSMSData",
  async (data, thunkApi) => {
    const url = sendSMSUrl();

    const respData = await httpPost(url, data);
    return respData;
  }
);
export const updateClaimStatusData = createAsyncThunk(
  "pharmacyService/updateClaimStatusData",
  async (data, thunkApi) => {
    const url = updateClaimStatusURL();
    const respData = await httpPost(url, data);
    return respData;
  }
);
export const sendToOrbitData = createAsyncThunk(
  "pharmacyService/sendToOrbitData",
  async (data, thunkApi) => {
    const url = sendToOrbitURL();
    const respData = await httpPost(url, data);
    return respData;
  }
);
export const changeSentToOrbitData = createAsyncThunk(
  "pharmacyService/changeSentToOrbitData",
  async (serviceId, thunkApi) => {
    const urlToGet = changeSentToOrbitUrl(serviceId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getSMSData = createAsyncThunk(
  "pharmacyService/getSMSData",
  async (thunkApi) => {
    const urlToGet = getSMSUrl();
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getIdentityVerificationData = createAsyncThunk(
  "pharmacyService/getIdentityVerificationData",
  async (thunkApi) => {
    const urlToGet = getIdentityVerificationUrl();
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getIdentityVerificationDataBySessionIdData = createAsyncThunk(
  "pharmacyService/getIdentityVerificationDataBySessionIdData",
  async (params, thunkApi) => {
    const { sessionId, gphcNumber } = params || {};
    const urlToGet = getIdentityVerificationDataBySessionIdUrl(
      sessionId,
      gphcNumber
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const checkYotiDataBySessionIdData = createAsyncThunk(
  "pharmacyService/checkYotiDataBySessionIdData",
  async (params, thunkApi) => {
    const urlToGet = checkYotiDataBySessionIdUrl(params);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getOrbitSessionDetailData = createAsyncThunk(
  "pharmacyService/getOrbitSessionDetailData",
  async (pharmacyId, thunkApi) => {
    const urlToGet = getOrbitSessionDetailUrl(pharmacyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const saveOrbitSessionDetailData = createAsyncThunk(
  "pharmacyService/saveOrbitSessionDetailData",
  async (data, thunkApi) => {
    const url = saveOrbitSessionDetailUrl();
    const respData = await httpPost(url, data);
    return respData;
  }
);
export const getUserLogData = createAsyncThunk(
  "pharmacyService/getUserLogData",
  async (serviceId, thunkApi) => {
    const urlToGet = getUserLogUrl(serviceId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const savePatientContactData = createAsyncThunk(
  "pharmacyService/savePatientContactData",
  async (params, thunkApi) => {
    const { serviceId, telephone, data } = params || {};
    const url = savePatientContactUrl(serviceId, telephone);
    const respData = await httpPost(url, data);
    return respData;
  }
);
export const getServiceProgressData = createAsyncThunk(
  "pharmacyService/getServiceProgressData",
  async (params) => {
    const { serviceId, pharmacyId } = params;
    const urlToGet = getServiceProgressURL(serviceId, pharmacyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const getPharmacyActionsData = createAsyncThunk(
  "pharmacyService/getPharmacyActionsData",
  async (params) => {
    const urlToGet = getPharmacyActionsUrl(params);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const changePharmacyActionsData = createAsyncThunk(
  "pharmacyService/changePharmacyActionsData",
  async (params) => {
    const { pharmacyId, pharmacyActionType, isAction } = params;
    const urlToGet = changePharmacyActionsUrl(
      pharmacyId,
      pharmacyActionType,
      isAction
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const moveInterventionData = createAsyncThunk(
  "pharmacyService/moveInterventionData",
  async (data, thunkApi) => {
    const url = moveInterventionUrl();
    const respData = await httpPost(url, data);
    return respData;
  }
);
export const declineServicesData = createAsyncThunk(
  "pharmacyService/declineServicesData",
  async (data, thunkApi) => {
    const url = declineServicesUrl();
    const respData = await httpPost(url, data);
    return respData;
  }
);
export const moveDeclineServiceToClinicalServiceData = createAsyncThunk(
  "pharmacyService/moveDeclineServiceToClinicalServiceData",
  async (clinicalServiceId) => {
    const url = moveDeclineServiceToClinicalServiceUrl(clinicalServiceId);
    const respData = await httpGet(url);
    return respData;
  }
);
export const rescheduleServicesData = createAsyncThunk(
  "pharmacyService/rescheduleServicesData",
  async (params, thunkApi) => {
    const { data, rescheduleDate } = params;
    const url = rescheduleServicesUrl(rescheduleDate);
    const respData = await httpPost(url, data);
    return respData;
  }
);
export const getServiceTitanPatientPdsData = createAsyncThunk(
  "pharmacyService/getTitanPatientPdsData",
  async (params, thunkAPI) => {
    const { pharmacyID, clinicalServiceId } = params || {};

    const respData = await httpGet(
      getTitanPatientPdsUrl(pharmacyID, clinicalServiceId)
    );
    return respData;
  }
);

export const getDefaultCompanyServiceUrlData = createAsyncThunk(
  "pharmacyService/getDefaultCompanyServiceUrlData",
  async (companyId, thunkApi) => {
    const urlToGet = getDefaultCompanyServiceUrl(companyId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getClinicalServiceBuilderByIdData = createAsyncThunk(
  "pharmacyService/getClinicalServiceBuilderByIdData",
  async (serviceId) => {
    const urlToGet = getClinicalServiceBuilderByIdUrl(serviceId);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getServiceTemplatesData = createAsyncThunk(
  "pharmacyService/getServiceTemplatesData",
  async (data, thunkApi) => {
    const url = getServiceTemplatesUrl();
    const respData = await httpPost(url, data);
    return respData;
  }
);

export const getAgencyRatesData = createAsyncThunk(
  "pharmacyService/getAgencyRatesData",
  async (data, thunkApi) => {
    const url = getAgencyRatesUrl();
    const respData = await httpGet(url);
    return respData;
  }
);

export const getAgencyPharmacyData = createAsyncThunk(
  "pharmacyService/getAgencyPharmacyData",
  async (data, thunkApi) => {
    const url = getAgencyPharmacyUrl(data);
    const respData = await httpGet(url);
    return respData;
  }
);

export const addAgencyPharmacyData = createAsyncThunk(
  "pharmacyService/addAgencyPharmacyData",
  async (data, thunkApi) => {
    const url = addAgencyPharmacyUrl();
    const respData = await httpPost(url, data);
    return respData;
  }
);

export const submitCustomTemplateData = createAsyncThunk(
  "pharmacyService/submitCustomTemplateData",
  async ({ filteredModel, companyID, serviceID }, thunkApi) => {
    const url = submitCustomTemplateUrl(companyID, serviceID);
    const respData = await httpPost(url, filteredModel);
    return respData;
  }
);

export const updateClinicalServicePaymentDetailsData = createAsyncThunk(
  "pharmacyService/updateClinicalServicePaymentDetailsData",
  async (clinicalServiceId, thunkApi) => {
    const url = updateClinicalServicePaymentDetailsUrl(clinicalServiceId, true);
    const respData = await httpPost(url);
    return respData;
  }
);

export const getAnalyticsNMSProcessedData = createAsyncThunk(
  "pharmacyService/getAnalyticsNMSProcessedData",
  async (params, thunkApi) => {
    const { calendarStartDate, calendarEndDate } =
      thunkApi.getState().pharmacyService || {};
    const { pharmacyId, serviceId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };

    const urlToGet = getAnalyticsNMSProcessedUrl(
      pharmacyId,
      serviceId,
      calendarStartDate,
      calendarEndDate
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getAnalyticsServicesEarningsData = createAsyncThunk(
  "pharmacyService/getAnalyticsServicesEarningsData",
  async (params, thunkApi) => {
    const { calendarStartDate, calendarEndDate } =
      thunkApi.getState().pharmacyService || {};
    const { pharmacyId, serviceId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };

    const urlToGet = getAnalyticsServicesEarningsUrl(
      pharmacyId,
      serviceId,
      calendarStartDate,
      calendarEndDate
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getAnalyticsServicesUsersData = createAsyncThunk(
  "pharmacyService/getAnalyticsServicesUsersData",
  async (params, thunkApi) => {
    const { calendarStartDate, calendarEndDate } =
      thunkApi.getState().pharmacyService || {};
    const { pharmacyId, serviceId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };

    const urlToGet = getAnalyticsServicesUsersUrl(
      pharmacyId,
      serviceId,
      calendarStartDate,
      calendarEndDate
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getAnalyticsServicesStagesData = createAsyncThunk(
  "pharmacyService/getAnalyticsServicesStagesData",
  async (params, thunkApi) => {
    const { calendarStartDate, calendarEndDate } =
      thunkApi.getState().pharmacyService || {};
    const { pharmacyId, serviceId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };

    const urlToGet = getAnalyticsServicesStagesUrl(
      pharmacyId,
      serviceId,
      calendarStartDate,
      calendarEndDate
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getAnalyticsServicesCompletedClaimedData = createAsyncThunk(
  "pharmacyService/getAnalyticsServicesCompletedClaimedData",
  async (params, thunkApi) => {
    const { calendarStartDate, calendarEndDate } =
      thunkApi.getState().pharmacyService || {};
    const { pharmacyId, serviceId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };

    const urlToGet = getAnalyticsServicesCompletedClaimedUrl(
      pharmacyId,
      serviceId,
      calendarStartDate,
      calendarEndDate
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getAnalyticsServicesCompletedToBeClaimedData = createAsyncThunk(
  "pharmacyService/getAnalyticsServicesCompletedToBeClaimedData",
  async (params, thunkApi) => {
    const { calendarStartDate, calendarEndDate } =
      thunkApi.getState().pharmacyService || {};
    const { pharmacyId, serviceId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };

    const urlToGet = getAnalyticsServicesCompletedToBeClaimedUrl(
      pharmacyId,
      serviceId,
      calendarStartDate,
      calendarEndDate
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const getAnalyticsServicesDeclinedData = createAsyncThunk(
  "pharmacyService/getAnalyticsServicesDeclinedData",
  async (params, thunkApi) => {
    const { calendarStartDate, calendarEndDate } =
      thunkApi.getState().pharmacyService || {};
    const { pharmacyId, serviceId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };

    const urlToGet = getAnalyticsServicesDeclinedUrl(
      pharmacyId,
      serviceId,
      calendarStartDate,
      calendarEndDate
    );
    const respData = await httpGet(urlToGet);
    return respData;
  }
);

export const pharmacyServiceSlice = createSlice({
  name: "pharmacyService",
  initialState,
  reducers: {
    setIsPharmacyServiceLoading: (state, action) => {
      state.isPharmacyServiceLoading = action?.payload || false;
    },
    setIsPharmacyServiceSidebarLoading: (state, action) => {
      state.isPharmacyServiceSiedebarLoading = action?.payload || false;
    },
    setAnyControlChecked(state, action) {
      state.isAnyControlChecked = action.payload;
    },
    setPatientNameValues: (state, action) => {
      state.patientName = action?.payload || false;
    },
    setShowPaymentModalData: (state, action) => {
      state.showPaymentModal = action?.payload || false;
    },
    setIsPharmacyServicesAnalyticsLoading: (state, action) => {
      state.isPharmacyAnalyticsLoading = action?.payload || false;
    },
    setIsServiceProgressLoading: (state, action) => {
      state.isServiceProgressLoading = action?.payload || false;
    },
    setIsCalenderShow: (state, action) => {
      state.isCalenderShow = action?.payload || false;
    },
    setSidebarVisibleData: (state, action) => {
      state.sidebarVisible = action?.payload || false;
    },
    setCompletedServiceTabValueData: (state, action) => {
      state.completedServiceTabValue = action?.payload || 0;
    },
    setSelectedRow: (state, action) => {
      state.selectedRowData = action?.payload || false;
    },
    setNewClinicComponent: (state, action) => {
      state.newClinicComponent = action?.payload || false;
    },
    setServiceId: (state, action) => {
      state.currentServiceId = action?.payload || "0";
    },
    setCurrentServiceTabValue: (state, action) => {
      state.currentServiceTab = action?.payload || 0;
    },
    setServiceName: (state, action) => {
      state.serviceName = action?.payload || "";
    },
    setServiceIcon: (state, action) => {
      state.serviceIcon = action?.payload || "";
    },
    setServiceBuilderType: (state, action) => {
      state.serviceBuilderType = action?.payload || "0";
    },
    setNHSCount: (state, action) => {
      state.NhsCount = action?.payload || 0;
    },
    setPrivateCount: (state, action) => {
      state.privateCount = action?.payload || 0;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action?.payload || 0;
    },
    setServiceData: (state, action) => {
      state.currentServiceData = action?.payload || [];
    },
    setCurrentEvents: (state, action) => {
      state.currentEvents = action?.payload || [];
    },
    setServiceAnalytics: (state, action) => {
      state.serviceAnalytics = action?.payload || [];
    },
    setCalendarStartDate: (state, action) => {
      state.calendarStartDate = action?.payload;
    },
    setCalendarEndDate: (state, action) => {
      state.calendarEndDate = action?.payload;
    },
    setSmsQueue: (state, action) => {
      state.smsQueue = action?.payload;
    },
    setOrbitQueue: (state, action) => {
      state.orbitQueue = action?.payload;
    },
    setCalendarDates: (state, action) => {
      state.calendarStartDate =
        (Array.isArray(action?.payload) &&
          action?.payload?.length >= 1 &&
          action?.payload[0]) ||
        defaultStartDate.toISOString();
      state.calendarEndDate =
        (Array.isArray(action?.payload) &&
          action?.payload?.length >= 2 &&
          action?.payload[1]) ||
        defaultEndDate.toISOString();
    },
    setNmsProgress: (state, action) => {
      state.isNmsProgress = action?.payload;
    },
    setSentToOrbitData: (state, action) => {
      state.isSentToOrbit = action?.payload;
    },
    setOrbitClinicalServiceIdData: (state, action) => {
      state.orbitClinicalServiceId = action?.payload;
    },
    setPatientContact: (state, action) => {
      state.isPatientContact = action?.payload;
    },
    setContactServiceId: (state, action) => {
      state.contactServiceId = action?.payload;
    },
    setIsFilterModal: (state, action) => {
      state.isFilterModal = action?.payload;
    },
    setFilterPatient: (state, action) => {
      state.filterPatientName = action?.payload || "";
    },
    setFilterNmsValues: (state, action) => {
      state.filterNmsValue = action?.payload || "";
    },
    setFilterTagsvalues: (state, action) => {
      state.filterTagValues = action?.payload || [];
    },
    setFilterTagsvaluesConsented: (state, action) => {
      state.filterTagValuesConsented = action?.payload || [];
    },
    setFilterNamevalues: (state, action) => {
      state.filterNameValues = action?.payload || [];
    },
    setPharmacyServices: (state, action) => {
      state.pharmacyServices = action?.payload || [];
    },
    setScrollPositionData: (state, action) => {
      state.scrollPosition = action?.payload || 0;
    },
    setOpenData: (state, action) => {
      state.open = action?.payload;
    },
    setAnalyticsTabsValueData: (state, action) => {
      state.analyticsTabValue = action?.payload || 0;
    },
    setServiceProgressData: (state, action) => {
      state.serviceProgress = action?.payload || 0;
    },
    setInterventionQueue: (state, action) => {
      state.interventionQueue = action?.payload;
    },
    setDeclineQueue: (state, action) => {
      state.declineQueue = action?.payload;
    },
    setRescheduleQueue: (state, action) => {
      state.rescheduleQueue = action?.payload;
    },
    setPdsQueue: (state, action) => {
      state.pdsQueue = action?.payload;
    },
    setInterventionStageScrollPositionData: (state, action) => {
      state.interventionStageScrollPosition = action?.payload || 0;
    },
    setEligibilityStageScrollPositionData: (state, action) => {
      state.eligibilityStageScrollPosition = action?.payload || 0;
    },
    setListViewGridScrollPositionData: (state, action) => {
      state.listViewGridScrollPosition = action?.payload || 0;
    },
    setShowRescheduleData: (state, action) => {
      state.showReschedule = action?.payload || 0;
    },
    setCheckedAllEligibleData: (state, action) => {
      state.checkedAllEligible = action?.payload || false;
    },
    setCheckedAllConsentData: (state, action) => {
      state.checkedAllConsent = action?.payload || false;
    },
    setCheckedAllInterventionData: (state, action) => {
      state.checkedAllIntervention = action?.payload || false;
    },
    setCheckedAllFollowUpData: (state, action) => {
      state.checkedAllFollowUp = action?.payload || false;
    },
    setProcessedPDSServicesData: (state, action) => {
      state.processedPDSServices = action?.payload || [];
    },
    setDrugFilterValueData: (state, action) => {
      state.drugFilterValue = action?.payload || "";
    },
    setSendSMSAutomationData: (state, action) => {
      state.sendSMSAutomation = action?.payload || false;
    },
    setSendOrbitAutomationData: (state, action) => {
      state.sendOrbitAutomation = action?.payload || false;
    },
    setTransformedPharmacyData: (state, action) => {
      state.pharmacyServices = action?.payload || [];
    },
    setSendInterventionAutomationData: (state, action) => {
      state.sendInterventionAutomation = action?.payload || false;
    },
    setSendConsentOnlyData: (state, action) => {
      state.sendConsentOnly = action?.payload || false;
    },
    setSendToInterventionOptionsData: (state, action) => {
      state.sendToInterventionOptions = action?.payload || null;
    },
    setFilteredPharmacyServicesData: (state, action) => {
      state.filterPharmacyService = action?.payload || false;
    },
    setOriginalPharmacyServicesData: (state, action) => {
      state.originalPharmacyServices = action?.payload || false;
    },
    setIsAutomationMenuOpenData: (state, action) => {
      state.isAutomationMenuOpen = action?.payload || false;
    },
    setSelectedClinicalServiceIdData: (state, action) => {
      state.selectedClinicalServiceId = action?.payload || "";
    },
    setOrbitSendModal: (state, action) => {
      state.isOrbitSendModal = action?.payload;
    },
    setTemplateControlValue: (state, action) => {
      const { templateIndex, sectionIndex, controlIndex, value } =
        action.payload;
      state.customTemplateModel.templates[templateIndex].templateSections[
        sectionIndex
      ].userControls[controlIndex].value = value;
    },
    setOrbitModalQueueData: (state, action) => {
      state.orbitModalQueue = action?.payload || [];
    },
    resetPharmacyServiceState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPharmacyServiceData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(getPharmacyServiceData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        const appDataFromResponse = action?.payload;

        state.pharmacyServices =
          appDataFromResponse?.clinicalServicesSortedModels || [];

        state.filterPharmacyService =
          appDataFromResponse?.clinicalServicesSortedModels || [];

        state.originalPharmacyServices =
          appDataFromResponse?.clinicalServicesSortedModels || [];

        state.pharmacyServicesSms =
          appDataFromResponse?.clinicalServiceSMSDates || [];
        let patientTag = [];
        let patientTagColor = [];
        appDataFromResponse?.clinicalServicesSortedModels?.map((appData) => {
          appData?.sortedServices?.map((sortedServices) => {
            sortedServices?.clinicalServices?.map((clinicalService) => {
              clinicalService?.patientTags?.map((pTags) => {
                const tagToAdd = pTags?.tagName;
                if (!patientTag.includes(tagToAdd)) {
                  patientTag = [...patientTag, tagToAdd];
                }
                const isPresent = patientTagColor.some(
                  (item) => item.tagName === pTags.tagName
                );
                if (!isPresent) {
                  patientTagColor = [...patientTagColor, pTags];
                }
              });
            });
          });
        });

        let gridRowData = appDataFromResponse?.clinicalServicesSortedModels;
        const uuid = require("uuid");

        function calculateAge(dob) {
          const dobDate = new Date(dob);

          if (isNaN(dobDate.getTime()) || dobDate.getFullYear() === 1) {
            return "-";
          }
          const currentDate = new Date();
          const diffMs = currentDate - dobDate;
          const age = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365.25));

          return age;
        }

        const transformData = (data, orbitQueue) => {
          let result = [];
          data?.forEach((stage) => {
            stage?.sortedServices?.forEach((service) => {
              service?.clinicalServices?.forEach((clinicalService) => {
                if (clinicalService?.serviceMedications?.length > 0) {
                  clinicalService?.serviceMedications?.forEach((medication) => {
                    let rowIndex = uuid.v4();
                    let row = {
                      rowIndex: rowIndex,
                      stageName: stage?.stageName,
                      patientName: clinicalService?.patientName,
                      patientNameFilter: clinicalService?.patientName,
                      age: calculateAge(clinicalService?.dob),
                      drugName: medication?.drugName,
                      dosage: medication?.dosage,
                      due: getDueName(service?.dueType),
                      patientTags: clinicalService?.patientTags,
                      handedoutDate: moment(
                        clinicalService?.clinicalServiceDate
                      ).format("DD/MM/YYYY"),
                      consented: clinicalService?.isConsent ? "Yes" : "No",
                      hasTelephoneNumber: clinicalService?.telephoneNumber
                        ? "Yes"
                        : "No",
                      clinicalServiceId: clinicalService?._id,
                      stageType: stage?.stageType,
                      service: clinicalService,
                      sendToOrbit: clinicalService?.sendToOrbit,
                    };

                    result.push(row);
                  });
                } else {
                  let rowIndex = uuid.v4();
                  let row = {
                    rowIndex: rowIndex,
                    stageName: stage?.stageName,
                    patientName: clinicalService?.patientName,
                    patientNameFilter: clinicalService?.patientName,
                    age: calculateAge(clinicalService?.dob),
                    drugName: "",
                    dosage: "",
                    due: getDueName(service?.dueType),
                    patientTags: clinicalService?.patientTags,
                    handedoutDate: moment(
                      clinicalService?.clinicalServiceDate
                    ).format("DD/MM/YYYY"),
                    consented: clinicalService?.isConsent ? "Yes" : "No",
                    hasTelephoneNumber: clinicalService?.telephoneNumber
                      ? "Yes"
                      : "No",
                    clinicalServiceId: clinicalService?._id,
                    stageType: stage?.stageType,
                    service: clinicalService,
                    sendToOrbit: clinicalService?.sendToOrbit,
                  };

                  result.push(row);
                }
              });
            });
          });
          return result;
        };
        state.transformedPharmacyData = transformData(gridRowData);

        if (patientTag?.length > 0) {
          state.patientTags = patientTag;
          state.patientTagsColor = patientTagColor;
        }
        if (state.filterTagValues?.length > 0) {
          if (state.filterNmsValue === "Is") {
            const updatedPharmacyService = state.filterPharmacyService?.map(
              (stage) => ({
                ...stage,
                sortedServices: stage?.sortedServices?.map((service) => ({
                  ...service,
                  clinicalServices: service?.clinicalServices?.filter(
                    (patient) =>
                      patient?.patientTags?.some((tag) =>
                        state.filterTagValues.includes(tag?.tagName)
                      )
                  ),
                })),
              })
            );
            state.pharmacyServices = updatedPharmacyService;
          } else {
            const updatedPharmacyService = state.filterPharmacyService?.map(
              (stage) => ({
                ...stage,
                sortedServices: stage?.sortedServices?.map((service) => ({
                  ...service,
                  clinicalServices: service?.clinicalServices?.filter(
                    (patient) =>
                      !patient?.patientTags?.some((tag) =>
                        state.filterTagValues.includes(tag?.tagName)
                      )
                  ),
                })),
              })
            );
            state.pharmacyServices = updatedPharmacyService;
          }
        }
      })
      .addCase(getPharmacyServiceData.rejected, (state, action) => {
        logError(action?.error);
        state.isPharmacyServiceLoading = false;
        state.pharmacyServices = [];
        state.filterPharmacyService = [];
        state.pharmacyServicesSms = [];
      })
      .addCase(getPharmacyClinicalServiceData.pending, (state) => {
        state.isPharmacyServiceSiedebarLoading = true;
      })
      .addCase(getPharmacyClinicalServiceData.fulfilled, (state, action) => {
        const appDataFromResponse = action?.payload;
        state.pharmacyClincalServices = appDataFromResponse || [];
      })
      .addCase(getPharmacyClinicalServiceData.rejected, (state, action) => {
        logError(action?.error);
        state.pharmacyClincalServices = [];
      })
      .addCase(getPharmacyClinicalServiceCountData.pending, (state) => {
        state.isPharmacyServiceSiedebarLoading = true;
      })
      .addCase(
        getPharmacyClinicalServiceCountData.fulfilled,
        (state, action) => {
          state.isPharmacyServiceSiedebarLoading = false;
          const appDataFromResponse = action?.payload;
          state.pharmacyClincalServicesCount = appDataFromResponse || [];
        }
      )
      .addCase(
        getPharmacyClinicalServiceCountData.rejected,
        (state, action) => {
          logError(action?.error);
          state.isPharmacyServiceSiedebarLoading = false;
          state.pharmacyClincalServicesCount = [];
        }
      )
      .addCase(getClinicalServiceBuilderByServiceIdData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(
        getClinicalServiceBuilderByServiceIdData.fulfilled,
        (state, action) => {
          state.isPharmacyServiceLoading = false;
          const appDataFromResponse = action?.payload;
          state.clinicalServiceBuilderModel = appDataFromResponse || [];
        }
      )
      .addCase(
        getClinicalServiceBuilderByServiceIdData.rejected,
        (state, action) => {
          logError(action?.error);
          state.isPharmacyServiceLoading = false;
          state.clinicalServiceBuilderModel = [];
        }
      )
      .addCase(getAnalyticsByServicePharmacyDateData.pending, (state) => {
        state.isPharmacyAnalyticsLoading = true;
      })
      .addCase(
        getAnalyticsByServicePharmacyDateData.fulfilled,
        (state, action) => {
          state.isPharmacyAnalyticsLoading = false;
          const appDataFromResponse = action?.payload;
          if (appDataFromResponse) {
            try {
              state.serviceAnalytics = appDataFromResponse || [];
            } catch (error) {}
          }
        }
      )
      .addCase(
        getAnalyticsByServicePharmacyDateData.rejected,
        (state, action) => {
          state.isPharmacyAnalyticsLoading = false;
          state.serviceAnalytics = [];
        }
      )
      .addCase(getEventsData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(getEventsData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.currentEvents = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(getEventsData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.currentEvents = [];
      })
      .addCase(getPharmaciesByCompanyIdData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(getPharmaciesByCompanyIdData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.allPharmacies = action?.payload || [];
      })
      .addCase(getPharmaciesByCompanyIdData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.allPharmacies = [];
      })
      .addCase(addpharmacyServiceData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(addpharmacyServiceData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.currentPharmacyService = action?.payload || null;
      })
      .addCase(addpharmacyServiceData.rejected, (state, action) => {
        logError(action?.error);
        state.isPharmacyServiceLoading = false;
        state.currentPharmacyService = null;
      })
      .addCase(updateEventData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(updateEventData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.updatedEvent = action?.payload || null;
      })
      .addCase(updateEventData.rejected, (state, action) => {
        logError(action?.error);
        state.isPharmacyServiceLoading = false;
        state.updatedEvent = null;
      })
      .addCase(
        updateCalendarEventByClinicalServiceIdData.pending,
        (state) => {}
      )
      .addCase(
        updateCalendarEventByClinicalServiceIdData.fulfilled,
        (state, action) => {}
      )
      .addCase(
        updateCalendarEventByClinicalServiceIdData.rejected,
        (state, action) => {
          logError(action?.error);
        }
      )
      .addCase(updateRecheduleByClinicalServiceIdData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(
        updateRecheduleByClinicalServiceIdData.fulfilled,
        (state, action) => {
          state.isPharmacyServiceLoading = false;
        }
      )
      .addCase(
        updateRecheduleByClinicalServiceIdData.rejected,
        (state, action) => {
          logError(action?.error);
          state.isPharmacyServiceLoading = false;
        }
      )
      .addCase(sendSMSData.pending, (state) => {})
      .addCase(sendSMSData.fulfilled, (state, action) => {})
      .addCase(sendSMSData.rejected, (state, action) => {
        logError(action?.error);
      })
      .addCase(sendToOrbitData.pending, (state) => {})
      .addCase(sendToOrbitData.fulfilled, (state, action) => {})
      .addCase(sendToOrbitData.rejected, (state, action) => {
        logError(action?.error);
      })
      .addCase(updateClaimStatusData.pending, (state) => {
        state.isSentClaim = true;
      })
      .addCase(updateClaimStatusData.fulfilled, (state, action) => {
        state.isSentClaim = false;
      })
      .addCase(updateClaimStatusData.rejected, (state, action) => {
        logError(action?.error);
        state.isSentClaim = false;
      })
      .addCase(getSMSData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(getSMSData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.smsUrls = action?.payload || [];
      })
      .addCase(getSMSData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.smsUrls = [];
      })
      .addCase(getIdentityVerificationData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(getIdentityVerificationData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.yotiSessionDetail = action?.payload || null;
      })
      .addCase(getIdentityVerificationData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.yotiSessionDetail = null;
      })
      .addCase(getIdentityVerificationDataBySessionIdData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
        state.isYotiSuccess = false;
      })
      .addCase(
        getIdentityVerificationDataBySessionIdData.fulfilled,
        (state, action) => {
          state.isPharmacyServiceLoading = false;
          const appDataFromResponse = action?.payload;
          if (appDataFromResponse.length === 0) {
            state.isYotiSuccess = true;
          }
        }
      )
      .addCase(
        getIdentityVerificationDataBySessionIdData.rejected,
        (state, action) => {
          state.isPharmacyServiceLoading = false;
          state.isYotiSuccess = false;
        }
      )
      .addCase(checkYotiDataBySessionIdData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
        state.isYotiSuccess = false;
      })
      .addCase(checkYotiDataBySessionIdData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse.length === 0) {
          state.isYotiSuccess = true;
        }
      })
      .addCase(checkYotiDataBySessionIdData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.isYotiSuccess = false;
      })
      .addCase(getOrbitSessionDetailData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
        state.orbitSessionDetail = null;
      })
      .addCase(getOrbitSessionDetailData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.orbitSessionDetail = action?.payload || null;
      })
      .addCase(getOrbitSessionDetailData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.orbitSessionDetail = null;
      })
      .addCase(changeSentToOrbitData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(changeSentToOrbitData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
      })
      .addCase(changeSentToOrbitData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
      })
      .addCase(saveOrbitSessionDetailData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
        state.orbitSession = null;
      })
      .addCase(saveOrbitSessionDetailData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.orbitSession = action?.payload || null;
      })
      .addCase(saveOrbitSessionDetailData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.orbitSession = null;
      })
      .addCase(getUserLogData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
        state.userLog = null;
      })
      .addCase(getUserLogData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        const appDataFromResponse = action?.payload;
        state.userLog = appDataFromResponse || null;
        state.isNmsProgress = appDataFromResponse?.isActive || false;
        state.userName = appDataFromResponse?.userName || "";
      })
      .addCase(getUserLogData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.userLog = null;
      })
      .addCase(savePatientContactData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
        state.savePatientContact = null;
      })
      .addCase(savePatientContactData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.savePatientContact = action?.payload || null;
      })
      .addCase(savePatientContactData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.savePatientContact = null;
      })
      .addCase(getServiceProgressData.pending, (state) => {
        state.isServiceProgressLoading = true;
        state.serviceProgress = 0;
      })
      .addCase(getServiceProgressData.fulfilled, (state, action) => {
        state.isServiceProgressLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.serviceProgress = appDataFromResponse || 0;
          } catch (error) {}
        }
      })
      .addCase(getServiceProgressData.rejected, (state, action) => {
        state.isServiceProgressLoading = false;
        state.serviceProgress = 0;
      })
      .addCase(getPharmacyActionsData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
        state.pharmacyActions = null;
      })
      .addCase(getPharmacyActionsData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.pharmacyActions = appDataFromResponse || null;
            state.sendSMSAutomation = appDataFromResponse?.sendSMS;
            state.sendOrbitAutomation = appDataFromResponse?.sendToOrbit;

            state.sendInterventionAutomation =
              appDataFromResponse?.sendToIntervention;
            state.sendConsentOnly = appDataFromResponse?.consentOnly;
            if (appDataFromResponse?.sendToIntervention === true) {
              state.sendToInterventionOptions =
                state.SendToInterventionDropdownOptions[1];
            } else {
              state.sendToInterventionOptions =
                state.SendToInterventionDropdownOptions[0];
            }
          } catch (error) {}
        }
      })
      .addCase(getPharmacyActionsData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.pharmacyActions = null;
      })
      .addCase(changePharmacyActionsData.pending, (state) => {
        state.isPharmacyServiceLoading = false;
      })
      .addCase(changePharmacyActionsData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
      })
      .addCase(changePharmacyActionsData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
      })
      .addCase(moveDeclineServiceToClinicalServiceData.pending, (state) => {
        state.isPharmacyServiceLoading = false;
        state.isRevertBackToClinicalService = false;
      })
      .addCase(
        moveDeclineServiceToClinicalServiceData.fulfilled,
        (state, action) => {
          state.isPharmacyServiceLoading = false;
          const appDataFromResponse = action?.payload;
          if (appDataFromResponse) {
            try {
              state.isRevertBackToClinicalService = true;
            } catch (error) {}
          }
        }
      )
      .addCase(
        moveDeclineServiceToClinicalServiceData.rejected,
        (state, action) => {
          state.isPharmacyServiceLoading = false;
          state.isRevertBackToClinicalService = false;
        }
      )
      .addCase(moveInterventionData.pending, (state) => {})
      .addCase(moveInterventionData.fulfilled, (state, action) => {})
      .addCase(moveInterventionData.rejected, (state, action) => {
        logError(action?.error);
      })
      .addCase(declineServicesData.pending, (state) => {})
      .addCase(declineServicesData.fulfilled, (state, action) => {})
      .addCase(declineServicesData.rejected, (state, action) => {})
      .addCase(rescheduleServicesData.pending, (state) => {})
      .addCase(rescheduleServicesData.fulfilled, (state, action) => {})
      .addCase(rescheduleServicesData.rejected, (state, action) => {
        logError(action?.error);
      })
      .addCase(getServiceTitanPatientPdsData.rejected, (state, action) => {})
      .addCase(getServiceTitanPatientPdsData.pending, (state) => {})
      .addCase(getServiceTitanPatientPdsData.fulfilled, (state, action) => {})

      .addCase(getDefaultCompanyServiceUrlData.pending, (state, action) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(getDefaultCompanyServiceUrlData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.defaultPharmacyServices = action?.payload;
      })
      .addCase(getDefaultCompanyServiceUrlData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
      })
      .addCase(getClinicalServiceBuilderByIdData.pending, (state, action) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(getClinicalServiceBuilderByIdData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        state.serviceDetails = action?.payload;
      })
      .addCase(getClinicalServiceBuilderByIdData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
      })
      .addCase(getServiceTemplatesData.pending, (state, action) => {})
      .addCase(getServiceTemplatesData.fulfilled, (state, action) => {
        state.serviceDetailTemplates = action?.payload;
        state.customTemplateModel = {
          templates: action?.payload.map((template) => ({
            ...template,
            templateSections: template.templateSections.map((section) => ({
              ...section,
              userControls: section.userControls.map((control) => ({
                ...control,
                value: true,
              })),
            })),
          })),
        };
      })
      .addCase(getServiceTemplatesData.rejected, (state, action) => {})
      .addCase(submitCustomTemplateData.pending, (state, action) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(submitCustomTemplateData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
      })
      .addCase(submitCustomTemplateData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
      })
      .addCase(addAgencyPharmacyData.pending, (state, action) => {
        state.isAgencyRatesLoading = true;
      })
      .addCase(addAgencyPharmacyData.fulfilled, (state, action) => {
        state.isAgencyRatesLoading = false;
      })
      .addCase(addAgencyPharmacyData.rejected, (state, action) => {
        state.isAgencyRatesLoading = false;
      })
      .addCase(getClinicalServiceBuilderByCompanyIdData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(
        getClinicalServiceBuilderByCompanyIdData.fulfilled,
        (state, action) => {
          state.isPharmacyServiceLoading = false;
          state.companyServices = action?.payload || [];
        }
      )
      .addCase(
        getClinicalServiceBuilderByCompanyIdData.rejected,
        (state, action) => {
          state.isPharmacyServiceLoading = false;
          state.companyServices = [];
        }
      )
      .addCase(updateClinicalServicePaymentDetailsData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(
        updateClinicalServicePaymentDetailsData.fulfilled,
        (state, action) => {
          state.isPharmacyServiceLoading = false;
        }
      )
      .addCase(
        updateClinicalServicePaymentDetailsData.rejected,
        (state, action) => {
          state.isPharmacyServiceLoading = false;
        }
      )
      .addCase(getAgencyRatesData.pending, (state) => {
        state.isAgencyRatesLoading = true;
      })
      .addCase(getAgencyRatesData.fulfilled, (state, action) => {
        state.isAgencyRatesLoading = false;
        state.agencyRates = action?.payload || [];
      })
      .addCase(getAgencyRatesData.rejected, (state, action) => {
        state.isAgencyRatesLoading = false;
        state.agencyRates = [];
      })
      .addCase(getAgencyPharmacyData.pending, (state) => {
        state.isPharmacyServiceLoading = true;
      })
      .addCase(getAgencyPharmacyData.fulfilled, (state, action) => {
        state.isPharmacyServiceLoading = false;
        if (action?.payload !== null) {
          state.IsPharmacyAgency = true;
          state.agencyPharmacy = action?.payload;
        } else {
          state.IsPharmacyAgency = false;
        }
      })
      .addCase(getAgencyPharmacyData.rejected, (state, action) => {
        state.isPharmacyServiceLoading = false;
      })
      // analytics optimization changes
      // NMS processed data
      .addCase(getAnalyticsNMSProcessedData.pending, (state) => {
        state.isPharmacyServiceProcessedLoading = true;
      })
      .addCase(getAnalyticsNMSProcessedData.fulfilled, (state, action) => {
        state.isPharmacyServiceProcessedLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.serviceAnalyticsProcessed = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(getAnalyticsNMSProcessedData.rejected, (state, action) => {
        state.isPharmacyServiceProcessedLoading = false;
        state.serviceAnalyticsProcessed = [];
      })
      // analytics earning data
      .addCase(getAnalyticsServicesEarningsData.pending, (state) => {
        state.isPharmacyServiceEarningLoading = true;
      })
      .addCase(getAnalyticsServicesEarningsData.fulfilled, (state, action) => {
        state.isPharmacyServiceEarningLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.serviceAnalyticsEarning = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(getAnalyticsServicesEarningsData.rejected, (state, action) => {
        state.isPharmacyServiceEarningLoading = false;
        state.serviceAnalyticsEarning = [];
      })
      // analytics service users data
      .addCase(getAnalyticsServicesUsersData.pending, (state) => {
        state.isPharmacyServiceUsersLoading = true;
      })
      .addCase(getAnalyticsServicesUsersData.fulfilled, (state, action) => {
        state.isPharmacyServiceUsersLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.serviceAnalyticsUsers = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(getAnalyticsServicesUsersData.rejected, (state, action) => {
        state.isPharmacyServiceUsersLoading = false;
        state.serviceAnalyticsUsers = [];
      })
      // analytics service stages data
      .addCase(getAnalyticsServicesStagesData.pending, (state) => {
        state.isPharmacyServiceStagesLoading = true;
      })
      .addCase(getAnalyticsServicesStagesData.fulfilled, (state, action) => {
        state.isPharmacyServiceStagesLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.serviceAnalyticsStages = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(getAnalyticsServicesStagesData.rejected, (state, action) => {
        state.isPharmacyServiceStagesLoading = false;
        state.serviceAnalyticsStages = [];
      })
      // analytics service completed claimed data
      .addCase(getAnalyticsServicesCompletedClaimedData.pending, (state) => {
        state.isPharmacyServiceCompletedClaimedLoading = true;
      })
      .addCase(
        getAnalyticsServicesCompletedClaimedData.fulfilled,
        (state, action) => {
          state.isPharmacyServiceCompletedClaimedLoading = false;
          const appDataFromResponse = action?.payload;
          if (appDataFromResponse) {
            try {
              state.serviceAnalyticsCompletedClaimed =
                appDataFromResponse || [];
            } catch (error) {}
          }
        }
      )
      .addCase(
        getAnalyticsServicesCompletedClaimedData.rejected,
        (state, action) => {
          state.isPharmacyServiceCompletedClaimedLoading = false;
          state.serviceAnalyticsCompletedClaimed = [];
        }
      )
      // analytics service completed tobe claimed data
      .addCase(
        getAnalyticsServicesCompletedToBeClaimedData.pending,
        (state) => {
          state.isPharmacyServiceCompletedTobeClaimedLoading = true;
        }
      )
      .addCase(
        getAnalyticsServicesCompletedToBeClaimedData.fulfilled,
        (state, action) => {
          state.isPharmacyServiceCompletedTobeClaimedLoading = false;
          const appDataFromResponse = action?.payload;
          if (appDataFromResponse) {
            try {
              state.serviceAnalyticsCompletedTobeClaimed =
                appDataFromResponse || [];
            } catch (error) {}
          }
        }
      )
      .addCase(
        getAnalyticsServicesCompletedToBeClaimedData.rejected,
        (state, action) => {
          state.isPharmacyServiceCompletedTobeClaimedLoading = false;
          state.serviceAnalyticsCompletedTobeClaimed = [];
        }
      )

      // analytics service declined data
      .addCase(getAnalyticsServicesDeclinedData.pending, (state) => {
        state.isPharmacyServiceCompletedDeclinedLoading = true;
      })
      .addCase(getAnalyticsServicesDeclinedData.fulfilled, (state, action) => {
        state.isPharmacyServiceCompletedDeclinedLoading = false;
        const appDataFromResponse = action?.payload;
        if (appDataFromResponse) {
          try {
            state.serviceAnalyticsCompletedDeclined = appDataFromResponse || [];
          } catch (error) {}
        }
      })
      .addCase(getAnalyticsServicesDeclinedData.rejected, (state, action) => {
        state.isPharmacyServiceCompletedDeclinedLoading = false;
        state.serviceAnalyticsCompletedDeclined = [];
      });
  },
});

// Action creators are generated for each case reducer function

export const {
  setIsPharmacyServiceLoading,
  setIsPharmacyServiceSidebarLoading,
  setIsPharmacyServicesAnalyticsLoading,
  resetPharmacyServiceState,
  setIsCalenderShow,
  setSidebarVisibleData,
  setCompletedServiceTabValueData,
  setSelectedRow,
  setServiceId,
  setCurrentServiceTabValue,
  setNHSCount,
  setPrivateCount,
  setTotalCount,
  setServiceData,
  setServiceName,
  setServiceIcon,
  setServiceBuilderType,
  setNewClinicComponent,
  setCurrentEvents,
  setServiceAnalytics,
  setCalendarStartDate,
  setCalendarEndDate,
  setCalendarDates,
  setSmsQueue,
  setNmsProgress,
  setSentToOrbitData,
  setPatientContact,
  setContactServiceId,
  setFilterPatient,
  setFilterNmsValues,
  setFilterTagsvalues,
  setFilterTagsvaluesConsented,
  setFilterNamevalues,
  setPharmacyServices,
  setIsFilterModal,
  setScrollPositionData,
  setOpenData,
  setAnalyticsTabsValueData,
  setServiceProgressData,
  setIsServiceProgressLoading,
  setOrbitQueue,
  setOrbitClinicalServiceIdData,
  setInterventionQueue,
  setDeclineQueue,
  setRescheduleQueue,
  setPdsQueue,
  setPatientNameValues,
  setInterventionStageScrollPositionData,
  setEligibilityStageScrollPositionData,
  setListViewGridScrollPositionData,
  setShowRescheduleData,
  setCheckedAllEligibleData,
  setCheckedAllFollowUpData,
  setCheckedAllInterventionData,
  setCheckedAllConsentData,
  setProcessedPDSServicesData,
  setDrugFilterValueData,
  setSendSMSAutomationData,
  setSendOrbitAutomationData,
  setSendConsentOnlyData,
  setTransformedPharmacyData,
  setSendInterventionAutomationData,
  setFilteredPharmacyServicesData,
  setOriginalPharmacyServicesData,
  setSendToInterventionOptionsData,
  setIsAutomationMenuOpenData,
  setTemplateControlValue,
  setAnyControlChecked,
  setSelectedClinicalServiceIdData,
  setShowPaymentModalData,
  setOrbitSendModal,
  setOrbitModalQueueData,
} = pharmacyServiceSlice.actions;

export default pharmacyServiceSlice.reducer;
