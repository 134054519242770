import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost } from "../../services/httpService";
import {
  addRoleUrl,
  getPermissionsUrl,
  getTeamsRoleByIdUrl,
  getRolesUrl,
  getTeamsRolesUrl,
  updateRoleUrl,
  inviteTeamMemberUrl,
  addTeamUrl,
  updateTeamUrl,
  getTeamsUrl,
  getUserImagesUrl,
  removeTeamMemberURL,
  changeMemberRoleUrl,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";

const initialState = {
  isTeamLoading: false,
  teamRolesAll: [],
  teamPermissions: [],
  currentRole: null,
  teaminvited: null,
  teamAdded: null,
  teamUpdated: null,
  currentTeams: [],
  teamUserImages: [],
  roles: [],
  isRolesLoading: false,
  addTeamModal: false,
  addTeamTitle: "",
  addTeamImage: "",
  addTeamPharmacyName: "",
  teamFormMode: "",
};

export const getTeamRolesAllData = createAsyncThunk(
  "team/getTeamsRoles",
  async (companyId, thunkAPI) => {
    const respData = await httpGet(getTeamsRolesUrl(companyId));
    return respData?.roles;
  }
);

export const getRolesData = createAsyncThunk(
  "team/getRolesData",
  async (roleType, thunkAPI) => {
    const respData = await httpGet(getRolesUrl(roleType));
    return respData?.roles;
  }
);

export const getTeamsData = createAsyncThunk(
  "team/getTeamsData",
  async (params, thunkAPI) => {
    const respData = await httpGet(getTeamsUrl());
    return respData;
  }
);

export const getUserImagesData = createAsyncThunk(
  "team/getUserImagesData",
  async (teamId, thunkAPI) => {
    const respData = await httpGet(getUserImagesUrl(teamId));
    return respData;
  }
);

export const addRoleData = createAsyncThunk(
  "team/addRole",
  async (data, thunkAPI) => {
    const respData = await httpPost(addRoleUrl(), data);
    return respData;
  }
);

export const inviteTeamMemberData = createAsyncThunk(
  "team/inviteTeamMemberData",
  async (params, thunkAPI) => {
    const { data, teamId } = params || {};
    const respData = await httpPost(inviteTeamMemberUrl(teamId), data);
    return respData;
  }
);

export const addTeamData = createAsyncThunk(
  "team/addTeamData",
  async (data, thunkAPI) => {
    const respData = await httpPost(addTeamUrl(), data);
    return respData;
  }
);

export const updateTeamData = createAsyncThunk(
  "team/updateTeamData",
  async (data, thunkAPI) => {
    const respData = await httpPost(updateTeamUrl(), data);
    return respData;
  }
);

export const getTeamsRoleByIdData = createAsyncThunk(
  "team/getTeamsRoleById",
  async (roleId, thunkAPI) => {
    const respData = await httpGet(getTeamsRoleByIdUrl(roleId));
    return respData?.roles;
  }
);
export const removeTeamMemberData = createAsyncThunk(
  "team/removeTeamMemberData",
  async (params, thunkAPI) => {
    const { teamID, teamMemberID } = params;
    const respData = await httpGet(removeTeamMemberURL(teamID, teamMemberID));
    return respData?.roles;
  }
);

export const changeMemberRoleData = createAsyncThunk(
  "team/changeMemberRoleData",
  async (params, thunkAPI) => {
    const { userEmail, companyID, roleID } = params;
    const respData = await httpGet(
      changeMemberRoleUrl(userEmail, companyID, roleID)
    );
    return respData?.roles;
  }
);

export const getPermissionsData = createAsyncThunk(
  "team/getPermissions",
  async (params, thunkAPI) => {
    const respData = await httpGet(getPermissionsUrl());
    return respData?.permissions;
  }
);

export const updateRoleData = createAsyncThunk(
  "team/updateRole",
  async (data, thunkAPI) => {
    const respData = await httpPost(updateRoleUrl(), data);
    return respData;
  }
);

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setIsTeamLoading: (state, action) => {
      state.isTeamLoading = action?.payload || false;
    },
    setCurrentRoleData: (state, action) => {
      state.currentRole = action?.payload || null;
    },
    setAddTeamModalData: (state, action) => {
      state.addTeamModal = action?.payload || false;
    },
    setAddTeamTitleData: (state, action) => {
      state.addTeamTitle = action?.payload || "";
    },
    setAddTeamImageData: (state, action) => {
      state.addTeamImage = action?.payload || "";
    },
    setAddTeamPharmacyNameData: (state, action) => {
      state.addTeamPharmacyName = action?.payload || [];
    },
    setTeamFormModeData: (state, action) => {
      state.teamFormMode = action?.payload || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamRolesAllData.pending, (state) => {
        state.isTeamLoading = true;
        state.teamRolesAll = [];
      })
      .addCase(getTeamRolesAllData.fulfilled, (state, action) => {
        state.isTeamLoading = false;
        state.teamRolesAll = action?.payload || [];
      })
      .addCase(getTeamRolesAllData.rejected, (state, action) => {
        logError(action?.error);
        state.isTeamLoading = false;
        state.teamRolesAll = [];
      })
      .addCase(getRolesData.pending, (state) => {
        state.isRolesLoading = true;
        state.roles = [];
      })
      .addCase(getRolesData.fulfilled, (state, action) => {
        state.isRolesLoading = false;
        const appDataFromResponse = action?.payload;
        let roleList = [];
        if (appDataFromResponse?.length > 0) {
          appDataFromResponse?.map((role) => {
            if (!role?.isAdmin) {
              roleList.push(role);
            }
          });
          state.roles = roleList;
        }
      })
      .addCase(getRolesData.rejected, (state, action) => {
        logError(action?.error);
        state.isRolesLoading = false;
        state.roles = [];
      })
      .addCase(getTeamsData.pending, (state) => {
        state.isTeamLoading = true;
        state.currentTeams = [];
      })
      .addCase(getTeamsData.fulfilled, (state, action) => {
        state.isTeamLoading = false;
        state.currentTeams = action?.payload || [];
      })
      .addCase(getTeamsData.rejected, (state, action) => {
        logError(action?.error);
        state.isTeamLoading = false;
        state.currentTeams = [];
      })
      .addCase(getUserImagesData.pending, (state) => {
        state.isTeamLoading = true;
        state.teamUserImages = [];
      })
      .addCase(getUserImagesData.fulfilled, (state, action) => {
        state.isTeamLoading = false;
        state.teamUserImages = action?.payload || [];
      })
      .addCase(getUserImagesData.rejected, (state, action) => {
        logError(action?.error);
        state.isTeamLoading = false;
        state.teamUserImages = [];
      })
      .addCase(addRoleData.pending, (state) => {
        state.isTeamLoading = true;
      })
      .addCase(addRoleData.fulfilled, (state, action) => {
        state.isTeamLoading = false;
        state.currentRole = action?.payload;
      })
      .addCase(addRoleData.rejected, (state, action) => {
        logError(action?.error);
        state.isTeamLoading = false;
        state.currentRole = null;
      })
      .addCase(inviteTeamMemberData.pending, (state) => {
        state.isTeamLoading = true;
        state.teaminvited = null;
      })
      .addCase(inviteTeamMemberData.fulfilled, (state, action) => {
        state.isTeamLoading = false;
        state.teaminvited = action?.payload;
      })
      .addCase(inviteTeamMemberData.rejected, (state, action) => {
        logError(action?.error);
        state.isTeamLoading = false;
        state.teaminvited = null;
      })
      .addCase(removeTeamMemberData.pending, (state) => {
        state.isTeamLoading = true;
      })
      .addCase(removeTeamMemberData.fulfilled, (state, action) => {
        state.isTeamLoading = false;
      })
      .addCase(removeTeamMemberData.rejected, (state, action) => {
        logError(action?.error);
        state.isTeamLoading = false;
      })
      .addCase(changeMemberRoleData.pending, (state) => {
        state.isTeamLoading = true;
      })
      .addCase(changeMemberRoleData.fulfilled, (state, action) => {
        state.isTeamLoading = false;
      })
      .addCase(changeMemberRoleData.rejected, (state, action) => {
        logError(action?.error);
        state.isTeamLoading = false;
      })
      .addCase(addTeamData.pending, (state) => {
        state.isTeamLoading = true;
        state.teamAdded = null;
      })
      .addCase(addTeamData.fulfilled, (state, action) => {
        state.isTeamLoading = false;
        state.teamAdded = action?.payload;
      })
      .addCase(addTeamData.rejected, (state, action) => {
        logError(action?.error);
        state.isTeamLoading = false;
        state.teamAdded = null;
      })
      .addCase(updateTeamData.pending, (state) => {
        state.isTeamLoading = true;
        state.teamUpdated = null;
      })
      .addCase(updateTeamData.fulfilled, (state, action) => {
        state.isTeamLoading = false;
        state.teamUpdated = action?.payload;
      })
      .addCase(updateTeamData.rejected, (state, action) => {
        logError(action?.error);
        state.isTeamLoading = false;
        state.teamUpdated = null;
      })
      .addCase(getTeamsRoleByIdData.pending, (state) => {
        state.isTeamLoading = true;
      })
      .addCase(getTeamsRoleByIdData.fulfilled, (state, action) => {
        state.isTeamLoading = false;
        const teamRolesById = action?.payload || [];
        state.currentRole =
          (teamRolesById?.length > 0 && teamRolesById[0]) || null;
      })
      .addCase(getTeamsRoleByIdData.rejected, (state, action) => {
        logError(action?.error);
        state.isTeamLoading = false;
        state.currentRole = null;
      })
      .addCase(getPermissionsData.pending, (state) => {
        state.isTeamLoading = true;
        state.teamPermissions = [];
      })
      .addCase(getPermissionsData.fulfilled, (state, action) => {
        state.isTeamLoading = false;
        state.teamPermissions = action?.payload || [];
      })
      .addCase(getPermissionsData.rejected, (state, action) => {
        logError(action?.error);
        state.isTeamLoading = false;
        state.teamPermissions = [];
      })
      .addCase(updateRoleData.pending, (state) => {
        state.isTeamLoading = true;
      })
      .addCase(updateRoleData.fulfilled, (state, action) => {
        state.isTeamLoading = false;
        state.currentRole = action?.payload;
      })
      .addCase(updateRoleData.rejected, (state, action) => {
        logError(action?.error);
        state.isTeamLoading = false;
        state.currentRole = null;
      });
  },
});

export const {
  setIsTeamLoading,
  setCurrentRoleData,
  setAddTeamModalData,
  setAddTeamTitleData,
  setAddTeamImageData,
  setAddTeamPharmacyNameData,
  setTeamFormModeData,
} = teamSlice.actions;

export default teamSlice.reducer;
