import React from "react";
import CustomLabel from "../../../../../components/controls/CustomLabel";
import { ReactComponent as DayIconSvg } from "../../../../../assets/images/DayIconSvg.svg";
import useTitanApp from "../../../../../hooks/useTitanApp";
import CustomButton from "../../../../../components/controls/CustomButton";
import SkillsetAllocation from "./SkillsetAllocation";
import LoadingComponentPharmanautDetails from "./LoadingComponentPharmanautDetails";
import {
  SaveOrbitPharmanautServices,
  setSidebarVisible,
  getOrbitAgencyUsers,
} from "../../../../../dispatchers/orbitDispatchers";
import moment from "moment";

const getDayName = (dayOfWeek) => {
  const days = [
    "Monday", // 1
    "Tuesday", // 2
    "Wednesday", // 3
    "Thursday", // 4
    "Friday", // 5
    "Saturday", // 6
    "Sunday", // 7
  ];
  return days[dayOfWeek - 1] || "Unknown";
};

const formatTime = (dateTime) => {
  const date = new Date(dateTime);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

// Utility function to calculate time since last login in "X hours Y min" format
const timeSinceLogin = (lastLogin) => {
  const now = new Date();
  const loginTime = new Date(lastLogin);
  const diffMs = now - loginTime;

  const hours = Math.floor(diffMs / (1000 * 60 * 60));
  const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  return `${hours} hours ${minutes} min`;
};

const allDaysOfWeek = [1, 2, 3, 4, 5, 6, 7]; // Monday to Sunday

function PharmanautGeneralDetails() {
  const { appState, orbitState, onboardingVerificationState } = useTitanApp();
  const { userData, isonboardingVerificationLoading } =
    onboardingVerificationState || {};
  const { orbitPharmanautUser, pharmanautUserLoader } = orbitState || {};
  const {
    gphcNumber,
    userStatus,
    userWeekAvailability,
    phoneNumber,
    lastLogin,
  } = orbitPharmanautUser;
  const statusLabel =
    userStatus === 2 ? "Online" : userStatus === 1 ? "Inactive" : "Offline";
  const { companyID } = userData;
  // Create a mapping of availability by dayOfWeek for easy lookup
  const availabilityMap =
    userWeekAvailability?.reduce((map, availability) => {
      map[availability.dayOfWeek] = availability;
      return map;
    }, {}) || {};

  const handleSaveServiceClick = () => {
    const { fullName, ...updatedUser } = orbitPharmanautUser;
    if (updatedUser.createdOn) {
      const parsedDate = moment(updatedUser.createdOn);
      updatedUser.createdOn = parsedDate.format("YYYY-MM-DDTHH:mm:ss[Z]");
    }

    SaveOrbitPharmanautServices(orbitPharmanautUser).then((data) => {
      getOrbitAgencyUsers(companyID).then((res) => {
        setSidebarVisible(false);
      });
    });
  };

  return pharmanautUserLoader ? (
    <LoadingComponentPharmanautDetails />
  ) : (
    <div className="content-div patient-info-container">
      <div className="general-inner-boxes">
        <div className="genaral_pds">
          <h2 className="pharmanaut-details-card-heading">General</h2>
        </div>
        <div className="general-info">
          <div className="info-box">
            <span>
              <CustomLabel name="GPHC Number" />
              <p className="card-inner-value">{gphcNumber || "-"}</p>
            </span>
          </div>
          <div className="info-box">
            <span>
              <CustomLabel name="Telephone" />
              <p className="card-inner-value">{phoneNumber || "-"}</p>
            </span>
          </div>
        </div>
        <div className="general-info">
          <div className="info-box">
            <span>
              <CustomLabel name="Status" />
              <p className="card-inner-value">{statusLabel || "-"}</p>
            </span>
          </div>
          <div className="info-box">
            <span>
              <CustomLabel name="Time Online" />
              <p className="card-inner-value">
                {lastLogin ? timeSinceLogin(lastLogin) : "N/A"}
              </p>
            </span>
          </div>
        </div>

        <h2 className="pharmanaut-details-card-heading">Availability</h2>
        <div className="pharmanaut-availibility">
          {allDaysOfWeek.map((dayOfWeek) => {
            const availability = availabilityMap[dayOfWeek];
            return (
              <div
                className={`pharmanaut-info-box ${
                  availability ? "" : "pharmanaut-info-box-disabled"
                }`}
                key={dayOfWeek}
              >
                <div className="dayVal">
                  <DayIconSvg color="red" className="icons-color" />
                  <p className="availibility-card-inner-value">
                    {getDayName(dayOfWeek)}
                  </p>
                </div>
                <div className="durations">
                  {availability &&
                    availability.slots.map((slot, index) => (
                      <p className="durationVal" key={index}>
                        {formatTime(slot.startTime)} -{" "}
                        {formatTime(slot.endTime)}
                      </p>
                    ))}
                </div>
              </div>
            );
          })}
        </div>

        <h2 className="pharmanaut-details-card-heading">Skillset Allocation</h2>
        <SkillsetAllocation />
        <div className="messageBtnContainer">
          <CustomButton action={handleSaveServiceClick} label="Save" />
          <CustomButton label="MESSAGE" />
        </div>
      </div>
    </div>
  );
}

export default PharmanautGeneralDetails;
