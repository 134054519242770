import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost } from "../../services/httpService";
import {
  getAppUrl,
  userChangeCompanyUrl,
  getCompanyNamesUrl,
  updateLastLoginByEmailUrl,
  updateUserCurrentStatusUrl,
  updateLogoutByEmailUrl,
} from "../../apiUrls/apiUrls";
import { logError } from "../../services/logService";
import { jwtDecode } from "jwt-decode";
import chmln from "@chamaeleonidae/chmln";
import config from "../../config";
const { hotjarId, chameleonKey } = config;

const initialState = {
  isSelectionScreen: true,
  isTeamUpdated: false,
  selectedTeamIndex: "",
  isValidOrg: false,
  isOrbitUser: false,
  isOrbitSwitch: false,
  isChatVisible: false,
  isOrbitVisible: false,
  isAppLoading: false,
  isTabLoading: false,
  darkMode: localStorage.getItem("darkMode") === "true" || false,
  theme:
    localStorage.getItem("darkMode") === "true" ? "dark-theme" : "light-theme",
  companyId: "",
  currentPharmacyId: "00000000-0000-0000-0000-000000000000",
  currentPharmacy: null,
  currentTeamId: "",
  currentTeam: null,
  currentUser: null,
  serviceCounts: [],
  teams: [],
  pharmacies: [],
  userPermissions: [],
  authToken: localStorage.getItem("authToken") || "",
  features: {
    ifMapEnabled: true,
    ifClinicalChecksEnabled: true,
    ifClinicalservicesEnabled: true,
    ifCustomerExperienceEnabled: true,
    ifPatientDashboardEnabled: true,
    ifPatientListEnabled: true,
    ifPharmacyOverviewEnabled: true,
    ifPatientSearchEnabled: true,
    ifChatEnabled: true,
    ifSettingEnabled: true,
    ifSettingMinimal: true,
    ifSendToOrbit: true,
    ifPharmacyAction: true,
    ifCreateService: true,
    ifAgencyFeature: true,
    featuresData: [],
  },
  changeCompany: null,
};

export const getAppData = createAsyncThunk(
  "app/getAppData",
  async (featureSearchType = 6, thunkAPI) => {
    const urlToGet = getAppUrl(featureSearchType);
    const respData = await httpGet(urlToGet);
    return respData;
  }
);
export const userChangeCompanyData = createAsyncThunk(
  "app/userChangeCompanyData",
  async (params, thunkAPI) => {
    const { email, type } = params;
    const respData = await httpGet(userChangeCompanyUrl(email, type));
    return respData;
  }
);
export const getCompanyNamesData = createAsyncThunk(
  "app/getCompanyNamesData",
  async (params, thunkAPI) => {
    const { oldCompanyId, newCompanyId } = params;
    const respData = await httpGet(
      getCompanyNamesUrl(oldCompanyId, newCompanyId)
    );
    return respData;
  }
);
export const updateLastLoginByEmailData = createAsyncThunk(
  "app/updateLastLoginByEmailData",
  async (thunkAPI) => {
    const respData = await httpPost(updateLastLoginByEmailUrl());
    return respData;
  }
);
export const updateUserCurrentStatusData = createAsyncThunk(
  "app/updateUserCurrentStatusData",
  async (statusType, thunkAPI) => {
    const respData = await httpGet(updateUserCurrentStatusUrl(statusType));
    return respData;
  }
);
export const updateLogoutByEmailData = createAsyncThunk(
  "app/updateLastLoginByEmailData",
  async (thunkAPI) => {
    const respData = await httpPost(updateLogoutByEmailUrl());
    return respData;
  }
);

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsValidOrg: (state, action) => {
      state.isValidOrg = action?.payload || false;
    },
    setIsChatVisible: (state, action) => {
      state.isChatVisible = action?.payload || false;
    },
    setIsOrbitSessionData: (state, action) => {
      state.isOrbitVisible = action?.payload || false;
    },
    setIsAppLoading: (state, action) => {
      state.isAppLoading = action?.payload || false;
    },
    setIsSelectionScreenData: (state, action) => {
      state.isSelectionScreen = action?.payload;
    },
    setIsTeamUpdatedData: (state, action) => {
      state.isTeamUpdated = action?.payload || false;
    },
    setIsSelectedTeamIndexData: (state, action) => {
      state.selectedTeamIndex = action?.payload || "";
    },
    setIsTabLoading: (state, action) => {
      state.isTabLoading = action?.payload || false;
    },
    setOrbitUser: (state, action) => {
      state.isOrbitSwitch = action?.payload || false;
    },
    setAuthToken: (state, action) => {
      const auth0Token = action?.payload || "";
      state.authToken = auth0Token;
      if (auth0Token != "") {
        const decoded = jwtDecode(auth0Token);
        const permissions = decoded.permissions || decoded.scope;
        state.userPermissions = permissions;
      }
      localStorage.setItem("authToken", auth0Token);
    },
    removeAuthToken: (state) => {
      state.authToken = "";
      localStorage.removeItem("authToken");
    },
    setDarkModeTheme: (state, action) => {
      state.darkMode = action?.payload || false;
      state.theme = state.darkMode === true ? "dark-theme" : "light-theme";
      localStorage.setItem("darkMode", state.darkMode);
    },
    setPharmacy: (state, action) => {
      state.currentPharmacy = action?.payload || null;
      state.currentPharmacyId =
        state.currentPharmacy?.teamPharmacyID ||
        "00000000-0000-0000-0000-000000000000";
    },
    setTeam: (state, action) => {
      state.currentTeam = action?.payload || null;
      state.currentTeamId = state.currentTeam?.teamID || "";
      state.pharmacies = state.currentTeam?.pharmacies || [];
      if (
        state?.pharmacies &&
        Array.isArray(state?.pharmacies) &&
        state?.pharmacies?.length > 0 &&
        state?.pharmacies[0]?.teamPharmacyID
      ) {
        state.currentPharmacy = state.pharmacies[0];
        state.currentPharmacyId =
          state.pharmacies[0]?.teamPharmacyID ||
          "00000000-0000-0000-0000-000000000000";
      }
    },
    setPharmaciesData: (state, action) => {
      state.pharmacies = action?.payload || [];
    },
    replaceAppState: (state, action) => {
      return action?.payload || initialState;
    },
    resetAppState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppData.pending, (state) => {})
      .addCase(getAppData.fulfilled, (state, action) => {
        const appDataFromResponse = action?.payload;
        const identifyUser = (userId, userAttributes) => {
          if (window.hj) {
            window.hj("identify", userId, userAttributes);
          }
        };
        const calculateScores = () => {
          try {
            const { teams, serviceCounts } = appDataFromResponse || {};
            state.serviceCounts = serviceCounts;
            if (teams && Array.isArray(teams) && teams?.length > 0) {
              for (const team of teams) {
                const pharmacies = team.pharmacies || [];
                for (const pharmacy of pharmacies) {
                  let totalKPIsPercentage = 0;
                  let clinicalScore = 0;
                  let operationsScore = 0;
                  let financeScore = 0;
                  let efficiencyScore = 0;

                  if (pharmacy.clinicalKPI) {
                    totalKPIsPercentage += pharmacy.clinicalKPI?.kpiPercentage;
                    clinicalScore += pharmacy.clinicalKPI?.kpiPercentage ?? 0;
                  }

                  if (pharmacy.operationsKPI) {
                    totalKPIsPercentage +=
                      pharmacy.operationsKPI?.kpiPercentage;
                    operationsScore +=
                      pharmacy.operationsKPI?.kpiPercentage ?? 0;
                  }

                  if (pharmacy.financeKPI) {
                    totalKPIsPercentage += pharmacy.financeKPI?.kpiPercentage;
                    financeScore += pharmacy.financeKPI?.kpiPercentage ?? 0;
                  }

                  if (pharmacy.efficiencyKPI) {
                    totalKPIsPercentage +=
                      pharmacy.efficiencyKPI?.kpiPercentage;
                    efficiencyScore +=
                      pharmacy.efficiencyKPI?.kpiPercentage ?? 0;
                  }

                  pharmacy.totalKPIPercentage = totalKPIsPercentage / 4;
                  pharmacy.clinicalScore = clinicalScore;
                  pharmacy.operationsScore = operationsScore;
                  pharmacy.financeScore = financeScore;
                  pharmacy.efficiencyScore = efficiencyScore;
                }
              }
            }
          } catch (error) {
            logError(error);
          }
        };

        if (appDataFromResponse) {
          try {
            appDataFromResponse.teams = Array.isArray(appDataFromResponse.teams)
              ? appDataFromResponse.teams?.sort((team_global, team) => {
                  if (team_global.isGlobalView && !team.isGlobalView) {
                    return -1;
                  }
                  if (!team_global.isGlobalView && team.isGlobalView) {
                    return 1;
                  }
                  return 0;
                })
              : [];
            const { companyID, applicationFeatures, teams, isOrbitUser, user } =
              appDataFromResponse || {
                companyID: "00000000-0000-0000-0000-000000000000",
              };
            calculateScores();
            state.companyId = companyID || "";
            const storedOption = localStorage.getItem("selectedOption");
            if (storedOption === "orbit") {
              state.isOrbitUser = true;
            } else {
              state.isOrbitUser = isOrbitUser || false;
            }
            state.features.featuresData =
              applicationFeatures?.applicationFeatures || [];
            state.teams = teams || [];
            state.currentUser = user || null;
            if (user !== null) {
              try {
                const userId = user?.fullName;
                const userAttributes = {
                  email: user?.emailAddress,
                  subscription_level: "pro",
                };
                identifyUser(userId, userAttributes);
              } catch (error) {
                console.log(error);
              }
              try {
                chmln.init(chameleonKey, {
                  forceOverride: true,
                  fastUrl: "https://fast.chameleon.io/",
                });
                chmln.identify(user?.fullName, {
                  email: user?.emailAddress,
                  name: user?.fullName,
                });
              } catch (error) {
                console.log(error);
              }
            }
            if (state?.isTeamUpdated) {
              const currentTeamIndex = state?.selectedTeamIndex;
              state.currentTeam = state?.teams[currentTeamIndex] || null;
              state.currentTeamId = state?.currentTeam?.teamID || "";
              state.pharmacies = state?.currentTeam?.pharmacies || [];
              if (
                state?.pharmacies &&
                Array.isArray(state?.pharmacies) &&
                state?.pharmacies?.length > 0 &&
                state?.pharmacies[0]?.teamPharmacyID
              ) {
                state.currentPharmacy = state?.pharmacies[0];
                state.currentPharmacyId =
                  state.pharmacies[0]?.teamPharmacyID ||
                  "00000000-0000-0000-0000-000000000000";
              }
            } else {
              let index = state?.teams?.findIndex(
                (eachTeam) => eachTeam.isGlobalView === true
              );
              index = index === -1 ? 0 : index;

              state.currentTeam = state?.teams[index] || null;
              state.currentTeamId = state?.currentTeam?.teamID || "";
              state.pharmacies = state?.currentTeam?.pharmacies || [];
              if (
                state?.pharmacies &&
                Array.isArray(state?.pharmacies) &&
                state?.pharmacies?.length > 0 &&
                state?.pharmacies[0]?.teamPharmacyID
              ) {
                state.currentPharmacy = state?.pharmacies[0];
                state.currentPharmacyId =
                  state.pharmacies[0]?.teamPharmacyID ||
                  "00000000-0000-0000-0000-000000000000";
              }
            }
            if (
              state?.features?.featuresData &&
              Array.isArray(state?.features?.featuresData)
            ) {
              const { featuresData } = state?.features || {};
              featuresData?.map((item) => {
                const { feature, isEnabled } = item || {};
                switch (feature) {
                  case 1:
                    state.features.ifMapEnabled = isEnabled || false;
                    break;
                  case 2:
                    state.features.ifClinicalChecksEnabled = isEnabled || false;
                    break;
                  case 3:
                    state.features.ifClinicalservicesEnabled =
                      isEnabled || false;
                    break;
                  case 4:
                    state.features.ifCustomerExperienceEnabled =
                      isEnabled || false;
                    break;
                  case 5:
                    state.features.ifPatientDashboardEnabled =
                      isEnabled || false;
                    break;
                  case 6:
                    state.features.ifPatientListEnabled = isEnabled || false;
                    break;
                  case 7:
                    state.features.ifPharmacyOverviewEnabled =
                      isEnabled || false;
                    break;
                  case 8:
                    state.features.ifPatientSearchEnabled = isEnabled || false;
                    break;
                  case 9:
                    state.features.ifChatEnabled = isEnabled || false;
                    break;
                  case 10:
                    state.features.ifSettingEnabled = isEnabled || false;
                    break;
                  case 11:
                    state.features.ifSettingMinimal = isEnabled || false;
                    break;
                  case 12:
                    state.features.ifSendToOrbit = isEnabled || false;
                    break;
                  case 13:
                    state.features.ifPharmacyAction = isEnabled || false;
                    break;
                  case 14:
                    state.features.ifCreateService = isEnabled || false;
                    break;
                  case 15:
                    state.features.ifAgencyFeature = isEnabled || false;
                    break;
                  default:
                    break;
                }
              });
            }

            if (
              state.isOrbitUser === false &&
              (!state.companyId ||
                !state.currentPharmacyId ||
                state.currentPharmacyId ===
                  "00000000-0000-0000-0000-000000000000")
            ) {
              state.isValidOrg = false;
            } else {
              state.isValidOrg = true;
            }
          } catch (error) {
            logError(error);
          }
        }
      })
      .addCase(getAppData.rejected, (state, action) => {
        logError(action?.error);
        state.isAppLoading = false;
      })
      .addCase(userChangeCompanyData.pending, (state) => {
        state.isAppLoading = true;
      })
      .addCase(userChangeCompanyData.fulfilled, (state, action) => {
        state.isAppLoading = false;
      })
      .addCase(userChangeCompanyData.rejected, (state, action) => {
        logError(action?.error);
        state.isAppLoading = false;
      })
      .addCase(getCompanyNamesData.pending, (state) => {
        state.isAppLoading = true;
      })
      .addCase(getCompanyNamesData.fulfilled, (state, action) => {
        state.isAppLoading = false;
        state.changeCompany = action?.payload;
      })
      .addCase(getCompanyNamesData.rejected, (state, action) => {
        logError(action?.error);
        state.isAppLoading = false;
        state.changeCompany = null;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsValidOrg,
  setIsChatVisible,
  setIsOrbitSessionData,
  setIsSelectionScreenData,
  setIsTeamUpdatedData,
  setIsSelectedTeamIndexData,
  setIsAppLoading,
  setIsTabLoading,
  setAuthToken,
  removeAuthToken,
  setDarkModeTheme,
  setPharmacy,
  setTeam,
  setOrbitUser,
  setPharmaciesData,
  resetAppState,
  replaceAppState,
} = appSlice.actions;

export default appSlice.reducer;
