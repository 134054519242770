import React from "react";
import Box from "@mui/material/Box";
import { Tabs, Tab, Skeleton } from "@mui/material";
import PrivateServicePreBooking from "./components/PrivateServicePreBooking";
import useTitanApp from "../../../hooks/useTitanApp";
import PrivateServiceTravelInfo from "./components/PrivateServiceTravelInfo";
import PrivateServiceActivity from "./components/PrivateServiceActivity";
import NmsTimeLine from "../../../components/NmsDetails/NmsTimeLine";

export default function PrivateServiceTabs({ stageName }) {
  const { nmsServiceState, appState } = useTitanApp();
  const { darkMode } = appState || {};
  const { isNmsLoading } = nmsServiceState || {};
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return <PrivateServicePreBooking />;
      case 1:
        return <PrivateServiceTravelInfo />;
      case 2:
        return <PrivateServiceActivity stageName={stageName} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: "100%" }} className="patient-tab">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Private Service Tabs"
          centered={true}
        >
          <Tab
            label={
              <span className={`tabs_text_alt`}>
                {isNmsLoading ? (
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                    height={30}
                    width={100}
                    animation="wave"
                    variant="rounded"
                  />
                ) : (
                  "Pre Booking"
                )}
              </span>
            }
            className="para-color"
          />
          <Tab
            label={
              <span className={`tabs_text_alt`}>
                {isNmsLoading ? (
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                    height={30}
                    width={100}
                    animation="wave"
                    variant="rounded"
                  />
                ) : (
                  "Travel Info"
                )}
              </span>
            }
            className="para-color"
          />
          <Tab
            label={
              <span className={`tabs_text_alt`}>
                {isNmsLoading ? (
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                    height={30}
                    width={100}
                    animation="wave"
                    variant="rounded"
                  />
                ) : (
                  "Activity"
                )}
              </span>
            }
            className="para-color"
          />
        </Tabs>
      </Box>
      {renderTabContent()}
    </Box>
  );
}
